import makeStyles from '@mui/styles/makeStyles';
import palette from 'theme/palette';
import { styled } from '@mui/material/styles';
import { Box, TableRow, TableCell } from '@mui/material';
import { Header } from '@tanstack/react-table';
import { DataRow } from './ODTableTypes';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';

const useODTableStyles = makeStyles(() => {
    return {
        tablecell: (props: undefined | { rowHighlightColor?: string }) => ({
            boxSizing: 'border-box',
            height: '40px',
            fontSize: '14px',
            '&.deleted': {
                textDecoration: 'line-through',
                backgroundColor: palette.neutral.search,
                color: palette.neutral.neutral4
            },
            '&.selected': {
                backgroundColor: props?.rowHighlightColor ?? palette.ODLightBlueNeutral.lightBlue1
            },
            '&.error': {
                backgroundColor: palette.ODRed.lightRed100
            }
        }),
        checkboxCell: {
            paddingLeft: '16px'
        },
        dollars: {
            '&::before': {
                content: '$'
            }
        },
        actioncell: {
            position: 'relative'
        },
        sticky: {
            position: 'sticky',
            zIndex: 1,
            backgroundColor: 'inherit'
        },
        stickyRight: { borderLeft: `1px solid ${palette.neutral.neutral2}` }
    };
});

interface NonInteractiveCellProps {
    isLastStickyColumn?: boolean;
    isFirstDataColumn?: boolean;
    isFirstScrollableColumn?: boolean;
    cellHasError?: boolean;
}
const NonInteractiveCell = styled(Box)<NonInteractiveCellProps>(
    ({ cellHasError, isLastStickyColumn, isFirstDataColumn, isFirstScrollableColumn }) => ({
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: `0 ${isLastStickyColumn ? '16px' : '8px'} 0 ${
            isFirstDataColumn ? '0px' : isFirstScrollableColumn ? '16px' : '8px'
        }`,
        boxSizing: 'border-box'
    })
);

const StyledHeaderRow = styled(TableRow)(() => ({
    backgroundColor: palette.neutral.search,
    height: '40px',
    display: 'flex'
}));

interface StyledTableRowExtraProps {
    showingActions: boolean;
    clickable: boolean;
}

type BoxSizing = 'border-box' | 'content-box' | 'inherit';
const StyledTableRow = styled(TableRow)<StyledTableRowExtraProps>((props) => {
    const baseStyling = {
        height: '40px',
        display: 'flex',
        boxSizing: 'border-box' as BoxSizing,
        cursor: props.clickable ? 'pointer' : ''
    };
    const { showingActions } = props;

    if (showingActions) {
        return {
            ...baseStyling,
            backgroundColor: palette.ODLightBlueNeutral.lightBlue1
        };
    } else
        return {
            ...baseStyling,
            backgroundColor: palette.white,
            '&:hover': {
                backgroundColor: palette.ODLightBlueNeutral.lightBlue1
            },
            '&.MuiTableRow-hover:hover': {
                backgroundColor: palette.ODLightBlueNeutral.lightBlue1
            }
        };
});

interface HeaderCellExtraProps {
    separateColumn?: boolean;
    header?: Header<DataRow, any>;
    isFirstRightStickyColumn?: boolean;
    isLastStickyColumn?: boolean;
    isFirstDataColumn?: boolean;
    isFirstScrollableColumn?: boolean;
    isGroupHeader?: boolean;
}
const HeaderCell = styled(TableCell, {
    shouldForwardProp: (prop) =>
        ![
            'isFirstRightStickyColumn',
            'isFirstDataColumn',
            'isGroupHeader',
            'isFirstScrollableColumn',
            'isLastStickyColumn'
        ].includes(prop as string)
})<HeaderCellExtraProps>(
    ({
        separateColumn = false,
        header,
        isFirstRightStickyColumn,
        isLastStickyColumn,
        isFirstScrollableColumn,
        isFirstDataColumn
    }) => {
        const { interactiveTable } = useODTable() as ODTableContextInterface & ODTableProviderProps;
        return {
            padding: `10px ${isLastStickyColumn ? '16px' : '8px'} 10px ${
                isFirstDataColumn
                    ? interactiveTable
                        ? '0px'
                        : '16px'
                    : isFirstScrollableColumn || isFirstRightStickyColumn
                    ? '16px'
                    : '8px'
            }`,
            fontWeight: 600,
            fontSize: '14px',
            borderLeft:
                header?.column.id === 'etc'
                    ? `1px solid ${palette.neutral.neutral2}`
                    : separateColumn
                    ? `1px solid ${palette.neutral.neutral2}`
                    : 'none',
            borderRight: isLastStickyColumn ? `1px solid ${palette.neutral.neutral2}` : 'none',
            // We want the header row to be grey.
            // The HeaderCells end up with a background color of white, which overrides the desired header row coloring.
            // Either MUI TableCell forces a default background color of white or there is a conflict in another parent component.
            // To handle this, we override that behavior by inheriting the background color value from the row.
            backgroundColor: 'inherit',
            width: header?.getSize(),
            lineHeight: 'normal',
            boxSizing: 'border-box'
        };
    }
);

export const dollarStyle = {
    '&:before': {
        content: '"$"'
    }
};

export const annualStyle = {
    '&:after': {
        content: '"/yr"'
    }
};

export const UnspecifiedDataPlaceholder = () => <Box>—</Box>;

export const DollarBox = styled(Box)(() => dollarStyle);

export { useODTableStyles, StyledTableRow, NonInteractiveCell, StyledHeaderRow, HeaderCell };
