import React from 'react';
import { CommonHeaderContainer } from 'shared';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import theme from 'theme';

export const ReportTitleHeader = () => {
    return (
        <CommonHeaderContainer
            dark={false}
            isNetworkManager={true}
            justify={'space-between'}
            headerIcon={
                <FolderSharpIcon
                sx={{
                    color: theme.palette.common.black
                }}
            />
            }
            header="Network Manager"
        />
    );
};
