import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Box } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import { Radio, FormControlLabel } from '@mui/material';
import { ODDrawer, ODToggleButtonGroup } from 'shared';
import { loadBidAnalysis, updateBidAnalysis, settingsUpdate } from 'redux/actions';
import { RootState } from 'redux/reducers';
import {
    ANALYSIS_TIME_AGGREGATION_TYPE,
    BID_AGGREGATION_LEVEL,
    LOCATION_VIEW,
    TIME_FRAME,
    toggleButtonsInfoLocationView
} from 'constants/settings';

import {
    SettingsAccordion,
    CustomizedSubsectionTitle,
    AccordionHeaderText,
    CustomAccordionSummary,
    CustomRadioGroup
} from './BidAnaysisSettingsSideout.styles';
import { useParams } from 'react-router-dom';
import { useParamsProps } from 'types/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useBidAnalysisHook } from 'utils/hooks';
import { useTheme } from '@mui/system';

//TODO connect endpoint to update time aggregation
//TODO when the changes are made, table data needs to be updated.

const BidAnalysisSettingsSlideout = ({ show, onClose }: any) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { odpt407934Zip } = useFlags();
    const { isFinalizedBid } = useBidAnalysisHook();

    const { analysis_id, config_id }: useParamsProps = useParams();
    const [openDrawer, setOpenDrawer] = useState({
        localType: true,
        timeAggregation: true
    });
    const { time_aggregation, zip_code_aggregation, locationType } = useSelector(
        (state: RootState) => state.BidAnalysisReducer.bidAnalysisSetting
    );

    const { bidAnalysisSetting } = useSelector((state: RootState) => state.BidAnalysisReducer);

    const handleLocationUpdate = (value: any) => {
        dispatch(settingsUpdate({ locationVisualization: value }));
        dispatch(
            updateBidAnalysis({
                bidAnalysisSetting: {
                    ...bidAnalysisSetting,
                    locationType: value
                }
            })
        );
    };

    const handleTimeAggUpdate = (value: string) => {
        dispatch(
            loadBidAnalysis({
                analysis_id,
                config_id,
                settings: { time_aggregation: value },
                isFinalizedBid
            })
        );
    };
    const handleZipAggUpdate = (value: number) => {
        dispatch(
            loadBidAnalysis({
                analysis_id,
                config_id,
                settings: { zip_code_aggregation: value },
                convertTo3Zip: value === 3,
                isFinalizedBid
            })
        );
    };
    const resetSettings = () => {
        if (time_aggregation !== TIME_FRAME.REPORT) {
            handleTimeAggUpdate(TIME_FRAME.REPORT);
        }
        if (locationType !== LOCATION_VIEW.CITY_STATE) {
            handleLocationUpdate(LOCATION_VIEW.CITY_STATE);
        }
        if (zip_code_aggregation !== 5) {
            handleZipAggUpdate(5);
        }
    };

    return (
        <ODDrawer
            open={show}
            title={'Settings'}
            anchor="right"
            onClose={onClose}
            enableCustomizedButton={true}
            secondaryActionText="Reset"
            secondaryAction={resetSettings}
            children={
                <>
                    <SettingsAccordion
                        key={`analysis-time-aggregation`}
                        expanded={openDrawer?.timeAggregation}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            setOpenDrawer({
                                ...openDrawer,
                                timeAggregation: !openDrawer?.timeAggregation
                            });
                        }}>
                        <CustomAccordionSummary
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.common.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <AccordionHeaderText>Analysis Time Aggregation</AccordionHeaderText>
                        </CustomAccordionSummary>
                    </SettingsAccordion>
                    <Collapse
                        in={openDrawer?.timeAggregation}
                        timeout="auto"
                        unmountOnExit
                        key={`location-type-collapse`}>
                        <>
                            <CustomizedSubsectionTitle>
                                Select Analysis Time Aggregation
                            </CustomizedSubsectionTitle>

                            <CustomRadioGroup
                                id="aggregation-level"
                                name="aggregation-level"
                                value={time_aggregation}
                                onChange={({ target }) => {
                                    handleTimeAggUpdate(target.value);
                                }}>
                                {ANALYSIS_TIME_AGGREGATION_TYPE.map((type) => {
                                    return (
                                        <FormControlLabel
                                            key={type.value}
                                            sx={{
                                                backgroundColor:
                                                    time_aggregation === type
                                                        ? theme.palette.ODLightBlueNeutral
                                                              .lightBlue1
                                                        : 'transparent'
                                            }}
                                            value={type.value}
                                            control={<Radio />}
                                            label={type.label}
                                        />
                                    );
                                })}
                            </CustomRadioGroup>
                        </>
                    </Collapse>

                    <SettingsAccordion
                        key={`location-visualization-type-accordion`}
                        expanded={openDrawer?.localType}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            setOpenDrawer({
                                ...openDrawer,
                                localType: !openDrawer?.localType
                            });
                        }}>
                        <CustomAccordionSummary
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.common.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <AccordionHeaderText>Location Type</AccordionHeaderText>
                        </CustomAccordionSummary>
                    </SettingsAccordion>
                    <Collapse
                        in={openDrawer?.localType}
                        timeout="auto"
                        unmountOnExit
                        key={`location-type-collapse`}>
                        <Box
                            sx={{
                                margin: '16px 0'
                            }}>
                            <ODToggleButtonGroup
                                toggleButtons={toggleButtonsInfoLocationView}
                                value={locationType}
                                handleAction={handleLocationUpdate}></ODToggleButtonGroup>
                            {locationType === LOCATION_VIEW.ZIP_CODE && odpt407934Zip && (
                                <>
                                    <CustomizedSubsectionTitle>
                                        Zip Code Value
                                    </CustomizedSubsectionTitle>

                                    <CustomRadioGroup
                                        id="bid_analysis_aggregation-level"
                                        name="bid_analysis_aggregation-level"
                                        value={zip_code_aggregation}
                                        onChange={({ target: { value } }) =>
                                            handleZipAggUpdate(Number(value))
                                        }>
                                        {BID_AGGREGATION_LEVEL.map((level) => {
                                            return (
                                                <FormControlLabel
                                                    key={`${level}-zip`}
                                                    sx={{
                                                        backgroundColor:
                                                            zip_code_aggregation === level
                                                                ? theme.palette.ODLightBlueNeutral
                                                                      .lightBlue1
                                                                : 'transparent'
                                                    }}
                                                    value={level}
                                                    control={<Radio />}
                                                    label={level}
                                                />
                                            );
                                        })}
                                    </CustomRadioGroup>
                                </>
                            )}
                        </Box>
                    </Collapse>
                </>
            }
        />
    );
};

export default BidAnalysisSettingsSlideout;
