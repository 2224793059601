/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Box, Tooltip, Stack, styled } from '@mui/material';
import { submitGroup } from 'redux/actions';
import * as yup from 'yup';
import FolderSharp from '@mui/icons-material/FolderSharp';
import { ODDrawer, ODTextField, ODMultiSelect, ODIconButton, ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import ClearIcon from '@mui/icons-material/Clear';
import palette from 'theme/palette';
import { HANDLE_GROUP_SLIDEOUT } from 'constants/reports';
import { REPORT_STATUS } from 'constants/network';

interface HandleGroupSlideoutProps {
    show: boolean;
    onClose?: React.MouseEventHandler<HTMLLabelElement>;
    bucket: { name: string; id: string };
    reportRuns: any;
    action: string;
    getReportRuns: any;
    onSidebarClose: any;
}

const SelectedReportListWrapper = styled(Box)(() => ({
    marginTop: '16px',
    overflowY: 'auto',
    maxHeight: '14rem'
}));

const ReportItem = styled(Box)(() => ({
    maxWidth: '288px',
    overflow: ' hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}));

const IconButtonContainer = styled(Box)(() => ({
    height: '20px',
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px',
    margin: '2px 0',
    borderRadius: '4px',
    backgroundColor: palette.ODLightBlueNeutral.lightBlue1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const HandleGroupSlideout = ({
    bucket = { name: '', id: '' },
    reportRuns = [],
    show = false,
    onSidebarClose,
    getReportRuns,
    action = HANDLE_GROUP_SLIDEOUT.ADD_NEW_BUCKET
}: HandleGroupSlideoutProps) => {
    const [name, setName] = useState(bucket?.name);
    const [selectedReportIds, setSelectedReportIds] = useState([]);

    const initialFormState = { groupName: name, groupReports: selectedReportIds };

    const validationSchema = yup.object({
        groupName: yup.string().min(1).max(999).required('Required'),
        groupReports: yup.array()
    });

    useEffect(() => {
        if (action === HANDLE_GROUP_SLIDEOUT.EDIT_BUCKET) {
            const runsList =
                reportRuns.filter(
                    (run: { bucket: any; report_status: string }) =>
                        run.bucket === bucket.id && run.report_status !== REPORT_STATUS.DRAFT
                ) || [];

            const runIds = runsList.map(({ id }: any) => id);

            setSelectedReportIds(runIds);
        } else if (action === HANDLE_GROUP_SLIDEOUT.ADD_NEW_BUCKET) {
            setName('');
        }
    }, [bucket?.id]);

    //options for file ids only
    const allReportIds = reportRuns
        .filter((report: { report_status: any }) => report?.report_status !== REPORT_STATUS.DRAFT)
        .map(({ id }: any) => id);

    //options for the select
    const allReports = reportRuns
        .filter((report: { report_status: any }) => report?.report_status !== REPORT_STATUS.DRAFT)
        .map((run: any) => {
            return {
                ...run,
                value: run?.name
            };
        });

    return (
        <ODDrawer
            open={show}
            title={
                action === HANDLE_GROUP_SLIDEOUT.ADD_NEW_BUCKET ? `Create New Group` : `Edit Group`
            }
            anchor="left"
            onClose={() => onSidebarClose()}
            enableCustomizedButton={true}
            children={
                <Formik
                    enableReinitialize
                    initialValues={initialFormState}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        submitGroup(values?.groupReports, values?.groupName, bucket, action).then(
                            () => {
                                onSidebarClose(true);
                                getReportRuns(false, true);
                                resetForm();
                            }
                        );
                    }}>
                    {({
                        handleSubmit,
                        values,
                        handleChange,
                        setFieldValue,
                        dirty,
                        isValid,
                        resetForm
                    }: any) => {
                        const clearAndClose = () => {
                            resetForm();
                            onSidebarClose();
                        };

                        const removeSelected = (run: any) => {
                            let filteredReports = values?.groupReports?.filter(
                                (e: any) => e !== run
                            );
                            setFieldValue('groupReports', filteredReports);
                        };

                        const handleClearAll = () => {
                            setFieldValue('groupReports', []);
                        };

                        const selectedReportList = values?.groupReports?.map(
                            (item: any, i: number) => {
                                let showValueLabel = (option: string | number) => {
                                    let selectedValueObj = allReports?.find(
                                        (item: any) => option === item.id
                                    );

                                    return (
                                        <Tooltip title={selectedValueObj.value}>
                                            <ReportItem>{selectedValueObj.value}</ReportItem>
                                        </Tooltip>
                                    );
                                };

                                if (item !== null) {
                                    return (
                                        <IconButtonContainer key={i}>
                                            <Box
                                                display="flex"
                                                justifyContent={'center'}
                                                alignItems={'center'}>
                                                <Stack flexDirection="row" marginRight="16px">
                                                    <FolderSharp />
                                                </Stack>

                                                {showValueLabel(item)}
                                            </Box>

                                            <ODIconButton
                                                sx={{ marginRight: 0 }}
                                                disableHover={true}
                                                id={'delete'}
                                                onClick={() => removeSelected(item)}
                                                buttonType={BUTTON_TYPE.TRANSPARENT}
                                                icon={<ClearIcon />}
                                            />
                                        </IconButtonContainer>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        );
                        return (
                            <form onSubmit={handleSubmit}>
                                <ODTextField
                                    sx={{ marginBottom: '16px' }}
                                    dataTestId="enter-group-name"
                                    label="Group Name"
                                    value={values?.groupName}
                                    onChange={handleChange}
                                    name="groupName"
                                    placeholder="Enter Name"
                                />
                                <Field
                                    className="group-reports-select"
                                    name="groupReports"
                                    label={'Add Reports'}
                                    options={allReportIds}
                                    component={ODMultiSelect}
                                    clearSelectedOptions={handleClearAll}
                                    data={allReports}
                                />
                                <SelectedReportListWrapper>
                                    {selectedReportList}
                                </SelectedReportListWrapper>

                                <Stack
                                    marginTop="32px"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <ODButton
                                        buttonType={BUTTON_TYPE.BLUE}
                                        type="submit"
                                        width="392px"
                                        buttonText="Save"
                                        disabled={!isValid || !dirty}
                                    />
                                    <ODButton
                                        buttonText="Cancel"
                                        width="392px"
                                        sx={{ marginTop: '8px' }}
                                        onClick={clearAndClose}
                                    />
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
            }
        />
    );
};

export default HandleGroupSlideout;
