import React, { memo } from 'react';
import { Box, Typography, Breadcrumbs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';

const BidConfigBreadcrumbs = memo(({ currConfigName }: { currConfigName: string | null }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleHeaderTextClick = () => navigate('/bidding');

    const BreadcrumbsContainer = styled(Box)({
        padding: '9px 16px',
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-start',
        alignItems: 'center',
        zIndex: 13,
        backgroundColor: theme.palette.common.white
    });

    const BreadcrumbsParentText = styled(Typography)({
        textTransform: 'capitalize',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.ODBlue.odBlue7
    });

    const BreadcrumbsText = styled(Typography)({
        color: theme.palette.common.black
    });

    return (
        <BreadcrumbsContainer>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
                <BreadcrumbsParentText onClick={handleHeaderTextClick}>
                    Bid File Manager
                </BreadcrumbsParentText>
                <BreadcrumbsText> {currConfigName}</BreadcrumbsText>
            </Breadcrumbs>
        </BreadcrumbsContainer>
    );
});

export default BidConfigBreadcrumbs;
