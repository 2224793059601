import { createSvgIcon } from '@mui/material';

export const AlertTriangleIcon = createSvgIcon(
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 15"
        preserveAspectRatio="xMidYMin"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 14.75H17.25L9 0.5L0.75 14.75Z" fill="#FEB900" />
    </svg>,
    'AlertTriangleIcon'
);
