import React, { useEffect, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { Cell } from '@tanstack/react-table';
import { CELL_ACTION_BUTTON, CellEdit, DataRow, InteractiveCellProps } from './ODTableTypes';
import palette from 'theme/palette';
import ActiveInputCell from './ActiveInputCell';
import WrapTextIcon from '@mui/icons-material/WrapText';
import UndoIcon from '@mui/icons-material/Undo';
import NonactiveInputCell from './NonactiveInputCell';
import { inputValid, isRateOrVolModified } from 'utils/common.util';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import { getSortedTableRowIndex, isNewValueDifferent } from './ODTableUtils';
import { ArrowForward } from '@mui/icons-material';
import { addCommaSeparators } from 'utils/number.util';
import NumericFormat from 'react-number-format';
import { HistoricRateIcon } from 'assets/images';
import ODIconButton from 'shared/ODIconButton';
import { BUTTON_TYPE } from 'constants/colors';
const StyledInteractiveTableCell = styled(Box)(() => ({
    padding: 0,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '100%'
}));

interface StyledDataCellBoxProps {
    isLastStickyColumn?: boolean;
    isFirstScrollableColumn?: boolean;
    isFirstDataColumn?: boolean;
    cell: any;
    selectedCell?: any;
    separatedColumn?: boolean;
    highlightedMax?: boolean;
}
const StyledDataCellBox = styled(Box)<StyledDataCellBoxProps>(
    ({ isLastStickyColumn, isFirstScrollableColumn, isFirstDataColumn, cell, highlightedMax }) => {
        const { maxRowSelected, minRowSelected, selectedColumn } = useODTable() as any;

        const isDraggedOver = (cell: any) => {
            const tableRowIndex = getSortedTableRowIndex(cell);
            return tableRowIndex >= minRowSelected && tableRowIndex <= maxRowSelected;
        };

        const givenColumnId = cell?.column?.id;

        let baseStyling: any = {
            // TODO figure out how to properly type this if we care. CellStyleParams did not work.
            padding: `0 ${isLastStickyColumn ? '16px' : '8px'} 0 ${
                isFirstDataColumn ? '0px' : isFirstScrollableColumn ? '16px' : '8px'
            }`,
            boxSizing: 'border-box'
        };

        if (givenColumnId === selectedColumn?.id) {
            if (highlightedMax) {
                baseStyling.backgroundColor = palette.ODRed.lightRed100;
            } else if (isDraggedOver(cell)) {
                baseStyling.backgroundColor = palette.ODLightBlueNeutral.lightBlue1;
            }
        }

        return baseStyling;
    }
);

const InteractiveCell = (props: InteractiveCellProps) => {
    const {
        cell,
        cellStyle,
        initialCellValue,
        validationRule,
        isLastStickyColumn,
        isFirstScrollableColumn,
        isFirstDataColumn,
        separatedColumn,
        setHighlightMaxCellStyling
    } = props;
    const {
        accommodatesUndos,
        handleClickedInteractiveCell,
        handleUndoCell,
        isDragging,
        selectedCell,
        cellEditsCallback,
        setMaxRowSelected,
        setMinRowSelected,
        setRowActionsClosed,
        setValueToCopy,
        targets,
        valueToCopy
    } = useODTable() as ODTableContextInterface & ODTableProviderProps;
    const [cellValue, setCellValue] = useState(initialCellValue);
    const [highlightMax, setHighlightMax] = useState<boolean>(false);
    const [inputFieldActive, setInputFieldActive] = useState(false);
    const maxValueColumnId = cell.column.columnDef.meta?.maxColumnId;
    const maxValue = maxValueColumnId ? (cell.row.original[maxValueColumnId] as number) : null;

    const onBlur = (value: any) => {
        let newCellValue = value;
        if (value === '') newCellValue = null;
        if (
            cell.column.columnDef.meta?.format &&
            ['volume', 'dollars'].includes(cell.column.columnDef.meta?.format)
        )
            newCellValue = Number(value);
        cellEditsCallback?.([
            {
                rowId: cell.row.original.id as number,
                columnId: cell.column.columnDef.id as string,
                newValue: newCellValue
            }
        ]);
        setValueToCopy(value);
        setInputFieldActive(false);
    };

    const formatValue = useCallback(
        (value: any) => {
            switch (cell.column.columnDef.meta?.format) {
                case 'dollars':
                    return Number(value).toFixed(2);
                case 'integer':
                    return Math.round(Number(value));
                default:
                    return value;
            }
        },
        [cell.column.columnDef.meta?.format]
    );

    useEffect(() => {
        setCellValue(formatValue(initialCellValue));
    }, [formatValue, initialCellValue]);

    const handleWrapTextButton = (cell: any) => {
        const tableRows = cell.getContext().table.getRowModel().rows;
        const sortedRowIndex = getSortedTableRowIndex(cell);
        let cellEdits: CellEdit[] = [];
        tableRows.slice(sortedRowIndex).forEach((tableRow: any) => {
            if (isNewValueDifferent(tableRow.original, cell.column.id, valueToCopy)) {
                let newValue = valueToCopy;
                if (cell.column.columnDef.meta?.maxColumnId)
                    newValue = Math.min(
                        Number(valueToCopy),
                        Number(tableRow.original[cell.column.columnDef.meta?.maxColumnId])
                    );
                cellEdits.push({
                    rowId: tableRow.original.id as number,
                    columnId: cell.column.id,
                    newValue: newValue
                });
            }
        });
        cellEditsCallback?.(cellEdits);
    };

    const handleMouseEnter = (cell: Cell<DataRow, any>): void => {
        if (isDragging) {
            const sortedTableRow = getSortedTableRowIndex(cell);
            updateMinMaxRows(sortedTableRow);
        }
    };
    const updateMinMaxRows = (sortedRowIndex: number) => {
        if (selectedCell) {
            const selectedCellSortedRowIndex = getSortedTableRowIndex(selectedCell);
            if (sortedRowIndex < selectedCellSortedRowIndex) {
                setMinRowSelected(sortedRowIndex);
                setMaxRowSelected(selectedCellSortedRowIndex);
            } else {
                setMinRowSelected(selectedCellSortedRowIndex);
                setMaxRowSelected(sortedRowIndex);
            }
        }
    };

    const renderMaxValue = () => {
        return (
            <Box
                onClick={() => {
                    onBlur(maxValue);
                    setCellValue(maxValue);
                }}
                sx={{
                    textWrap: 'nowrap',
                    textDecoration: `underline ${
                        highlightMax ? palette.semantic.semanticRed : palette.neutral.neutral6
                    }`,
                    color: highlightMax ? palette.semantic.semanticRed : palette.neutral.neutral6,
                    marginRight: '12px'
                }}>{`${maxValue} Max`}</Box>
        );
    };

    const isMax = (cell: Cell<DataRow, any>) => {
        return Number(cell.getValue()) === maxValue;
    };

    const blinkMax = () => {
        setHighlightMax(true);
        setHighlightMaxCellStyling?.(true);

        setTimeout(function () {
            setHighlightMax(false);
            setHighlightMaxCellStyling?.(false);
        }, 200);
    };

    const onChange = (value: any) => {
        if (value === '') setCellValue(null);
        if (inputValid(validationRule, value)) {
            if (maxValue) {
                if (value < maxValue) {
                    setCellValue(value);
                } else {
                    setCellValue(maxValue);
                    blinkMax();
                }
            } else {
                setCellValue(value);
            }
        }
    };

    const displayMaxPreview =
        cell.column.columnDef.meta?.maxColumnId &&
        cell.column.columnDef.meta?.displayableTargets &&
        cell.column.columnDef.meta?.displayableTargets.some(
            (key: string) => targets?.[key] !== null && targets?.hasOwnProperty(key)
        );

    const renderCellActionButtons = () => {
        let buttonsList = [];
        const cellActionButtons = cell.column.columnDef.meta?.actionButtons;
        if (cellActionButtons) {
            for (let i = 0; i < cellActionButtons?.length; i++) {
                switch (cellActionButtons[i]) {
                    case CELL_ACTION_BUTTON.COPY_HISTORICAL_RATE:
                        if (
                            cell.row.original.historic_rate !== null &&
                            Number(cell.row.original.historic_rate) > 0
                        )
                            buttonsList.push(
                                <ODIconButton
                                    icon={<HistoricRateIcon style={{ color: palette.black }} />}
                                    enableTooltip={true}
                                    title="Insert Historic Rate"
                                    id={`paste-historic-rate`}
                                    buttonType={BUTTON_TYPE.TRANSPARENT}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        cellEditsCallback?.([
                                            {
                                                rowId: cell.row.original.id as number,
                                                columnId: cell.column.columnDef.id as string,
                                                newValue: cell.row.original.historic_rate
                                            }
                                        ]);
                                    }}
                                    sx={{ marginRight: '4px' }}
                                />
                            );
                }
            }
        }
        return buttonsList;
    };

    return (
        <Box
            sx={{ height: '100%', cursor: 'pointer' }}
            id={cell.id}
            onClick={(e) => {
                handleClickedInteractiveCell(e.target, cell);
                setRowActionsClosed(true);
                e.stopPropagation();
            }}>
            <StyledDataCellBox
                sx={{ position: 'relative', height: '100%' }}
                cell={cell}
                selectedCell={selectedCell}
                isLastStickyColumn={isLastStickyColumn}
                isFirstScrollableColumn={isFirstScrollableColumn}
                isFirstDataColumn={isFirstDataColumn}
                separatedColumn={separatedColumn}
                highlightedMax={highlightMax}>
                <StyledInteractiveTableCell
                    id="styledInteractiveTableCell"
                    {...props}
                    key={cell.id}
                    onMouseEnter={() => handleMouseEnter(cell)}>
                    {selectedCell && selectedCell.id === cell.id ? (
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                marginLeft: '-9px',
                                paddingLeft: '9px',
                                marginRight: '-5px',
                                height: '100%',
                                alignItems: 'center'
                            }}>
                            <Stack direction="row" alignItems="center">
                                <ActiveInputCell
                                    key={`${cell.id}-input-cell`}
                                    id={cell.id}
                                    validationRule={cell.column.columnDef.meta?.validationRule}
                                    maxValue={maxValue}
                                    format={cell.column.columnDef.meta?.format}
                                    value={cellValue === null ? '' : cellValue}
                                    onFocus={() => {
                                        setInputFieldActive(true);
                                    }}
                                    onChangeProp={onChange}
                                    confirmInput={(val) => {
                                        onBlur(val);
                                        setCellValue(val);
                                    }}
                                    selectedCell={selectedCell}
                                    setMaxRowSelected={setMaxRowSelected}
                                    setMinRowSelected={setMinRowSelected}
                                    timeUnits={cell.column.columnDef.meta?.timeUnits}
                                />
                            </Stack>
                            {Boolean(maxValueColumnId) && maxValue !== null && renderMaxValue()}
                            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                {accommodatesUndos &&
                                    isRateOrVolModified(cell?.row?.original, cell?.column?.id) && (
                                        <ODIconButton
                                            icon={<UndoIcon sx={{ color: palette.black }} />}
                                            enableTooltip={true}
                                            title="Undo"
                                            buttonType={BUTTON_TYPE.TRANSPARENT}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const originalValue = handleUndoCell?.(
                                                    cell?.row?.original,
                                                    cell?.column?.id,
                                                    cellValue
                                                );
                                                setValueToCopy(originalValue);
                                            }}
                                            sx={{
                                                visibility: inputFieldActive ? 'hidden' : 'visible',
                                                marginRight: '4px'
                                            }}
                                        />
                                    )}
                                {cell.column.columnDef.meta?.actionButtons &&
                                    renderCellActionButtons()}
                                {cell.column.columnDef.meta?.hideQuickValueCopyingActions ? null : (
                                    <ODIconButton
                                        icon={<WrapTextIcon sx={{ color: palette.black }} />}
                                        enableTooltip={true}
                                        title="Apply All"
                                        buttonType={BUTTON_TYPE.TRANSPARENT}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setValueToCopy(cellValue);
                                            handleWrapTextButton(cell);
                                        }}
                                    />
                                )}
                            </Stack>
                        </Box>
                    ) : (
                        <NonactiveInputCell
                            cellStyle={cellStyle}
                            value={
                                displayMaxPreview && cell.column.columnDef.meta?.maxColumnId ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                        <Box sx={{ textDecoration: 'underline' }}>
                                            {addCommaSeparators(
                                                formatValue(cell.getValue()).toString()
                                            )}
                                            {cell.column.columnDef.meta?.timeUnits ?? ''}
                                        </Box>
                                        {isMax(cell) ? (
                                            '\u00A0Max'
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                <ArrowForward
                                                    sx={{
                                                        width: '18px',
                                                        height: '18px',
                                                        marginX: '8px',
                                                        color: palette.semantic.focusedBlue
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        color: palette.semantic.focusedBlue,
                                                        fontWeight: '600px',
                                                        textDecoration: 'none'
                                                    }}>
                                                    {`${Number(
                                                        cell.row.original[
                                                            cell.column.columnDef.meta?.maxColumnId
                                                        ]
                                                    )} Max`}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                ) : cell.getValue() === null ? null : (
                                    <NumericFormat
                                        displayType="text"
                                        value={cell.getValue()}
                                        thousandSeparator={true}
                                        decimalScale={
                                            cell.column.columnDef.meta?.format === 'dollars' ? 2 : 0
                                        }
                                        fixedDecimalScale={true}
                                        prefix={
                                            cell.column.columnDef.meta?.format === 'dollars'
                                                ? '$'
                                                : ''
                                        }
                                        suffix={`${cell.column.columnDef.meta?.timeUnits ?? ''}${
                                            isMax(cell) ? ' Max' : ''
                                        }`}
                                    />
                                )
                            }
                        />
                    )}
                </StyledInteractiveTableCell>
            </StyledDataCellBox>
        </Box>
    );
};

export default InteractiveCell;
