/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Grid, Tooltip, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import ReportSelectionButton from './ReportSelectionButton';
import CompareDisabled from './CompareDisabled';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { setSelectedReports } from 'redux/actions';
import { REPORT_STATUS } from 'constants/network';

const FooterReportSelection = ({ baseReport, compareReport }) => {
    const [info, setInfo] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const compareReports = (isSameBucket = true) => {
        if (!isSameBucket) return setInfo('ComparingDisabled');
        const payload = {
            selectedBaseReport: { id: baseReport.id, enabled: true },
            selectedCompareReport: { id: compareReport.id, enabled: true }
        };
        dispatch(setSelectedReports(payload));
        const bucketId = baseReport.bucket;
        const reportIds = `${baseReport.id}/${compareReport.id}`;
        const redirectUrl = `/reports/bucket/${bucketId}/${reportIds}/network/shipper`;
        navigate(redirectUrl);
    };

    const viewReport = (item) => {
        if (
            [REPORT_STATUS.COMPLETED, REPORT_STATUS.DRAFT, REPORT_STATUS.VFA_COMPLETED].includes(
                item.report_status
            )
        ) {
            dispatch(
                setSelectedReports({
                    selectedBaseReport: { id: item.id, enabled: true },
                    selectedCompareReport: {}
                })
            );
            const redirectUrl = `/reports/${
                item.bucket ? `bucket/${item.bucket}/` : `report-run/`
            }${item.id}${item.config_id ? `/config/${item.config_id}` : ''}/network/shipper`;
            navigate(redirectUrl);
        }
    };

    const isSameBucket = baseReport?.bucket === compareReport?.bucket;

    // TODO This safeguard is redundant. This footer component checks the report statuses to conditionally enable the compare button
    // but ReportSelection/index.js doesn't even render this footer component if the selected report status are not comaparable.
    // Moreover it's not even possible to select non comparable reports in ReportSelection/index.js.
    // If we do need to keep this safeguard, we should move isInComparableState() to /utils
    // because it is also used in ReportSelection/index.js

    const isInComparableState = (report) => {
        return [REPORT_STATUS.VFA_COMPLETED, REPORT_STATUS.COMPLETED, REPORT_STATUS.DRAFT].includes(
            report.report_status
        );
    };
    const hasCompare =
        baseReport &&
        compareReport &&
        isInComparableState(baseReport) &&
        isInComparableState(compareReport);

    const selectedName = [baseReport.name];
    if (compareReport) {
        if (isSameBucket) selectedName.push(<> + {compareReport.name}</>);
        else
            selectedName.push(
                <>
                    {' '}
                    + <s>{compareReport.name}</s>
                </>
            );
    }

    const reportName = selectedName.map((name) => name);

    const disable = !isSameBucket || !hasCompare;
    return (
        <Box
            key={`report-footer-selection`}
            sx={{
                margin: '8px',
                bottom: 0,
                width: '440px',
                borderRadius: '5px',
                position: 'absolute',
                backgroundColor: theme.palette.semantic.focusedBlue,
                zIndex: 100
            }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{
                    height: 56,
                    padding: '8px 16px'
                }}>
                <Grid item xs={6}>
                    <Box
                        style={{
                            fontSize: 14,
                            paddingRight: 4,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'flex'
                        }}>
                        <FolderSharpIcon
                            sx={{ color: theme.palette.neutral.white, paddingRight: '14px' }}
                        />
                        <Tooltip title={reportName}>
                            <Box
                                sx={{
                                    width: '150px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    marginRight: '16px',
                                    textOverflow: 'ellipsis',
                                    color: theme.palette.white
                                }}>
                                {reportName}
                            </Box>
                        </Tooltip>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    {baseReport && compareReport ? (
                        <ReportSelectionButton
                            disable={disable}
                            title="Compare Report"
                            buttonText={'Compare Report'}
                            onClick={() => compareReports(isSameBucket)}
                            endIcon={
                                <CompareArrowsIcon
                                    sx={{
                                        color: disable
                                            ? theme.palette.neutral.neutral6
                                            : theme.palette.neutral.white
                                    }}
                                />
                            }
                        />
                    ) : (
                        <ReportSelectionButton
                            title="View Report"
                            buttonText={'View Report'}
                            onClick={() => viewReport(baseReport)}
                            endIcon={
                                <ArrowForwardIcon sx={{ color: theme.palette.neutral.white }} />
                            }
                        />
                    )}
                </Grid>
            </Grid>
            <CompareDisabled onAction={() => setInfo(null)} open={info === 'ComparingDisabled'} />
        </Box>
    );
};

export default FooterReportSelection;
