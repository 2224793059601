import React, { useCallback, useMemo, lazy, Suspense } from 'react';
import { Stack, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import ODSnackbar, { ODSNACKBAR_TYPE } from 'shared/Snackbar';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { updateBidAnalysis } from 'redux/actions';
import palette from 'theme/palette';

const BidAnalysisTable = lazy(() => import('./BidAnalysisTable'));
const InflowOutflowButtons = lazy(() => import('./InflowOutflowButtons'));
const InflowOutflowTable = lazy(() => import('./InflowOutflowTable'));

const TableSection = styled(Stack)(() => ({
    position: 'relative',
    flex: '1 1 auto',
    height: '100%'
}));

const BidAnalysisTableContainer = styled(Stack)(() => ({
    backgroundColor: palette.white,
    fontSize: '14px',
    borderRadius: '4px',
    overflowY: 'auto',
    height: 'calc(100vh - 194px)'
}));

const BidAnalysisTablesContainer: React.FC = () => {
    const { selectedLaneId, openLaneScoreSlideout, showNomappingAlert } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const dispatch = useDispatch();

    const closeInfoSnackBar = useCallback(() => {
        dispatch(updateBidAnalysis({ showNomappingAlert: false }));
    }, [dispatch]);

    const InfoSnackbar = useMemo(
        () => (
            <ODSnackbar
                text={
                    'We were not able to provide a zip code. Please update your custom markets file or contact support for assistance.'
                }
                open={showNomappingAlert}
                backButtons={
                    <ODButton
                        onClick={() => {
                            closeInfoSnackBar();
                            window?.openWidget();
                        }}
                        buttonType={BUTTON_TYPE.BLUE}
                        buttonText="GET SUPPORT"
                        sx={{ fontWeight: 600 }}
                    />
                }
                onCloseHandler={closeInfoSnackBar}
                type={ODSNACKBAR_TYPE.HELP}
            />
        ),
        [showNomappingAlert, closeInfoSnackBar]
    );

    return (
        <BidAnalysisTableContainer
            width={`calc(100vw - ${openLaneScoreSlideout ? '590px' : '138px'})`}>
            {selectedLaneId !== null && (
                <Suspense fallback={<div>Loading...</div>}>
                    <InflowOutflowButtons />
                </Suspense>
            )}
            <TableSection>
                <Suspense fallback={<div>Loading...</div>}>
                    <BidAnalysisTable />
                </Suspense>
                {selectedLaneId !== null && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <InflowOutflowTable />
                    </Suspense>
                )}
            </TableSection>
            {InfoSnackbar}
        </BidAnalysisTableContainer>
    );
};

export default React.memo(BidAnalysisTablesContainer);
