import { Box } from '@mui/material';

interface AlertsProps {
    alerts: any[] | undefined | null;
}

const AlertsContainer = ({ alerts }: AlertsProps) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                width:
                    navigator.userAgent.indexOf('Firefox') > -1
                        ? '-moz-available'
                        : '-webkit-fill-available',
                zIndex: 1000,
                margin: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
            {alerts?.map((alert) => alert.alertComponent)}
        </Box>
    );
};

export default AlertsContainer;
