import { Box, styled, Collapse, Accordion, AccordionSummary, Tab } from '@mui/material';
import palette from 'theme/palette';

export const CustomCollapse = styled(Collapse)(() => ({
    border: `1px solid ${palette.neutral.neutral1}`,
    borderTop: 'none',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    marginBottom: '16px'
}));

export const CustomAccordion = styled(Accordion)(() => ({
    '&:before': {
        backgroundColor: 'transparent'
    }
}));
export const AvatarText = styled('span')(() => ({
    fontSize: '14px',
    fontWeight: 600
}));

export const CustomAccordionSummary = styled(AccordionSummary)(() => ({
    backgroundColor: palette.white,
    borderRadius: '4px',
    border: `1px solid ${palette.neutral.neutral1}`,
    marginTop: '2px',
    minHeight: '48px !important',
    maxHeight: '48px !important'
}));

export const AccordionHeader = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '8px',
    width: '100%',
    flexDirection: 'row'
}));

export const StyledRateTab = styled(Tab)(() => ({
    width: '50%',
    textTransform: 'none',
    '&.Mui-selected': { color: palette.black, fontWeight: 600 }
}));

export const SubHeader = styled(Box)(() => ({
    color: palette.neutral.neutral6,
    fontSize: '12px',
    marginTop: '16px',
    marginBottom: '4px'
}));

export const StyledSelectedRowDetail = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '20px',
    fontSize: '14px',
    padding: '10px',
    margin: '2px 0',
    borderRadius: '4px',
    backgroundColor: palette.ODLightBlueNeutral.lightBlue1
}));

export const StyledFreightBonusBody = styled(Box)(() => ({
    maxHeight: '180px',
    border: `1px solid ${palette.neutral.neutral1}`,
    borderRadius: '4px',
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '8px'
}));
