/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Avatar, Checkbox, FormLabel } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import InsightsIcon from '@mui/icons-material/Insights';

import {
    AccordionHeader,
    AvatarText,
    CustomAccordionSummary,
    CustomAccordion,
    CustomCollapse
} from './UploadedBidDataAccordion.styles';
import { ODTextField } from 'shared';
import { useEffect, useState } from 'react';
import { BID_CONFIG_INFO_MESSAGES } from 'constants/bidding';
import { isDuplicateBidAnalysisRunName, isUniqueBidAnalysisRunName } from 'utils/common.util';
import palette from 'theme/palette';
import { useTheme } from '@mui/system';

const UploadedBidDataAccordion = ({
    baseAndNetworkReports,
    setBidAccordionNoErrors,
    setBidAnalysesRuns,
    bidAnalysesRuns,
    numOfRateAdjustmentStudyAccordions,
    manualOpenOverride
}: {
    baseAndNetworkReports: any[];
    setBidAccordionNoErrors: any;
    setBidAnalysesRuns: any;
    bidAnalysesRuns: any;
    numOfRateAdjustmentStudyAccordions: number;
    manualOpenOverride: { value: boolean };
}) => {
    const selectOptions = baseAndNetworkReports.map((report: any) => {
        return { ...report, value: report.id };
    });

    // Latest Base Report
    const latestBase = selectOptions.filter(
        (report: any) => report?.id === report?.latest_base?.id
    )[0];
    const latestBaseIndex = selectOptions.indexOf(latestBase);
    if (latestBaseIndex !== -1) {
        selectOptions.unshift(selectOptions.splice(latestBaseIndex, 1)[0]);
    }
    const theme = useTheme();
    const [inputErrorRun, setInputErrorRun] = useState<string | null>(null);
    const [inputErrorFinalizedRun, setInputErrorFinalized] = useState<string | null>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [bidAnalysisRunName, setBidAnalysisRunName] = useState<string>('');
    const [finalizedBidAnalysisRunName, setFinalizedBidAnalysisRunName] = useState<string>('');
    const [openDrawer, setOpenDrawer] = useState({
        upload_bid: true,
        finalized_bid: true
    }) as any;
    const [manualOpen, setManualOpen] = useState<boolean>(manualOpenOverride.value);
    const uploadBidAccordionState = openDrawer['upload_bid'] as any;
    const finalizedBidAccordionState = openDrawer['finalized_bid'] as any;

    useEffect(() => {
        setManualOpen(manualOpenOverride.value);
    }, [manualOpenOverride]);

    const updateApiData = (
        bidAnalysisRunName: string,
        checked: boolean,
        finalizedBidAnalysisRunName: string,
        selectedReport: any
    ) => {
        setBidAnalysesRuns({
            ...bidAnalysesRuns,
            uploadedBidData: {
                bidAnalysisRunName,
                checked,
                finalizedBidAnalysisRunName,
                selectedReport
            }
        });
    };

    useEffect(() => {
        // Handle disable Run button
        if (!checked && !inputErrorRun) setBidAccordionNoErrors(true);
        else if (checked && !inputErrorRun && !inputErrorFinalizedRun)
            setBidAccordionNoErrors(true);
        else setBidAccordionNoErrors(false);

        // Auto Select checkbox if user enter a name
        if (finalizedBidAnalysisRunName) setChecked(true);

        // Handle field required and maximum characters reached
        if (!bidAnalysisRunName) setInputErrorRun('*Field Required');
        if (checked && !finalizedBidAnalysisRunName) setInputErrorFinalized('*Field Required');
        else if (!checked) setInputErrorFinalized(null);
        updateApiData(bidAnalysisRunName, checked, finalizedBidAnalysisRunName, latestBase);
    }, [
        bidAnalysisRunName,
        checked,
        finalizedBidAnalysisRunName,
        inputErrorRun,
        inputErrorFinalizedRun
    ]);

    useEffect(() => {
        const isOpen = manualOpen || numOfRateAdjustmentStudyAccordions === 0;
        setOpenDrawer({ ...openDrawer, upload_bid: isOpen, finalized_bid: isOpen });
    }, [numOfRateAdjustmentStudyAccordions, manualOpen]);

    // Handle Duplicate run names
    useEffect(() => {
        if (
            bidAnalysisRunName &&
            isDuplicateBidAnalysisRunName(bidAnalysesRuns, bidAnalysisRunName)
        )
            setInputErrorRun('File name already exists.');
        else if (bidAnalysisRunName) setInputErrorRun(null);
        if (
            (bidAnalysisRunName && bidAnalysisRunName === finalizedBidAnalysisRunName) ||
            isUniqueBidAnalysisRunName(bidAnalysesRuns, finalizedBidAnalysisRunName) > 1
        )
            setInputErrorFinalized('File name already exists.');
        else if (finalizedBidAnalysisRunName) setInputErrorFinalized(null);
    }, [bidAnalysisRunName]);
    useEffect(() => {
        if (
            finalizedBidAnalysisRunName &&
            isDuplicateBidAnalysisRunName(bidAnalysesRuns, finalizedBidAnalysisRunName)
        )
            setInputErrorFinalized('File name already exists.');
        else if (finalizedBidAnalysisRunName) setInputErrorFinalized(null);
        if (
            (bidAnalysisRunName && bidAnalysisRunName === finalizedBidAnalysisRunName) ||
            isUniqueBidAnalysisRunName(bidAnalysesRuns, bidAnalysisRunName) > 1
        )
            setInputErrorRun('File name already exists.');
        else if (bidAnalysisRunName) setInputErrorRun(null);
    }, [finalizedBidAnalysisRunName]);

    return (
        <>
            <CustomAccordion
                key={`uploaded-bid-data-accordion`}
                expanded={uploadBidAccordionState}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e) => {
                    _e.stopPropagation();
                    setOpenDrawer({
                        ...openDrawer,
                        upload_bid: !uploadBidAccordionState,
                        finalized_bid: !uploadBidAccordionState
                    });
                    setManualOpen(!openDrawer.upload_bid);
                }}>
                <CustomAccordionSummary
                    expandIcon={
                        <KeyboardArrowDownSharp sx={{ color: theme.palette.common.black }} />
                    }
                    aria-controls="accordion-content"
                    id={`accordion`}>
                    <AccordionHeader>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                sx={{
                                    bgcolor:
                                        inputErrorRun || inputErrorFinalizedRun
                                            ? theme.palette.semantic.semanticRed
                                            : theme.palette.semantic.focusedBlue,

                                    width: 24,
                                    height: 24,
                                    marginRight: '16px'
                                }}>
                                <AvatarText>1</AvatarText>
                            </Avatar>
                            <Typography
                                style={{
                                    color: 'black',
                                    fontSize: '14px'
                                }}>
                                <b>Uploaded Bid Data</b>
                            </Typography>
                        </Box>
                    </AccordionHeader>
                </CustomAccordionSummary>
            </CustomAccordion>
            <CustomCollapse
                in={uploadBidAccordionState}
                timeout="auto"
                unmountOnExit
                key={`select-base-network`}>
                <Box sx={{ margin: '24px 16px 0' }}>
                    <span>{BID_CONFIG_INFO_MESSAGES.UPLOADED_BID_DATA_MESSAGE}</span>
                </Box>

                <Box display="flex" flexDirection="column" margin="16px">
                    <FormLabel sx={{ color: palette.neutral.neutral6, fontSize: 12 }}>
                        Base Network
                    </FormLabel>
                    <Typography marginTop="4px" marginLeft="16px">
                        {latestBase?.name}
                    </Typography>
                </Box>
                <Box sx={{ margin: '16px' }}>
                    <ODTextField
                        value={bidAnalysisRunName}
                        helperText={`${bidAnalysisRunName?.length}/50 Character Limit`}
                        infoText={
                            bidAnalysisRunName.length >= 50 ? '*Maximum characters reached' : null
                        }
                        errorText={inputErrorRun}
                        autoFocus={true}
                        dataTestId="enter-bid-analysis-run-name"
                        label="Name Bid Analysis Run*"
                        onChange={(event) => {
                            setBidAnalysisRunName(event?.target?.value);
                            if (inputErrorRun) setInputErrorRun(null);
                        }}
                        name="saveBidAnalysisRun"
                        customInputProps={{ maxLength: 50 }}
                    />
                </Box>
                <Box sx={{ margin: '16px' }}>
                    <CustomAccordion
                        key={`finalized-bid-analysis-accordion`}
                        expanded={finalizedBidAccordionState}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            _e.stopPropagation();
                            setOpenDrawer({
                                ...openDrawer,
                                finalized_bid: !finalizedBidAccordionState
                            });
                        }}>
                        <CustomAccordionSummary
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.common.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion-finalized-bid-analysis`}>
                            <AccordionHeader>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={checked}
                                        onChange={(e: any) => {
                                            setOpenDrawer({
                                                ...openDrawer
                                            });
                                            if (checked) setFinalizedBidAnalysisRunName('');
                                            setChecked(!checked);
                                        }}
                                        sx={{
                                            marginRight: '8px',
                                            marginLeft: '-8px'
                                        }}
                                    />
                                    <InsightsIcon
                                        fontSize="small"
                                        sx={{
                                            marginRight: '16px',
                                            color: theme.palette.common.black
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: 'black',
                                            fontSize: '14px'
                                        }}>
                                        <b>Generate Finalized Bid Analysis</b>
                                    </Typography>
                                </Box>
                            </AccordionHeader>
                        </CustomAccordionSummary>
                    </CustomAccordion>
                    <CustomCollapse
                        in={finalizedBidAccordionState}
                        timeout="auto"
                        unmountOnExit
                        key={`select-min-finalized-bid-analysis`}>
                        <Box sx={{ margin: '24px 16px 16px' }}>
                            <span>{BID_CONFIG_INFO_MESSAGES.FINALIZED_BID_ANALYSIS_MESSAGE}</span>
                        </Box>

                        <Box sx={{ margin: '16px' }}>
                            <ODTextField
                                value={finalizedBidAnalysisRunName}
                                errorText={inputErrorFinalizedRun}
                                infoText={
                                    finalizedBidAnalysisRunName.length >= 50
                                        ? '*Maximum characters reached'
                                        : null
                                }
                                autoFocus={false}
                                helperText={`${finalizedBidAnalysisRunName?.length}/50 Character Limit`}
                                dataTestId="enter-finalized-bid-analysis"
                                label="Name Finalized Bid Analysis Run*"
                                onChange={(event) => {
                                    setFinalizedBidAnalysisRunName(event?.target?.value);
                                    if (inputErrorFinalizedRun) setInputErrorFinalized(null);
                                }}
                                name="generateFinalizedBidAnalysis"
                                customInputProps={{ maxLength: 50 }}
                            />
                        </Box>
                    </CustomCollapse>
                </Box>
            </CustomCollapse>
        </>
    );
};

export default UploadedBidDataAccordion;
