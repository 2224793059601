import { ODIconButton } from 'shared';
import { useSelector } from 'react-redux';
import { LOAD_SOURCE } from 'constants/network';
import { RootState } from 'types/redux';
import { BUTTON_TYPE } from 'constants/colors';
import theme from 'theme';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ViewListIcon from '@mui/icons-material/ViewList';

interface TableSettingsListButtonProps {
    handleClickedGear: any;
}
const SettingsGearContainer = styled(Box)(() => ({
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-6px'
}));

const TableSettingsListButton = ({ handleClickedGear }: TableSettingsListButtonProps) => {
    const { loadSource } = useSelector((state: RootState) => state.ReportsReducers);

    //TODO temp set up until the flag is gone.
    const setButtonType = () => {
        if (loadSource === LOAD_SOURCE.ALL) {
            return BUTTON_TYPE.LIGHT;
        } else return null;
    };

    const setButtonTextColor = () => {
        if (loadSource === LOAD_SOURCE.ALL) {
            return theme.palette.common.black;
        } else return theme.palette.common.white;
    };

    return (
        <SettingsGearContainer>
            <ODIconButton
                onClick={handleClickedGear}
                icon={<ViewListIcon />}
                buttonType={setButtonType()}
                sx={{
                    width: '32px',
                    height: '32px',
                    color: setButtonTextColor(),
                    borderRadius: '50%'
                }}
            />
        </SettingsGearContainer>
    );
};

export default TableSettingsListButton;
