import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import draftStore from 'utils/draftStore.util';

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const prevLocation = usePrevious(location?.pathname);

    useEffect(() => {
        draftStore.clear();
    }, []);

    useEffect(() => {
        draftStore.set('prevLocation', prevLocation);
    }, [prevLocation]);

    return children;
};

const usePrevious = (value = null) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export default PrivateRoute;
