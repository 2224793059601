/* eslint-disable eqeqeq */
import { first, isEmpty } from 'lodash';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
//utils
import { getLoadSourceQueryParam, getFreightBonusQueryParam } from 'utils/network.util';
import { NETWORK_TABLE_TYPE, REPORT_STATUS } from 'constants/network';
import { DRIVER_MODE } from 'constants/drivers';
import {
    onSetConfigurationSuccess,
    updateNetworkView,
    getConfigurationByRunId,
    getDomicilesByReportId,
    getParamsByConfigId,
    getKPIbyReportId,
    updateDriverReducer,
    getTableDataByIdAndFilters,
    setLoadFilters,
    resetDomicile,
    resetDriverType,
    updateDriverMoments,
    getConfigurationActions,
    createBidAnalysisConfiguration,
    updateBidAnalysis,
    updateConfigurationState,
    setConfiguration,
    settingsClear
} from 'redux/actions';
import { useParamsProps } from 'types/hooks';
import { useLoadingHook } from 'utils/hooks';
import { useSimulationHook } from 'utils/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
const driverDomQueryParam = 'driver_domiciles'; // remove this boilerplate when we remove the v2KpIs feature flag

const useNetworkViewHook = () => {
    const { v2KpIs } = useFlags();
    const navigate = useNavigate();
    const location = useLocation();
    const { configuration } = useSelector((state: RootState) => state.ConfigurationReducer);
    const { slideout } = useSelector((state: RootState) => state.NetworkReducer);
    const { compare_run, base_run, analysis_id, config_id, bid_config_id }: useParamsProps =
        useParams();
    const { showLoader } = useLoadingHook();
    const { createSimulationOnEdit } = useSimulationHook();
    const currReportId = compare_run || base_run;
    const dispatch = useDispatch();
    const {
        domicile,
        driverType,
        driverMoments,
        //!TODO driverTours: { type },
        multiDriverToursToggle,
        checked
    } = useSelector((state: RootState) => state.DriversReducer);
    const isDriverMoments = !isEmpty(driverMoments);
    const { bidAnalysisSetting, currBidAnalysisData } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const { initialReportRunData } = useSelector((state: RootState) => state.ReportKPIReducer);
    const { time_aggregation } = bidAnalysisSetting;
    const { loadFilters, loadSource, isFreightBonusApplied, reportDetail } = useSelector(
        (state: RootState) => state.ReportsReducers
    );
    const { currTab, driverMode } = useSelector((state: RootState) => state.NetworkReducer);

    const fetchConfigActionsByIds = () => {
        dispatch(
            getConfigurationActions(
                analysis_id ?? base_run,
                configuration?.id ?? config_id ?? reportDetail?.configuration,
                time_aggregation
            )
        );
    };

    const currReport =
        useSelector((state: RootState) => state.ReportKPIReducer.initialReportRunData)?.find(
            (x: any) => x.id === Number(compare_run || base_run)
        ) || {};

    const updateBaseAndCompareReportState = () => {
        const runReportData = (initialReportRunData || []).filter(
            ({ report_status }: { report_status: REPORT_STATUS }) =>
                [
                    REPORT_STATUS.VFA_COMPLETED,
                    REPORT_STATUS.COMPLETED,
                    REPORT_STATUS.RETRAINED
                ].includes(report_status)
        );

        if (runReportData && runReportData.length > 0) {
            let baseReportData = base_run
                ? runReportData.find(
                      ({ id }: { id: string | number }) => Number(id) === Number(base_run)
                  )
                : first(runReportData);

            let compareReportData = compare_run
                ? runReportData.find(
                      ({ id }: { id: string | number }) => Number(id) === Number(compare_run)
                  )
                : {};

            dispatch(
                updateNetworkView({
                    baseReport: baseReportData,
                    compareReport: compareReportData
                })
            );
        }
    };

    //TODO update these reset into reusable functions.
    const refreshNetworkViewTable = () => {
        dispatch(settingsClear());
        dispatch(
            updateDriverReducer({
                checked: {
                    allDomiciles: false,
                    allDriverTypes: false,
                    allDriverIds: false,
                    selectedDomicile: [],
                    selectedDriverType: [],
                    selectedDriverId: []
                }
            })
        );

        dispatch(
            updateNetworkView({
                checked: {
                    allShippers: false,
                    allLanes: false,
                    selectedShippers: [],
                    selectedLanes: []
                }
            })
        );

        dispatch(
            getTableDataByIdAndFilters(
                base_run,
                {
                    compare_run_id: compare_run,
                    ...getLoadSourceQueryParam(loadSource),
                    ...getFreightBonusQueryParam(isFreightBonusApplied)
                },
                v2KpIs
            )
        );
        dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
    };

    const handleParametersDialog = async (bidAnalysisView: Boolean) => {
        showLoader(true);
        let simulationId;
        let simulation;
        if (bidAnalysisView) {
            if (!currBidAnalysisData.config_id) {
                simulation = await createBidAnalysisConfiguration({ currBidAnalysisData });
                simulationId = simulation.id;
            } else {
                simulationId = currBidAnalysisData.config_id;
            }
        } else {
            simulationId = await createSimulationOnEdit();
        }
        if (simulation?.id) {
            dispatch(
                updateBidAnalysis({
                    currBidAnalysisData: {
                        ...currBidAnalysisData,
                        config_id: simulation?.id
                    }
                })
            );
            navigate(`/bid-analysis/${currBidAnalysisData.id}/config/${simulation.id}`);
            showLoader(false);
            await dispatch(setConfiguration(simulation));
        } else {
            dispatch(
                updateConfigurationState({
                    creatingConfiguration: false,
                    error: 'Failed to create draft'
                })
            );
            showLoader(false);
        }
        await dispatch(getParamsByConfigId(simulationId));
        dispatch(updateNetworkView({ showParameterDialog: true }));
        showLoader(false);
    };

    const onBidAnalysis = analysis_id !== undefined;
    const onBidConfig = bid_config_id !== undefined;

    const handleHistorySlideout = () => {
        dispatch(updateNetworkView({ showHistorySlideout: true }));
        if (currReportId) dispatch(getDomicilesByReportId(currReportId));
        if (!onBidAnalysis && !onBidConfig) fetchConfigActionsByIds();
    };

    const clearScreen = () => {
        if (multiDriverToursToggle) {
            // !TODO viewDriverTours({ clear: true, type }). See driver tours placeholder methods
        } else if (isDriverMoments) {
            dispatch(updateDriverMoments(null));
        } else if (driverType) {
            dispatch(resetDriverType(Boolean(domicile)));
        } else if (domicile) {
            dispatch(resetDomicile());
        } else {
            //!TODO replace with swap icon
        }
    };

    const handleBackToPreviousView = (reset = false) => {
        showLoader(true);
        let baseFilter = compare_run ? { compare_run_id: compare_run } : {};

        dispatch(
            updateNetworkView({
                showDriverOptionBar: false,
                checked: {
                    allShippers: false,
                    allLanes: false,
                    selectedShippers: [],
                    selectedLanes: []
                }
            })
        );

        if (currTab !== NETWORK_TABLE_TYPE.DRIVERS || reset) {
            dispatch(
                getKPIbyReportId({
                    runId: base_run,
                    filter: baseFilter,
                    v2KpIsFlag: v2KpIs
                })
            );
            dispatch(
                getTableDataByIdAndFilters(
                    base_run,
                    {
                        ...baseFilter,
                        ...getLoadSourceQueryParam(loadSource),
                        ...getFreightBonusQueryParam(isFreightBonusApplied)
                    },
                    v2KpIs
                )
            );
            dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
        } else {
            clearScreen();

            if (driverMode === DRIVER_MODE.DOMICILE) {
                dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DEFAULT }));
                dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDomicile: [],
                            selectedDriverType: []
                        }
                    })
                );
                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter: baseFilter,
                        v2KpIsFlag: v2KpIs
                    })
                );
            } else if (driverMode === DRIVER_MODE.DRIVER_TYPES) {
                dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DOMICILE }));
                dispatch(setLoadFilters({ ...loadFilters, isFilterSet: true }));
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverType: []
                        }
                    })
                );
                let filterObj = {};

                filterObj = {
                    [driverDomQueryParam]: domicile?.domicile_id
                };

                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter:
                            Object.keys(filterObj)?.length === 0
                                ? baseFilter
                                : {
                                      ...filterObj,
                                      ...baseFilter
                                  },
                        v2KpIsFlag: v2KpIs
                    })
                );
            } else if (driverMode === DRIVER_MODE.DRIVER_ID) {
                dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DRIVER_TYPES }));
                dispatch(setLoadFilters({ ...loadFilters, isFilterSet: true }));
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverId: []
                        }
                    })
                );
                let filterObj = {};

                filterObj = {
                    [driverDomQueryParam]: domicile?.domicile_id,
                    driver_types: driverType?.id
                };

                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter:
                            Object.keys(filterObj)?.length === 0
                                ? baseFilter
                                : {
                                      ...filterObj,
                                      ...baseFilter
                                  },
                        v2KpIsFlag: v2KpIs
                    })
                );
            }
        }
        showLoader(false);
    };

    const handleBackToPreviousView_NEW = (reset = false, currDriverStep = null) => {
        showLoader(true);
        let baseFilter = compare_run ? { compare_run_id: compare_run } : {};

        dispatch(
            updateNetworkView({
                showDriverOptionBar: false,
                checked: {
                    allShippers: false,
                    allLanes: false,
                    selectedShippers: [],
                    selectedLanes: []
                }
            })
        );

        if (currTab !== NETWORK_TABLE_TYPE.DRIVERS || reset) {
            dispatch(
                getKPIbyReportId({
                    runId: base_run,
                    filter: baseFilter,
                    v2KpIsFlag: v2KpIs
                })
            );
            dispatch(
                getTableDataByIdAndFilters(
                    base_run,
                    {
                        ...baseFilter,
                        ...getLoadSourceQueryParam(loadSource),
                        ...getFreightBonusQueryParam(isFreightBonusApplied)
                    },
                    v2KpIs
                )
            );
            dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
        } else {
            clearScreen();

            if (currDriverStep === 'drivers') {
                dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DEFAULT }));
                dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDomicile: [],
                            selectedDriverType: []
                        }
                    })
                );
                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter: baseFilter,
                        v2KpIsFlag: v2KpIs
                    })
                );
            } else if (currDriverStep === DRIVER_MODE.DOMICILE) {
                dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DOMICILE }));
                dispatch(setLoadFilters({ ...loadFilters, isFilterSet: true }));
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverType: []
                        }
                    })
                );
                let filterObj = {};

                filterObj = {
                    [driverDomQueryParam]: domicile?.domicile_id
                };

                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter:
                            Object.keys(filterObj)?.length === 0
                                ? baseFilter
                                : {
                                      ...filterObj,
                                      ...baseFilter
                                  },
                        v2KpIsFlag: v2KpIs
                    })
                );
            } else if (currDriverStep === DRIVER_MODE.DRIVER_TYPES) {
                dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DRIVER_TYPES }));
                dispatch(setLoadFilters({ ...loadFilters, isFilterSet: true }));
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverId: []
                        }
                    })
                );
                let filterObj = {};

                filterObj = {
                    [driverDomQueryParam]: domicile?.domicile_id,
                    driver_types: driverType?.id
                };

                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter:
                            Object.keys(filterObj)?.length === 0
                                ? baseFilter
                                : {
                                      ...filterObj,
                                      ...baseFilter
                                  },
                        v2KpIsFlag: v2KpIs
                    })
                );
            }
        }
        showLoader(false);
    };

    const openAssociatedReportSlideout = async () => {
        showLoader(true);
        if (currReport?.id) {
            dispatch(setLoadFilters({ ...loadFilters, isSimulation: true }));
            dispatch(onSetConfigurationSuccess({ openManageSimulation: true }));
            await dispatch(getConfigurationByRunId(currReport?.id));
        } else {
            console.error('currReport?.id did not exist.');
        }
        showLoader(false);
    };

    const navigateBackToBidAnalysisView = () => {
        navigate(-1);
    };

    const closeAddNewDomicileSlideout = () => {
        dispatch(
            updateNetworkView({
                slideout: {
                    ...slideout,
                    addNewDriver: false
                }
            })
        );
    };

    const closeEditDriverSlideout = () => {
        dispatch(
            updateNetworkView({
                slideout: {
                    ...slideout,
                    editCurrDriver: false
                }
            })
        );
    };

    const closeAlertModal = () => {
        dispatch(
            updateNetworkView({
                showAlertModal: false
            })
        );
    };

    const networkImpactView = location.state?.networkImpactView;

    return {
        currReportId,
        currReport,
        clearScreen,
        fetchConfigActionsByIds,
        handleBackToPreviousView_NEW,
        handleBackToPreviousView,
        handleHistorySlideout,
        handleParametersDialog,
        navigateBackToBidAnalysisView,
        networkImpactView,
        openAssociatedReportSlideout,
        updateBaseAndCompareReportState,
        closeAddNewDomicileSlideout,
        closeEditDriverSlideout,
        closeAlertModal,
        refreshNetworkViewTable
    };
};

export default useNetworkViewHook;
