import React from 'react';
import {
    Backdrop,
    BackdropProps,
    Box,
    CircularProgress,
    Typography,
    useTheme
} from '@mui/material';
export interface ODLoaderProps extends BackdropProps {
    text: string;
}

const ODLoader = ({ open, text, ...rest }: ODLoaderProps) => {
    const theme = useTheme();
    return (
        <Backdrop
            sx={{ backgroundColor: `${theme.palette.common.black}40`, zIndex: 9999 }}
            open={open}
            {...rest}>
            <Box
                sx={{
                    width: 456,
                    height: 112,
                    borderRadius: '4px',
                    backgroundColor: 'common.white',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'common.white',
                        p: 4,
                        width: '100%'
                    }}>
                    <Typography sx={{ fontSize: '18px' }}>{text}</Typography>
                    <CircularProgress color="success" size="24px" />
                </Box>
            </Box>
        </Backdrop>
    );
};

export default ODLoader;
