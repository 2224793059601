import { Box, Input, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useState, useEffect } from 'react';
import { useFilterStyles, ClearButton, FooterLabel } from './FilterStyles';
import clsx from 'clsx';
import CircleIcon from '@mui/icons-material/Circle';
import { ExpandMoreSharp } from '@mui/icons-material/';
import { AlertTriangleIcon } from 'assets/images';
import CloseIcon from '@mui/icons-material/Close';
import { FilterList } from '@mui/icons-material';
import { RATING } from 'types/bidAnalysis';
import { Table } from '@tanstack/react-table';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import {
    useODTable,
    ODTableContextInterface,
    ODTableProviderProps
} from 'shared/ODTable/ODTableContext';
import { formatTwoDecimals } from 'utils/number.util';
import { isEmpty } from './filterUtils';
import { useTheme } from '@mui/system';

const NumberRangeFilterV8WithThresholds = ({ column }: any) => {
    const [focused, setFocused] = useState('');
    const [hovered, setHovered] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [thresholdDropdownOpen, setThresholdDropdownOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const columnFilterValue = column.getFilterValue() as undefined | [number, number];
    const [facetedMin, setFacetedMin] = useState<number>(-Infinity);
    const [facetedMax, setFacetedMax] = useState<number>(Infinity);
    const [selectedThreshold, setSelectedThreshold] = useState<RATING | ''>('');
    const classes = useFilterStyles();
    const theme = useTheme();
    const { table } = useODTable() as ODTableContextInterface &
        ODTableProviderProps & { table: Table<DataRow> };

    const isFiltered = column.getIsFiltered();
    useEffect(() => {
        if (!isFiltered) setSelectedThreshold('');
    }, [isFiltered]);
    useEffect(() => {
        const facetedMinMax = column.getFacetedMinMaxValues();
        setFacetedMin(formatTwoDecimals(facetedMinMax?.[0]) ?? -Infinity);
        setFacetedMax(formatTwoDecimals(facetedMinMax?.[1]) ?? Infinity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column.getFacetedMinMaxValues()]);

    const updateFilterValue = (value: (number | null | undefined)[] | undefined) => {
        column.setFilterValue(value);
        table.setPageIndex(0);
    };

    const onUpdate = (count: number | string | null, type: string) => {
        let newFilterValue;
        if (type === 'min') {
            if (count === '') newFilterValue = [null, columnFilterValue?.[1]];
            else newFilterValue = [Number(count), columnFilterValue?.[1]];
        } else if (type === 'max') {
            if (count === '') newFilterValue = [columnFilterValue?.[0], null];
            else newFilterValue = [columnFilterValue?.[0], Number(count)];
        }
        updateFilterValue(newFilterValue);
    };

    const validateInput = (value: number | undefined, type: string) => {
        if (value !== null && value !== undefined) {
            if (columnFilterValue) {
                if (
                    type === 'min' &&
                    columnFilterValue?.[1] !== null &&
                    value > columnFilterValue?.[1]
                ) {
                    onUpdate(columnFilterValue[1], type);
                }
                if (
                    type === 'max' &&
                    columnFilterValue?.[0] !== null &&
                    value < columnFilterValue?.[0]
                ) {
                    onUpdate(columnFilterValue[0], type);
                }
            }
            if (facetedMax !== null && value > facetedMax) onUpdate(facetedMax, type);
            if (facetedMin !== null && value < facetedMin) onUpdate(facetedMin, type);
        }
    };

    const minFieldEnabled = () => {
        return focused === 'min' || hovered === 'min' || columnFilterValue?.[0] !== null;
    };
    const maxFieldEnabled = () => {
        return focused === 'max' || hovered === 'max' || columnFilterValue?.[1] !== null;
    };

    const thresholds = [
        {
            value: '',
            icon: <FilterList fontSize="small" sx={{ marginRight: '16px' }} />,
            min: 0,
            max: 100
        },
        {
            value: RATING.IDEAL,
            icon: (
                <CircleIcon
                    fontSize="small"
                    sx={{ marginRight: '16px', color: theme.palette.semantic.semanticGreen }}
                />
            ),
            min: column.id === 'lane_score' ? 70 : 1,
            max: column.id === 'lane_score' ? 100 : 1
        },
        {
            value: RATING.TOLERABLE,
            icon: (
                <Box sx={{ display: 'flex' }}>
                    <AlertTriangleIcon
                        style={{
                            marginRight: '16px',
                            fill: theme.palette.semantic.semanticYellow
                        }}
                    />
                </Box>
            ),
            min: column.id === 'lane_score' ? 11 : 2,
            max: column.id === 'lane_score' ? 69 : 3
        },
        {
            value: RATING.UNACCEPTABLE,
            icon: (
                <CloseIcon
                    fontSize="small"
                    sx={{
                        marginRight: '16px',
                        color: theme.palette.semantic.semanticRed
                    }}
                />
            ),
            min: column.id === 'lane_score' ? 11 : 4,
            max: column.id === 'lane_score' ? 69 : 4
        }
    ];

    const handleSelectThreshold = (e: SelectChangeEvent) => {
        if (e.target.value === '') setSelectedThreshold('');
        setSelectedThreshold(e.target.value as RATING);
        switch (e.target.value) {
            case RATING.IDEAL:
                if (column.id === 'lane_score') updateFilterValue([70, 100]);
                else updateFilterValue([1, 1]);
                break;
            case RATING.TOLERABLE:
                if (column.id === 'lane_score') updateFilterValue([11, 69]);
                else updateFilterValue([2, 3]);
                break;
            case RATING.UNACCEPTABLE:
                if (column.id === 'lane_score') updateFilterValue([0, 10]);
                else updateFilterValue([4, 4]);
                break;
            case '':
                updateFilterValue([null, null]);
                break;
        }
    };

    const thresholdDropdown = (
        <Box>
            <Select
                displayEmpty
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <FilterList sx={{ display: 'flex', justifyContent: 'center' }} />;
                    }
                    return thresholds.find((t) => t.value === selected)?.icon;
                }}
                open={thresholdDropdownOpen}
                MenuProps={{
                    classes: {
                        paper: clsx(classes.menu)
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }}
                className={(classes.dropdownborder, classes.lightModeAutocompleteClosed)}
                sx={{
                    width: '50px',
                    height: '24px',
                    borderRadius: '2px 0 0 2px',
                    marginRight: '-1px',
                    '& .MuiInputBase-input': {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingRight: 0
                    },
                    border: 'none',
                    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                        ...(selectedThreshold === ''
                            ? { paddingLeft: '6px' }
                            : { paddingLeft: '22px' })
                    },
                    /* Remove the blue outline */
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                    },
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                }}
                labelId="threshold"
                id={`${column.id}-threshold-filter`}
                value={selectedThreshold}
                onChange={(e) => handleSelectThreshold(e)}
                onOpen={() => {
                    setThresholdDropdownOpen(true);
                    setIsOpen(true);
                }}
                onClose={() => {
                    setThresholdDropdownOpen(false);
                    setIsOpen(false);
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                IconComponent={() => (
                    <IconButton
                        onClick={(e) => setThresholdDropdownOpen(!thresholdDropdownOpen)}
                        size="medium"
                        sx={{ padding: 0, marginRight: '4px' }}>
                        <ExpandMoreSharp
                            sx={{
                                marginRight: 0,
                                transform: thresholdDropdownOpen ? 'rotate(180deg)' : '',
                                color:
                                    isOpen ||
                                    isHovered ||
                                    (columnFilterValue && columnFilterValue?.length > 0)
                                        ? theme.palette.common.black
                                        : theme.palette.neutral.neutral6
                            }}
                        />
                    </IconButton>
                )}>
                {thresholds.map((t) => (
                    <MenuItem sx={{ width: '46px' }} key={t.value} value={t.value}>
                        {t.icon}
                    </MenuItem>
                ))}
            </Select>
            <FooterLabel sx={{ marginTop: '1px' }}>Filter</FooterLabel>
        </Box>
    );

    const minValueFilter = (
        <Box sx={{ marginRight: '2px', borderRadius: '0 2px 2px 0' }}>
            <Input
                id={`${column.id}-min-filter-input`}
                onFocus={() => setFocused('min')}
                onMouseEnter={() => setHovered('min')}
                onMouseLeave={() => setHovered('')}
                onBlur={() => {
                    setSelectedThreshold('');
                    validateInput(columnFilterValue?.[0], 'min');
                    setHovered('');
                }}
                disableUnderline
                className={clsx(classes.numberInput, classes.lightModeNumberInput)}
                sx={{
                    marginRight: '2px',
                    backgroundColor:
                        focused === 'min' || hovered === 'min'
                            ? theme.palette.neutral.neutral2
                            : '',
                    color: minFieldEnabled()
                        ? theme.palette.common.white
                        : theme.palette.neutral.neutral3
                }}
                placeholder={`${
                    columnFilterValue?.[0] ?? facetedMin === -Infinity ? '' : facetedMin
                }`}
                value={columnFilterValue?.[0] ?? ''}
                type="number"
                onChange={(e) => {
                    setSelectedThreshold('');
                    onUpdate(e.target.value, 'min');
                }}
                endAdornment={undefined}
            />
            <FooterLabel sx={{ display: 'flex' }}>
                <Box>Min</Box>
                {isEmpty(columnFilterValue?.[0]) ? null : (
                    <Box
                        sx={{
                            textTransform: 'capitalize',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: theme.palette.semantic.focusedBlue,
                            marginLeft: '4px'
                        }}
                        onClick={() => {
                            updateFilterValue([null, columnFilterValue?.[1]]);
                            setSelectedThreshold('');
                        }}>
                        Clear
                    </Box>
                )}
            </FooterLabel>
        </Box>
    );

    const maxValueFilter = (
        <Box sx={{ marginRight: '2px' }}>
            <Input
                id={`${column.id}-max-filter-input`}
                onFocus={() => setFocused('max')}
                onMouseEnter={() => setHovered('max')}
                onMouseLeave={() => setHovered('')}
                onBlur={() => {
                    setSelectedThreshold('');
                    validateInput(columnFilterValue?.[1], 'max');
                    setHovered('');
                }}
                disableUnderline
                className={clsx(
                    classes.numberInput,
                    classes.lightModeNumberInput,
                    focused === 'max' && classes.lightModeNumberInputFocused
                )}
                sx={{
                    marginRight: '2px',
                    backgroundColor:
                        focused === 'max' || hovered === 'max'
                            ? theme.palette.neutral.neutral2
                            : '',
                    color: maxFieldEnabled()
                        ? theme.palette.common.white
                        : theme.palette.neutral.neutral3
                }}
                placeholder={`${
                    columnFilterValue?.[1] ?? facetedMax === Infinity ? '' : facetedMax
                }`}
                value={columnFilterValue?.[1] ?? ''}
                type="number"
                onChange={(e) => {
                    setSelectedThreshold('');
                    onUpdate(e.target.value, 'max');
                }}
                endAdornment={undefined}
            />
            <FooterLabel sx={{ display: 'flex' }}>
                <Box>Max</Box>
                {isEmpty(columnFilterValue?.[1]) ? null : (
                    <ClearButton
                        onClick={() => {
                            updateFilterValue([columnFilterValue?.[0], null]);
                            setSelectedThreshold('');
                        }}>
                        Clear
                    </ClearButton>
                )}
            </FooterLabel>
        </Box>
    );

    return (
        <div>
            <div className="flex space-x-2">
                <Box
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    onBlur={(event) => {
                        if (!event.currentTarget.contains(event.relatedTarget)) setFocused('');
                    }}>
                    {thresholdDropdown}
                    {minValueFilter}
                    {maxValueFilter}
                </Box>
            </div>
            <div className="h-1" />
        </div>
    );
};

export default NumberRangeFilterV8WithThresholds;
