import { Checkbox, Theme } from '@mui/material';
import palette from 'theme/palette';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import IndeterminateCheckBoxSharpIcon from '@mui/icons-material/IndeterminateCheckBoxSharp';
import { SxProps } from '@mui/system';

interface CheckboxProps {
    isChecked?: boolean;
    isDisabled?: boolean;
    onChecked: any;
    color?: string;
    indeterminate?: boolean;
    sx?: SxProps<Theme>;
    value?: any;
    lightMode?: boolean;
}
const ODCheckbox = ({
    isChecked = false,
    isDisabled = false,
    onChecked,
    lightMode = true,
    color = lightMode ? palette.black : palette.white,
    sx,
    ...restProps
}: CheckboxProps) => {
    return (
        <Checkbox
            value={restProps.value}
            icon={<CheckBoxOutlineBlankSharpIcon />}
            checkedIcon={<CheckBoxSharpIcon />}
            indeterminateIcon={<IndeterminateCheckBoxSharpIcon />}
            sx={{
                color: lightMode ? palette.neutral.neutral6 : palette.neutral.neutral3,
                '&.MuiSvgIcon-root': {
                    fontSize: '18px'
                },
                '&.Mui-checked': {
                    color: isDisabled ? palette.neutral.neutral6 : color
                },
                '&.MuiCheckbox-indeterminate': {
                    color: color
                },
                ...sx
            }}
            checked={isChecked}
            onClick={onChecked}
            disabled={isDisabled}
            {...restProps}
        />
    );
};

export default ODCheckbox;
