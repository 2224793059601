import { ODModal } from 'shared';
import RequestQuoteSharpIcon from '@mui/icons-material/RequestQuoteSharp';
import { Box, Tooltip, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/system';

const BidConfigFileName = styled(Typography)(() => ({
    fontWeight: 600,
    maxWidth: '280px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}));

const SAVE_BID_VERBIAGE =
    'Saving the changes to your bid file will override the original data. If you would like to preserve the original data in this file, we recommend you duplicate this file and apply your changes to the copy.';

type SaveBidDialogProps = {
    onClose: () => void;
    open: boolean;
    onSave: () => void;
    title: string | null;
};
const SaveBidFileChangeDialog = (props: SaveBidDialogProps) => {
    const theme = useTheme();
    const { onClose, open, onSave, title } = props;

    const Title = styled(Box)(() => ({
        backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
        borderRadius: '4px',
        paddingLeft: '19px',
        height: '40px',
        fontSize: 14,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px'
    }));
    return (
        <ODModal
            show={open}
            heading="Save Bid File Changes"
            handleClose={onClose}
            actionButtonText="Save"
            handleAction={onSave}
            children={
                <>
                    <Title>
                        <RequestQuoteSharpIcon sx={{ marginRight: '16px' }} />
                        <Tooltip title={title as any}>
                            <BidConfigFileName>{title}</BidConfigFileName>
                        </Tooltip>
                    </Title>
                    <Typography>{SAVE_BID_VERBIAGE}</Typography>
                </>
            }
        />
    );
};

export default SaveBidFileChangeDialog;
