import React from 'react';
import { Autocomplete, TextField, Box, Checkbox, ListItemIcon, Tooltip } from '@mui/material/';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import IndeterminateCheckBoxSharpIcon from '@mui/icons-material/IndeterminateCheckBoxSharp';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import palette from 'theme/palette';
import KeyboardArrowUpSharp from '@mui/icons-material/KeyboardArrowUpSharp';
import { useTheme } from '@mui/system';

const SelectTextfield = ({
    options = [],
    style = {},
    value = [] as any,
    customIcon = null,
    label = ' ',
    placeholder = '',
    multiple = false,
    variant = 'standard',
    id = 'auto-complete-component',
    className = 'auto-complete-component',
    ...restProps
}) => {
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{ fontSize: '12px', color: theme.palette.neutral.neutral6 }}
                id={`${label}-select-driver-type-select-label`}>
                {label}
            </Box>
            <Autocomplete
                sx={{
                    backgroundColor: palette.white,
                    '& .MuiInputBase-input': {
                        padding: '10px 16px !important',
                        fontSize: '14px',
                        width: '100%',
                        color: palette.neutral.neutral6,
                        '&:hover': {
                            color: `${palette.neutral.black} !important`,
                            bacgroundColor: 'red'
                        },
                        '&:target': {
                            color: `${palette.neutral.black} !important`
                        },
                        '&:active': {
                            color: palette.neutral.black,
                            '.MuiSvgIcon-root': {
                                color: palette.neutral.black
                            }
                        }
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        border: 'none'
                    },
                    '&:hover .MuiSvgIcon-root': {
                        color: palette.neutral.black
                    },
                    '&.Mui-focused': {
                        '& .MuiInputBase-input': {
                            color: palette.neutral.black
                        },
                        '& .MuiSvgIcon-root': {
                            color: palette.neutral.black
                        }
                    },
                    '& .MuiSvgIcon-root': {
                        right: 0
                    }
                }}
                multiple={multiple}
                id={id}
                options={options}
                disableCloseOnSelect={multiple}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props: any, option: any, { selected = false }) => (
                    <MenuItem
                        {...props}
                        value={option}
                        id={`option-${option?.id}`}
                        key={`${option?.id}-${option.name}-option`}
                        sx={{
                            width: '392px',
                            height: '40px',
                            backgroundColor: palette.neutral.neutral1,
                            '&:hover': {
                                backgroundColor: palette.neutral.neutral2
                            }
                        }}>
                        <ListItemIcon>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankSharpIcon />}
                                checkedIcon={<CheckBoxSharpIcon />}
                                indeterminateIcon={<IndeterminateCheckBoxSharpIcon />}
                                sx={{
                                    width: '14px',
                                    padding: 0,
                                    color: palette.neutral.neutral6,
                                    '&.Mui-checked': {
                                        color: palette.black
                                    }
                                }}
                                checked={option.selected || selected}
                            />
                        </ListItemIcon>
                        <Box sx={{ fontSize: '14px' }}>
                            {option.name} {option.selected || selected}
                        </Box>
                    </MenuItem>
                )}
                renderTags={(values) => (
                    <Box>{multiple ? `(${values.length}) Selected` : value}</Box>
                )}
                renderInput={(params: any) => {
                    return (
                        <>
                            <TextField
                                {...restProps}
                                sx={{
                                    '& .MuiInput-root': {
                                        fontSize: '14px',
                                        width: '100%',
                                        padding: '10px 16px !important',
                                        color: theme.palette.neutral.neutral6,
                                        '&.MuiAutocomplete-inputRoot': {
                                            padding: '10px 16px'
                                        },
                                        '&:hover': {
                                            color: theme.palette.common.black,
                                            backgroundColor: palette.neutral.neutral2
                                        },
                                        '&:hover:not(.Mui-disabled):before': {
                                            borderBottom: palette.neutral.neutral2
                                        },
                                        '&:target': {
                                            color: theme.palette.common.black
                                        },
                                        '&:active': {
                                            color: theme.palette.common.black,
                                            '.MuiSvgIcon-root': {
                                                color: theme.palette.common.black
                                            }
                                        }
                                    },
                                    '& .MuiInput-input': {
                                        padding: '0 !important',
                                        fontSize: 14,
                                        color: theme.palette.neutral.neutral6
                                    },
                                    '& .MuiInput-root::before': {
                                        borderBottom: `1px solid ${theme.palette.neutral.neutral2}`
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: palette.semantic.focusedBlue
                                    },
                                    '& .MuiAutocomplete-endAdornment': {
                                        position: 'relative'
                                    },
                                    '& .MuiButtonBase-root': {
                                        //TODO placeholder
                                    },
                                    '& .MuiInputAdornment-root': {
                                        width: 18,
                                        height: 18
                                    },
                                    '& .MuiInputAdornment-root svg': {
                                        fill: theme.palette.neutral.neutral6
                                    },
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: 'transparent',
                                        borderBottom: palette.neutral.neutral2,
                                        paddingRight: 0,
                                        borderRadius: '0px',
                                        '&:hover': {
                                            backgroundColor: palette.neutral.neutral2
                                        },
                                        '&:hover:not(.Mui-disabled):before': {
                                            borderBottom: palette.neutral.neutral2
                                        },
                                        '&.Mui-focused': {
                                            background: palette.ODLightBlueNeutral.lightBlue1
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: palette.neutral.black
                                        }
                                    }
                                }}
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {value.length ? (
                                                <Tooltip title="Close">
                                                    <Box
                                                        onClick={(_e) => {
                                                            _e.stopPropagation();
                                                            restProps?.onChange(_e, [], true);
                                                        }}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            marginRight: '16px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                        <CloseIcon />
                                                    </Box>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}

                                            {customIcon ? (
                                                customIcon
                                            ) : (
                                                <KeyboardArrowUpSharp
                                                    sx={{
                                                        transform: params?.InputProps?.endAdornment
                                                            ?.props?.ownerState?.focused
                                                            ? 'rotate(0deg)'
                                                            : 'rotate(-180deg)'
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) as any
                                }}
                                placeholder={
                                    multiple
                                        ? value.length === 0 && `Select Option`
                                        : ('Select Option' as any)
                                }
                                variant="standard"
                            />
                        </>
                    );
                }}
                value={value}
                {...restProps}
            />
        </>
    );
};

export default SelectTextfield;
