import { Box, Typography } from '@mui/material';
import { Checkbox } from 'shared';
import LockSharpIcon from '@mui/icons-material/LockSharp';
import { useTheme } from '@mui/system';

const DropdownOption = ({ option, selected, handleCheckbox }: any) => {
    const theme = useTheme();
    const locked = option?.required;

    return (
        <Box
            sx={{
                backgroundColor: locked
                    ? theme.palette.neutral.neutral1
                    : selected
                    ? theme.palette.ODLightBlueNeutral.lightBlue1
                    : 'none',
                color: !selected || locked ? theme.palette.neutral.neutral6 : 'black',
                height: '32px',
                width: '100%',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1px',
                fontSize: '14px'
            }}>
            <Box>
                {option.required ? (
                    <Box
                        sx={{
                            padding: '8px',
                            display: 'flex',
                            width: '36px',
                            height: '36px',
                            boxSizing: 'border-box',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <LockSharpIcon />
                    </Box>
                ) : (
                    <Checkbox
                        isChecked={selected}
                        isDisabled={locked}
                        value={option.id}
                        onChecked={(e: any) => {
                            handleCheckbox(e);
                        }}
                    />
                )}
            </Box>
            <Box component={Typography}>{option.name}</Box>
        </Box>
    );
};

export default DropdownOption;
