import React from 'react';
import { Box } from '@mui/material';
import { Close, LinkOff, SubdirectoryArrowRight } from '@mui/icons-material';
import { AnyObject } from 'yup/lib/types';
import palette from 'theme/palette';
import { DriverLanesIcon } from 'assets/images';
import { ODCard, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { StyledPaper, TextContent } from './HistorySlideout.styles';
import theme from 'theme';

interface historyHandleUnlinkCard {
    key: string;
    type: string;
    handleRestore: (e: any, data: AnyObject) => any;
    id: string;
    value: any;
    readOnly?: boolean;
}
const HistoryHandleUnlinkCard = ({
    id,
    type,
    key,
    value,
    handleRestore,
    readOnly
}: historyHandleUnlinkCard) => {
    return (
        <StyledPaper readOnly={readOnly} key={key}>
            <ODCard sx={{ justifyContent: 'space-between' }}>
                <Box display={'flex'}>
                    <DriverLanesIcon
                        style={{
                            margin: '4px 6px 0 0',
                            color: theme.palette.common.black,
                            fill: theme.palette.common.black
                        }}
                    />

                    <TextContent id={`shipper-id-${value?.shipper}`}>
                        <b style={{ marginRight: '4px' }}>Lane ID:</b> {value.lane}
                    </TextContent>
                </Box>
                {!readOnly && (
                    <ODIconButton
                        disableHover={true}
                        sx={{ padding: 0 }}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        icon={<Close sx={{ color: palette.neutral.black }} />}
                        onClick={(e) => handleRestore(e, { id, ...value })}
                    />
                )}
            </ODCard>

            <ODCard>
                <SubdirectoryArrowRight sx={{ marginRight: '16px' }} />
                <b>Shipper ID:</b>
                <span
                    style={{
                        marginLeft: '4px',
                        textDecoration: type === 'remove' ? 'line-through' : 'none'
                    }}>
                    {value.shipper}
                </span>
            </ODCard>

            <ODCard>
                <LinkOff sx={{ marginRight: '16px' }} />
                Unlinked Combination
            </ODCard>
        </StyledPaper>
    );
};

export default HistoryHandleUnlinkCard;
