import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import palette from 'theme/palette';

export const PaginationContainer = styled(Box)(() => ({
    minHeight: '56px',
    height: '56px',
    backgroundColor: palette.white,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingRight: '11px',
    marginX: 0,
    fontSize: '14px',
    color: palette.ODBlue.odBlue7,
    position: 'static',
    marginTop: 'auto',
    borderTop: `1px solid ${palette.neutral.neutral2}`,
    borderRadius: 'none'
}));

export const PaginationButton = styled(IconButton)(() => ({
    color: palette.ODBlue.odBlue7,
    minWidth: 0,
    width: '40px',
    height: '40px',
    margin: '0',
    '&:disabled': {
        color: palette.neutral.neutral6,
        backgroundColor: palette.neutral.neutral4
    }
}));
