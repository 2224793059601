/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LocalShippingSharpIcon from '@mui/icons-material/LocalShippingSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { ODButton } from 'shared';
import { updateNetworkView } from 'redux/actions/networkActions';
import { useDispatch, useSelector } from 'react-redux';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DriverLanesIcon } from 'assets/images';
import { NETWORK_TABLE_TYPE } from 'constants/network';
import { RootState } from 'redux/reducers';
import { useNetworkViewHook } from 'utils/hooks';
import { BUTTON_TYPE } from 'constants/colors';
import { useTheme } from '@mui/system';

const TableModeDropdown = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { handleBackToPreviousView_NEW } = useNetworkViewHook();
    const { currTab } = useSelector(({ NetworkReducer }: RootState) => NetworkReducer);
    const [buttonText, setButtonText] = useState(currTab);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleDropdownOption = (id: string) => {
        dispatch(
            updateNetworkView({
                currTab: id,
                showDriverOptionBar: false,
                currShipperActionBar: null
            })
        );
        handleBackToPreviousView_NEW(true);
        setAnchorEl(null);
        setButtonText(id);
    };

    const setStartIconByTableType = (mode: string) => {
        let currStartIcon = 'Unspecified' as React.ReactNode;

        if (mode === NETWORK_TABLE_TYPE.SHIPPERS) {
            currStartIcon = <LocalShippingSharpIcon />;
        } else if (mode === NETWORK_TABLE_TYPE.DRIVERS) {
            currStartIcon = <LocationOnIcon />;
        } else if (mode === NETWORK_TABLE_TYPE.LANES) {
            currStartIcon = <DriverLanesIcon style={{ color: theme.palette.common.white }} />;
        }

        return currStartIcon;
    };

    useEffect(() => {
        setButtonText(currTab);
    }, [currTab]);

    return (
        <>
            <ODButton
                buttonType={BUTTON_TYPE.DARK}
                startIcon={setStartIconByTableType(buttonText)}
                endIcon={<KeyboardArrowDownSharpIcon />}
                sx={{ margin: '8px', width: '156px' }}
                buttonText={buttonText}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
            />
            <Menu
                sx={{
                    '& .MuiPaper-root': {
                        width: '156px',
                        marginTop: '2px',
                        backgroundColor: theme.palette.ODBlue.odBlue9,
                        color: theme.palette.common.white,

                        '& .MuiMenu-list': {
                            padding: '4px 0'
                        },
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                backgroundColor: theme.palette.ODBlue.odBlue10
                            },
                            textTransform: 'capitalize',
                            fontSize: '14px',
                            '&:active': {
                                backgroundColor: theme.palette.action.selectedOpacity
                            }
                        }
                    }
                }}
                id="table-type-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'button-for-network-table-type'
                }}>
                {[
                    NETWORK_TABLE_TYPE.SHIPPERS,
                    NETWORK_TABLE_TYPE.DRIVERS,
                    NETWORK_TABLE_TYPE.LANES
                ].map((option: any, i: React.Key | null | undefined) => {
                    return (
                        <MenuItem key={i} onClick={() => handleDropdownOption(option)}>
                            {option}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default TableModeDropdown;
