import React, { useState } from 'react';
import { SelectedItem } from 'shared';
import { styled } from '@mui/material/styles';
import { Box, Collapse, Accordion, Typography, AccordionSummary } from '@mui/material';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import AddIcon from '@mui/icons-material/Add';
//import { BidFile } from 'components/Bidding/BiddingTypes';
import { useTheme } from '@mui/system';

interface SelectedBidsAccordionProps {
    id: string;
    name: string;
    onExpand: any;
    onCheckedSelection: any;
    selectedBidFiles: any[];
}

const SelectedBidsAccordion = ({
    id = 'bidinfo',
    name = 'Selected Bid Files',
    onCheckedSelection,
    selectedBidFiles
}: SelectedBidsAccordionProps) => {
    const [expanded, toggleExpand] = useState(true);
    const theme = useTheme();

    const Label = styled(Box)(() => ({
        marginBottom: '8px',
        color: theme.palette.neutral.neutral6,
        fontWeight: 'normal',
        fontSize: '12px'
    }));

    const NoBid = styled(Box)(() => ({
        height: 40,
        fontSize: 14,
        color: theme.palette.neutral.neutral6,
        border: `1px solid ${theme.palette.ODLightBlueNeutral.lightBlue1}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }));

    const renderSelectedBidsList = () => {
        return (
            <Box style={{ marginBottom: '16px' }}>
                <Label>Bid Files</Label>
                {selectedBidFiles?.length > 0 ? (
                    selectedBidFiles.map((bidFile) => (
                        <SelectedItem
                            onChecked={(e: Event) => {
                                onCheckedSelection(bidFile.id, e);
                            }}
                            id={bidFile.id}
                            name={bidFile.file_name}
                            key={bidFile.id}></SelectedItem>
                    ))
                ) : (
                    <NoBid>No Bid Selected</NoBid>
                )}
            </Box>
        );
    };

    return (
        <div
            style={{
                marginBottom: 16,
                marginTop: '8px',
                borderBottom: `1px solid ${theme.palette.neutral.neutral1}`
            }}
            key={`accordion-${id}`}>
            <Accordion
                key={`reportInfo-list-${id}`}
                expanded={expanded}
                style={{ backgroundColor: theme.palette.neutral.neutral1, fontSize: 14 }}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e, expanded) => toggleExpand(expanded)}>
                <AccordionSummary
                    expandIcon={
                        <KeyboardArrowDownSharpIcon
                            sx={{
                                color: expanded
                                    ? theme.palette.common.black
                                    : theme.palette.neutral.neutral9
                            }}
                        />
                    }
                    aria-controls="accordion-content"
                    id={`accordion-${id}`}>
                    <AddIcon sx={{ color: theme.palette.common.black }} />
                    <Typography style={{ paddingLeft: 16, fontWeight: 600, color: 'black' }}>
                        {name}
                    </Typography>
                </AccordionSummary>
            </Accordion>
            <Collapse in={expanded} timeout="auto" unmountOnExit key={`report-selected-${id}`}>
                <Box sx={{ marginTop: '16px' }}>{renderSelectedBidsList()}</Box>
            </Collapse>
        </div>
    );
};

export default SelectedBidsAccordion;
