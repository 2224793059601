import React from 'react';
import { Box, Typography } from '@mui/material';
import { Checkbox } from 'shared';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';

interface SelectedItemProps {
    onChecked: any;
    id: number;
    name: string;
}
const SelectedItem = ({ onChecked, id, name }: SelectedItemProps) => {
    const theme = useTheme();
    const Details = styled(Box)(() => ({
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
        fontSize: 14,
        height: '40px',
        borderRadius: '4px',
        margin: '2px 0'
    }));
    return (
        <Details>
            <Checkbox
                isChecked={true}
                indeterminate={false}
                onChecked={(e: any) => {
                    onChecked(e);
                }}
            />
            <Typography style={{ paddingLeft: '2px', fontSize: '14px' }}>{name}</Typography>
        </Details>
    );
};

export default SelectedItem;
