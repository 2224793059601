import React from 'react';
import { SxProps, TablePagination } from '@mui/material';
import { useTheme } from '@mui/system';

export interface ODTablePaginationFromCommonProps {
    selectId?: string;
    count: number;
    rowsPerPage: number;
    page: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number
    ) => void;
    onRowsPerPageChange:
        | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
        | undefined;
    sx: SxProps;
}

const ODTablePaginationFromCommon = ({
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    selectId,
    sx,
    ...rest
}: ODTablePaginationFromCommonProps) => {
    const theme = useTheme();
    return (
        <TablePagination
            sx={{
                color: theme.palette.neutral.neutral6,
                '& .MuiToolbar-root': {
                    minHeight: '56px'
                },
                '& .MuiSelect-icon': { display: 'none' },
                '& .MuiTablePagination-select': {
                    padding: '0 !important',
                    height: '40px !important',
                    width: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: `1px solid ${theme.palette.neutral.neutral2}`,
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                    '&:hover': {
                        backgroundColor: theme.palette.neutral.neutral1,
                        border: 'none',
                        color: theme.palette.common.black
                    }
                },
                '& .MuiInputBase-root': {
                    marginLeft: '16px',
                    '& .MuiTablePagination-select': {
                        borderRadius: '4px'
                    }
                },
                '& .MuiTablePagination-actions': {
                    margin: '0 16px',
                    '& .MuiButtonBase-root': {
                        padding: 0,
                        height: '40px',
                        width: '40px',
                        borderRadius: '4px',
                        '&:first-of-type': {
                            marginRight: '16px'
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral1
                        }
                    }
                },
                ...sx
            }}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage="Rows Per Page:"
            SelectProps={{
                id: selectId,
                MenuProps: {
                    transformOrigin: {
                        vertical: 209,
                        horizontal: 20
                    },
                    elevation: 0,
                    sx: {
                        '& .MuiPaper-root > .MuiList-root': {
                            padding: 0,
                            '& .MuiMenuItem-root': {
                                padding: 0,
                                fontSize: '14px',
                                height: '40px',
                                width: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: theme.palette.neutral.neutral1,
                                '&:hover': {
                                    backgroundColor: theme.palette.neutral.neutral2
                                },
                                '&.Mui-selected': {
                                    backgroundColor: theme.palette.neutral.neutral2
                                }
                            }
                        }
                    }
                }
            }}
            {...rest}
        />
    );
};

export default ODTablePaginationFromCommon;
