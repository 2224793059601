import { Table } from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';
import { VALIDATION_RULE } from 'utils/common.util';
type CellEdit = {
    rowId: number;
    columnId: string;
    newValue: any;
};
type DataRow = {
    [key: string]: string | number | object | null;
};
type CellStyleParams = {
    padding?: number | string;
    paddingLeft?: number | string;
    paddingRight?: number | string;
    fontSize?: number | string;
    backgroundColor?: string;
    borderLeft?: string;
    borderRight?: string;
    borderTop?: string;
    borderBottom?: string;
    border?: string;
    display?: string;
    alignItems?: string;
    marginLeft?: number | string;
    boxSizing?: string;
};
type InteractiveCellProps = {
    cell: any;
    cellStyle: {};
    initialCellValue: string | number | object | null;
    validationRule: VALIDATION_RULE;
    isLastStickyColumn?: boolean;
    isFirstScrollableColumn?: boolean;
    isFirstDataColumn?: boolean;
    separatedColumn?: boolean;
    setHighlightMaxCellStyling?: (value: boolean) => void;
};
type CrossColumnCopyOptionsButtonProps = {
    text: string;
};
interface ColumnHeaderData {
    columnName: string;
    columnId: string;
}
interface CrossColumnCopyDetails {
    destColumnHeaderData: ColumnHeaderData;
    sourceColumns: ColumnHeaderData[];
}
interface ODTableProps {
    globalFilter?: string[];
    searchBarGlobalFilter?: any;
    setGlobalFilter?: () => void;
    checkedRows?: any[];
    setCheckedRows?: Dispatch<SetStateAction<number[]>> | ((r: number[]) => void);
    tableKey?: string;
    addPagination?: boolean;
    lockOverflowY?: boolean;
    rateColumnRef?: any;
    setPageSizeInHook?: any;
    setPageIndexInHook?: any;
    manualPagination?: boolean;
    bottomActionBar?: boolean;
    totalItems?: number | undefined;
}
interface ColumnDetails {
    filterType?: string | null;
    id: string;
    name: string;
    required?: boolean; // column will always be displayed in table
    visibleByDefault?: boolean; // column will be displayed in table when table first loads
    validationRule?: symbol; // format that this column's values must follow. e.g. Nonnegative Float. See utils/number.util.
    cellStyle?: any;
    sticky?: 'left' | 'right';
    /* A style object e.g. {color: "red"}. cellStyle applies to interactive cells (cells that take user input) and has no effect on noninteractive cells.
     * for noninteractive cells, specify the style rules in the columnHelper.accessor object. See historic rate in BidConfigColumns.tsx for an example.
     */
}

interface ActionButton {
    description: string;
    icon: JSX.Element;
    callback: (rowId: number, table: Table<DataRow>) => void;
}

// We can extend this type to include additional details about the error. For example, error type if a cell can have various errors.
interface CellError {
    rowId: number;
    columnId: string;
}
interface TableErrors {
    [key: number]: CellError[];
}

interface ColumnFormatSelection {
    dataCategory: string;
    formatOption: { value: string; label: string };
}

export enum CELL_ACTION_BUTTON {
    COPY_HISTORICAL_RATE = 'copy historical rate'
}

export type {
    ActionButton,
    CellEdit,
    CellStyleParams,
    ColumnDetails,
    ColumnFormatSelection,
    ColumnHeaderData,
    CrossColumnCopyDetails,
    CrossColumnCopyOptionsButtonProps,
    DataRow,
    InteractiveCellProps,
    ODTableProps,
    CellError,
    TableErrors
};
