import { styled, Box, Typography, Collapse, Accordion, AccordionSummary } from '@mui/material';
import palette from 'theme/palette';

export const Title = styled(Box)(() => ({
    borderRadius: '4px',
    paddingLeft: '19px',
    height: '40px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
}));

export const DrawerHeader = styled(Box)(() => ({
    height: '56px',
    padding: '0 16px',
    flex: '0 0 auto',
    borderBottom: `1px solid ${palette.neutral.neutral1}`,
    display: 'flex',
    alignItems: 'center'
}));

export const HeaderTitle = styled(Typography)(() => ({
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: 400
}));

export const StyledHeaderActions = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

export const LaneContainer = styled(Box)(() => ({
    padding: '8px 0'
}));

export const CustomCollapse = styled(Collapse)(() => ({
    border: `1px solid ${palette.neutral.neutral1}`,
    borderTop: 'none',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    marginBottom: '16px'
}));

export const CustomAccordion = styled(Accordion)(() => ({
    '&:before': {
        backgroundColor: 'transparent'
    }
}));

export const CustomAccordionSummary = styled(AccordionSummary)(() => ({
    backgroundColor: palette.white,
    borderRadius: '4px',
    border: `1px solid ${palette.neutral.neutral1}`,
    marginTop: '2px'
}));

export const AverageHeader = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row'
}));
