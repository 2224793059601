/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { updateNetworkView, updateParamsByConfigId } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadingHook } from 'utils/hooks';
import Box from '@mui/material/Box';
import { ODButton } from 'shared';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import MuiIconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import ParametersTabs from 'components/NetworkView/TopNavbar/components/ParametersTabs';
import { BUTTON_TYPE } from 'constants/colors';
import { useStyles } from './Modals.styles';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import { useTheme } from '@mui/system';

const Parameters = React.memo(() => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        parametersData: { version, data }
    } = useSelector((state: RootState) => state.ReportsReducers);
    const { config_id }: any = useParams();
    const { showLoader } = useLoadingHook();

    const { showParameterDialog } = useSelector((state: RootState) => state.NetworkReducer);

    const driver_types = data?.driver_types || [];
    const [editParams, setEditParams] = useState(false);
    const [cancelChange, setCancelChange] = useState(false);
    const [updatedFieldData, setUpdatedFieldData] = useState([]);

    const triggerEditParameters = (bool: boolean) => {
        setEditParams(bool);
        setCancelChange(!bool);
    };

    const triggerSaveParameters = async () => {
        dispatch(updateNetworkView({ showParameterDialog: false }));
        showLoader(true);
        const name = version?.version_name + '_' + moment().valueOf();
        let updatedData = {
            version: { ...version, version_name: name },
            data: {
                driver_types: updatedFieldData
            }
        };
        dispatch(updateParamsByConfigId(config_id, updatedData));
        setEditParams(!editParams);
        showLoader(false);
    };

    useEffect(() => {
        if (driver_types?.length > 0) {
            setUpdatedFieldData(driver_types);
        }
    }, [driver_types]);

    return (
        <Dialog
            open={showParameterDialog}
            keepMounted
            classes={{ paper: classes.dialogPaper }}
            onClose={() => dispatch(updateNetworkView({ showParameterDialog: false }))}
            aria-describedby="dialog-for-download-report">
            <Box id="top-section-params">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0px 32px',
                        height: '56px',
                        borderBottom: `1px solid ${theme.palette.neutral.neutral1}`
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TuneSharpIcon
                            sx={{ marginRight: '18.25px', color: theme.palette.common.black }}
                        />
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '14px',
                                display: 'flex',
                                fontFamily: 'IBM Plex Sans'
                            }}>
                            Parameters
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                        {!editParams && (
                            <ODButton
                                endIcon={
                                    <EditSharpIcon
                                        sx={{
                                            marginLeft: '10.25px',
                                            color: theme.palette.common.black
                                        }}
                                    />
                                }
                                buttonType={BUTTON_TYPE.GREY}
                                buttonText="Edit Parameters"
                                onClick={() => triggerEditParameters(true)}
                                sx={{ marginRight: '16px' }}
                            />
                        )}

                        <MuiIconButton
                            className={classes.iconButton}
                            onClick={() =>
                                dispatch(updateNetworkView({ showParameterDialog: false }))
                            }
                            aria-label="close modal"
                            classes={{ root: classes.menuIconButton }}
                            size="large">
                            <CloseIcon sx={{ color: theme.palette.common.black }} />
                        </MuiIconButton>
                    </Box>
                </Box>
                <ParametersTabs
                    cancelChange={cancelChange}
                    setUpdatedFieldData={setUpdatedFieldData}
                    editParams={editParams}
                    updatedFieldData={updatedFieldData}
                />
            </Box>

            {editParams && (
                <Box
                    sx={{
                        padding: '8px 32px',
                        fontSize: '14px',
                        color: theme.palette.common.white,
                        fontWeight: 400,
                        backgroundColor: theme.palette.semantic.focusedBlue,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <Box
                        component="section"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <TuneSharpIcon
                            sx={{ marginRight: '18.25px', color: theme.palette.common.white }}
                        />
                        <span style={{ fontWeight: 600 }}>Parameter: </span>
                        Variable Cost
                    </Box>

                    <Box component="section" sx={{ display: 'flex' }}>
                        <ODButton
                            buttonType={BUTTON_TYPE.BLUE}
                            buttonText="Cancel"
                            onClick={() => triggerEditParameters(false)}
                            sx={{ border: '1px solid black', marginRight: '16px' }}
                        />
                        <ODButton
                            buttonType={BUTTON_TYPE.DARK}
                            buttonText="Save Parameters"
                            onClick={() => triggerSaveParameters()}
                        />
                    </Box>
                </Box>
            )}
        </Dialog>
    );
});

export default Parameters;
