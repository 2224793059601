import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import palette from 'theme/palette';
import { Box } from '@mui/material';

export const useFilterStyles = makeStyles(() => ({
    listbox: {
        padding: 0
    },
    popupIndicator: {
        padding: 0
    },

    // Textbox
    textbox: {
        '& .MuiInputBase-root': {
            fontSize: 10,
            '& .Mui-active': {
                border: 'none'
            },
            '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                height: '100%',
                padding: 0
            }
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: '8px',
            top: 'calc(50% - 9px)'
        },
        '& .MuiOutlinedInput-root': {
            height: 22
        }
    },
    darkModeTextbox: {
        '& .MuiInputBase-root': {
            '& .Mui-active': {
                color: palette.white,
                backgroundColor: palette.ODBlue.odBlue8
            }
        },
        '& input::placeholder': {
            color: palette.neutral.neutral3,
            opacity: 1
        }
    },
    lightModeTextbox: {
        '& .MuiInputBase-root': {
            backgroundColor: palette.neutral.neutral1,
            '& .Mui-active': {
                color: palette.black,
                backgroundColor: palette.neutral.neutral2
            }
        },
        '& input::placeholder': {
            color: palette.black,
            opacity: 1
        }
    },

    // Popper
    lightModePopper: {
        '& .MuiAutocomplete-paper': {
            backgroundColor: palette.neutral.neutral2,
            color: palette.black,
            '& li:hover': { backgroundColor: palette.neutral.neutral3 }
        }
    },
    darkModePopper: {
        '& .MuiAutocomplete-paper': {
            backgroundColor: palette.ODBlue.odBlue8,
            color: palette.white,
            '& li:hover': { backgroundColor: palette.ODBlue.odBlue8 }
        }
    },
    popper: {
        '& .MuiAutocomplete-paper': {
            display: 'inline-block',
            fontSize: 13,
            '& li': { padding: '6px 10px 5px 11px' },
            marginTop: '2px',
            width: '100%',
            borderRadius: '2px'
        },
        '& li': {}
    },
    menu: {
        backgroundColor: palette.neutral.neutral1,
        color: palette.black,
        marginTop: '1px',
        marginLeft: '-1px',
        borderRadius: '4px',
        fontSize: '14px',
        border: `1px solid ${palette.neutral.neutral2}`,
        padding: '1px',
        '& li': { height: '32px', borderRadius: '4px', paddingLeft: '8px' },
        '& li:hover': { backgroundColor: palette.neutral.neutral2 },
        '& .MuiList-root': { paddingTop: 0, paddingBottom: 0 }
    },
    lowerMenu: {
        marginTop: '8px'
    },

    // Dropdown border
    dropdownborder: {
        borderRadius: '2px',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    lightModeAutocompleteOpen: {
        border: '1px solid transparent',
        backgroundColor: palette.neutral.neutral2
    },
    lightModeAutocompleteClosed: {
        border: `1px solid ${palette.neutral.neutral2}`,
        backgroundColor: palette.neutral.search,
        '&:hover': { backgroundColor: palette.neutral.neutral2 }
    },
    darkModeAutocompleteOpen: {
        border: '1px solid transparent',
        backgroundColor: palette.ODBlue.odBlue8
    },
    darkModeAutocompleteClosed: {
        border: '1px solid #453D51',
        borderRadius: '2px',
        backgroundColor: 'none',
        '&:hover': { backgroundColor: palette.ODBlue.odBlue8 }
    },

    // Numeric range input style rules
    numberInput: {
        fontSize: 10,
        paddingLeft: 8,
        width: '64px',
        borderRadius: '2px',
        '& input': {
            height: '13px'
        },
        '& input::placeholder': {
            opacity: 1
        },
        /* Chrome, Safari, Edge, Opera */
        '& input::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        /* Firefox */
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        }
    },
    darkModeNumberInput: {
        border: `1px solid ${palette.ODBlue.odBlue8}`,
        '& input::placeholder': {
            color: palette.neutral.neutral3
        }
    },
    lightModeNumberInput: {
        border: `1px solid ${palette.neutral.neutral2}`,
        color: palette.black,
        '& input::placeholder': {
            color: palette.neutral.neutral6
        }
    },
    darkModeNumberInputFocused: {
        backgroundColor: palette.ODBlue.odBlue8
    },
    lightModeNumberInputFocused: {
        backgroundColor: palette.neutral.neutral2
    },
    darkModeArrowButton: {
        color: palette.white
    },
    lightModeArrowButton: {
        color: palette.black
    }
}));

export const EndAdornmentsContainer = styled(Box)(() => ({
    display: 'flex',
    position: 'absolute',
    right: '8px',
    alignItems: 'center',
    '& .MuiAutocomplete-endAdornment': {
        position: 'static'
    }
}));

export const ClearButton = styled(Box)(() => ({
    textTransform: 'capitalize',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: palette.semantic.focusedBlue,
    marginLeft: '4px'
}));

export const FooterLabel = styled(Box)(() => ({
    fontSize: '8px',
    marginTop: '2px',
    fontWeight: '400'
}));
