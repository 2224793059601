import { Input } from '@mui/material';
import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import { DataRow } from '../ODTableTypes';
import { Cell } from '@tanstack/react-table';
import { getSortedTableRowIndex } from '../ODTableUtils';
import clsx from 'clsx';
import { VALIDATION_RULE } from 'utils/common.util';
import NumericFormat from 'react-number-format';

const useStyles = makeStyles(() => ({
    inputField: {
        fontSize: '14px'
    },
    inputFocused: {
        color: palette.black,
        textDecoration: 'none'
    },
    emptyInput: {
        color: palette.neutral.neutral4
    }
}));

const CustomInput = (props: any) => {
    const classes = useStyles();

    return (
        <Input
            {...props}
            className={clsx(
                classes.inputField,
                (props.value == null ||
                    (typeof props.value === 'string' && props.value.length === 0)) &&
                    classes.emptyInput
            )}
            classes={{ focused: classes.inputFocused }}
            disableUnderline={true}
        />
    );
};

type ActiveInputCellType = {
    id: string;
    value: string | number | object | null;
    onChangeProp: (e: any) => void;
    onFocus: (e: any) => void;
    confirmInput: (val: any) => void;
    selectedCell: Cell<DataRow, any>;
    setMinRowSelected: (r: number) => void;
    setMaxRowSelected: (r: number) => void;
    format?: string;
    validationRule?: VALIDATION_RULE;
    maxValue: number | null;
    timeUnits?: string;
};
// Render Functions and supporting components
const ActiveInputCell = ({
    format,
    id,
    value,
    onChangeProp,
    onFocus,
    confirmInput,
    selectedCell,
    setMinRowSelected,
    setMaxRowSelected,
    timeUnits
}: ActiveInputCellType) => {
    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (e.target instanceof HTMLInputElement) {
                e.target.blur();
            }
        }
    };

    return (
        <NumericFormat
            customInput={CustomInput}
            prefix={format === 'dollars' ? '$' : ''}
            suffix={timeUnits}
            thousandSeparator={true}
            displayType="input"
            value={value as string | number | null}
            decimalScale={format === 'dollars' ? 2 : 0}
            fixedDecimalScale={true}
            onValueChange={(values, sourceInfo) => {
                if (sourceInfo.source === 'event') onChangeProp(values.floatValue ?? '');
            }}
            valueIsNumericString={true}
            onKeyDown={handleKeyPress}
            onBlur={() => {
                confirmInput(value);
            }}
            onClick={(e: Event) => {
                onFocus(e);
                if (selectedCell) {
                    setMinRowSelected(getSortedTableRowIndex(selectedCell));
                    setMaxRowSelected(getSortedTableRowIndex(selectedCell));
                }
            }}
        />
    );
};

export default ActiveInputCell;
