import { Box, Tooltip } from '@mui/material';
import { INDICATOR_TYPE, indicatorLinesWidth } from 'constants/network';
import palette from 'theme/palette';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

type IndicatorLineType =
    | INDICATOR_TYPE.EXTERNALDELTA_GREATER
    | INDICATOR_TYPE.FLEETDELTA_GREATER
    | INDICATOR_TYPE.COMPARE_TOTAL_DRIVER_GREATER
    | INDICATOR_TYPE.TOTAL_DRIVER_GREATER
    | INDICATOR_TYPE.INCLUDES_BID_LANES
    | INDICATOR_TYPE.BID_LANE
    | INDICATOR_TYPE.CHANGED;
interface IndicatorLineProps {
    type: IndicatorLineType;
}
export interface IndicatorLinesProps {
    lines: IndicatorLineProps[];
    isDriversTab: boolean;
}

const IndicatorLine = ({ type }: IndicatorLineProps) => {
    let description;
    let color;
    switch (type) {
        case INDICATOR_TYPE.FLEETDELTA_GREATER:
            description = 'fleet delta > external delta';
            color = palette.semantic.semanticGreen;
            break;
        case INDICATOR_TYPE.TOTAL_DRIVER_GREATER:
            description = '';
            color = palette.semantic.semanticGreen;
            break;
        case INDICATOR_TYPE.COMPARE_TOTAL_DRIVER_GREATER:
            description = '';
            color = palette.semantic.semanticRed;
            break;
        case INDICATOR_TYPE.EXTERNALDELTA_GREATER:
            description = 'fleet delta < external delta';
            color = palette.semantic.semanticRed;
            break;
        case INDICATOR_TYPE.INCLUDES_BID_LANES:
            description = 'Includes bid lanes';
            color = palette.barColors.accessibleMagenta;
            break;
        case INDICATOR_TYPE.BID_LANE:
            description = 'Bid lane';
            color = palette.barColors.accessibleMagenta;
            break;
        case INDICATOR_TYPE.CHANGED:
            description = 'Changed';
            color = palette.white;
            break;
        default:
            description = '';
            color = 'transparent';
            break;
    }

    return (
        <Tooltip title={description} disableHoverListener={description === ''}>
            <Box
                sx={{
                    backgroundColor: color,
                    width: '2px',
                    height: '100%',
                    marginRight: '1px'
                }}></Box>
        </Tooltip>
    );
};

const useStyles = makeStyles(() => ({
    driversTab: {
        position: 'absolute',
        left: 0
    }
}));

const IndicatorLines = ({ lines, isDriversTab }: IndicatorLinesProps) => {
    const classes = useStyles();
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                width: `${indicatorLinesWidth}px`,
                backgroundColor: 'inherit'
            }}
            className={clsx(isDriversTab && [classes.driversTab])}>
            {lines.map((line) => (
                <IndicatorLine key={line.type} type={line.type} />
            ))}
        </Box>
    );
};

export const determineIndicatorLines = (row: any, isDriversTab = false) => {
    let indicatorLines = [];

    if (row?.original?.isRemoved) {
        indicatorLines.push({ type: INDICATOR_TYPE.CHANGED });
    } else if (row?.original?.isChanged?.isChanged) {
        let numberType = row?.original?.isChanged?.numType;

        if (numberType === 0) {
            indicatorLines.push({ type: INDICATOR_TYPE.CHANGED });
        } else if (numberType === 'pos') {
            indicatorLines.push({ type: INDICATOR_TYPE.FLEETDELTA_GREATER });
        } else if (numberType === 'neg') {
            indicatorLines.push({ type: INDICATOR_TYPE.EXTERNALDELTA_GREATER });
        }
    }

    if (row.original?.load_bid_count > 0) indicatorLines.push({ type: INDICATOR_TYPE.BID_LANE });

    if (row?.original?.compare_load_count > 0) {
        indicatorLines.push({ type: INDICATOR_TYPE.FLEETDELTA_GREATER });
    } else if (row?.original?.compare_load_count < 0) {
        indicatorLines.push({ type: INDICATOR_TYPE.FLEETDELTA_GREATER });
    }

    return <IndicatorLines lines={indicatorLines} isDriversTab />;
};

export default IndicatorLines;
