import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

const valueIsEmpty = (value: string | number | null | object) => {
    return value === null || (typeof value === 'string' && value.length === 0);
};
type NonactiveInputCellType = {
    value: string | number | null | object;
    cellStyle: any;
};
const NonactiveInputCell = ({ value, cellStyle }: NonactiveInputCellType) => {
    const theme = useTheme();

    if (typeof value === 'number' || typeof value === 'string' || value === null) {
        return (
            <Box
                sx={{
                    ...cellStyle,
                    textDecoration: 'underline',
                    color: valueIsEmpty(value) && theme.palette.neutral.neutral6
                }}>
                {valueIsEmpty(value) ? '0' : value}
            </Box>
        );
    } else {
        return <Box>{value as any}</Box>;
    }
};

export default NonactiveInputCell;
