import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import palette from 'theme/palette';

const StyledInstructions = styled(Box)(() => ({
    color: palette.neutral.neutral6,
    fontSize: '12px',
    marginBottom: '8px'
}));

export { StyledInstructions };
