import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import ClearIcon from '@mui/icons-material/Clear';
import { RootState } from 'redux/reducers';
import { setSelectedReports } from 'redux/actions';
import theme from 'theme';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { useParamsProps } from 'types/hooks';
import { StyledCheckbox } from './NetworkTopNavbar.styles';
import useReportsHook from 'utils/hooks/useReportsHook';

interface selectReportProps {
    setAction: React.Dispatch<React.SetStateAction<string>>;
}

const SelectReportBtn = ({ setAction }: selectReportProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { compare_run, base_run }: useParamsProps = useParams();
    const { selectedBaseReport, selectedCompareReport } = useSelector(
        (state: RootState) => state.NetworkReducer
    );
    const { handleRemoveBaseReport } = useReportsHook();
    const { configuration } = useSelector((state: RootState) => state.ConfigurationReducer);

    const handleCheckboxClick = (e: any) => {
        const _selectedBaseReport = { ...selectedBaseReport };
        const _selectedCompareReport = { ...selectedCompareReport };

        if (e.target.value === 'base') {
            _selectedBaseReport.enabled = !_selectedBaseReport.enabled;
            if (!_selectedBaseReport.enabled) {
                navigate(window.location.pathname.replace(base_run + '/', ''));
            } else {
                navigate(
                    window.location.pathname.replace(
                        base_run + '/',
                        selectedBaseReport.id + '/' + selectedCompareReport.id + '/'
                    )
                );
            }
        }

        if (e.target.value === 'compare') {
            _selectedCompareReport.enabled = !_selectedCompareReport.enabled;
            if (!_selectedCompareReport.enabled) {
                navigate(window.location.pathname.replace(compare_run + '/', ''));
            } else {
                navigate(
                    window.location.pathname.replace(
                        base_run + '/',
                        base_run + '/' + selectedCompareReport.id + '/'
                    )
                );
            }
        }
        dispatch(
            setSelectedReports({
                selectedBaseReport: _selectedBaseReport,
                selectedCompareReport: _selectedCompareReport
            })
        );
        if (!(_selectedBaseReport.enabled || _selectedCompareReport.enabled))
            setAction('SelectReport');
    };

    const removeBaseReport = () => {
        dispatch(
            setSelectedReports({
                selectedBaseReport: { id: selectedCompareReport.id, enabled: true },
                selectedCompareReport: {}
            })
        );

        if (compare_run) {
            navigate(window.location.pathname.replace(compare_run + '/', ''));
            navigate(
                window.location.pathname.replace(base_run as string, selectedCompareReport.id)
            );
        }

        handleRemoveBaseReport();
    };

    return (
        <>
            <ODButton
                id={'network-base-report-name-button'}
                buttonType={!selectedBaseReport?.id ? BUTTON_TYPE.LIGHT : BUTTON_TYPE.DARK}
                tooltipText={
                    !isEmpty(configuration)
                        ? configuration?.name
                        : selectedBaseReport?.name || 'Select Report'
                }
                buttonTextWidth={'103px'}
                startIcon={
                    selectedBaseReport.id && selectedCompareReport.id ? (
                        <StyledCheckbox
                            flag={true}
                            disabled={!selectedCompareReport.enabled}
                            checked={selectedBaseReport.enabled}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCheckboxClick(e);
                            }}
                            value="base"
                            inputProps={{ 'aria-label': 'base' }}
                        />
                    ) : (
                        <FolderSharpIcon />
                    )
                }
                sx={{ margin: '8px' }}
                onClick={() => setAction('SelectReport')}
                buttonText={
                    !isEmpty(configuration)
                        ? configuration?.name
                        : selectedBaseReport?.name || 'Select Report'
                }
                endIcon={
                    selectedBaseReport.id && selectedCompareReport.id ? (
                        <ClearIcon
                            sx={{
                                color: selectedBaseReport.enabled
                                    ? theme.palette.common.white
                                    : theme.palette.common.black
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                removeBaseReport();
                            }}
                        />
                    ) : null
                }
            />
        </>
    );
};

export default SelectReportBtn;
