import KPICard from 'shared/KPICard';
import { TARGET } from 'types/bidAnalysis';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { SimpleDonutWrapper } from 'shared/ODDonutChart';
import { convertNumberToCommas } from 'utils/number.util';
import { useTheme } from '@mui/system';
import { useState } from 'react';

const BidAnalysisKPICard = ({
    value,
    bidGoalType,
    denominator,
    displayExceedsTotalError,
    displayTargetTooLowError,
    targetType,
    setBidTarget,
    updateSelectedBidGoalType
}: {
    value: number;
    bidGoalType: TARGET | null;
    denominator: number;
    displayExceedsTotalError: () => void;
    displayTargetTooLowError: () => void;
    targetType: TARGET;
    setBidTarget: (goal: number | null, targetType: TARGET) => void;
    updateSelectedBidGoalType: (isEmpty: boolean) => void;
}) => {
    const [inputValue, setInputValue] = useState<number | null>(null);
    const theme = useTheme();
    const { bidGoals, kpiPreviews, selectedLaneId } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    let format: 'number' | 'dollars' = 'number';
    let label;
    let target;
    let targetName;
    let kpiPreview;
    switch (targetType) {
        case TARGET.LANES:
            format = 'number';
            kpiPreview = kpiPreviews.lanes;
            label = 'Total Lanes Accepted';
            target = bidGoals.lanes;
            targetName = 'Lanes';
            break;
        case TARGET.VOLUME:
            format = 'number';
            kpiPreview = kpiPreviews.volume;
            label = 'Total Volume Accepted';
            target = bidGoals.volume;
            targetName = 'Volume';
            break;
        case TARGET.REVENUE:
            format = 'dollars';
            kpiPreview = kpiPreviews.revenue;
            label = 'Total Revenue Accepted';
            target = bidGoals.revenue;
            targetName = 'Revenue';
            break;
        default:
            console.warn('BidAnalysisKPICard does not recognize target type ' + targetType);
            break;
    }

    const anotherTargetIsActive = () => {
        return bidGoalType !== null && bidGoalType !== targetType;
    };

    const formatTooltipNumber = (number: number) =>
        `${targetType === TARGET.REVENUE ? '$' : ''}${convertNumberToCommas(number?.toFixed(2))}`;

    return (
        <KPICard
            value={value}
            valueTooltip={`${formatTooltipNumber(value)} Total ${targetName} Accepted`}
            denominator={denominator}
            denominatorTooltip={`${formatTooltipNumber(denominator)} Total ${targetName}`}
            format={format}
            label={label ?? 'Invalid label'}
            icon={
                <SimpleDonutWrapper
                    numerator={value}
                    denominator={denominator}
                    color={theme.palette.semantic.focusedBlue}
                    donutId={`total-${targetType}-donut`}
                />
            }
            inputValue={inputValue}
            setInputValue={setInputValue}
            disableTargetInput={anotherTargetIsActive() || selectedLaneId !== null}
            exceededTargetCallback={displayExceedsTotalError}
            targetTooLowCallback={displayTargetTooLowError}
            kpiPreview={kpiPreview}
            kpiPreviewTooltip={`${formatTooltipNumber(kpiPreview)} Total Acceptable ${targetName}`}
            target={target}
            targetTooltip={`${formatTooltipNumber(target)} Total Target ${targetName}`}
            targetName={targetName ?? 'invalid target name'}
            setTarget={(target: number | null) => {
                setBidTarget(target, targetType);
            }}
            setIsInputEmpty={updateSelectedBidGoalType}
        />
    );
};

export default BidAnalysisKPICard;
