import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { TARGET } from 'types/bidAnalysis';
import { DECISION } from 'constants/bidding';
import { clearBidGoals, updateBidAnalysis } from 'redux/actions';
import BidAnalysisKPICard from './BidAnalysisKPICard';
import { useBidAnalysisHook } from 'utils/hooks';
import BidGoalAlert, { BID_GOAL_ALERT_TYPE } from './BidGoalAlert';
import { styled } from '@mui/material/styles';
import * as FS from '@fullstory/browser';

const KPICardsContainer = styled(Box)({
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px'
});

const BidAnalysisKPICards = () => {
    const [bidGoalType, setBidGoalType] = useState<TARGET | null>(null);
    const { checkedRows, currBidAnalysisLanes, userUpdatedSelectedLanes } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const { bidGoalApplied } = useBidAnalysisHook();
    const [totalLanes, setTotalLanes] = useState<number>(0);
    const [totalLanesAccepted, setTotalLanesAccepted] = useState<number>(0);
    const [totalVolume, setTotalVolume] = useState<number>(0);
    const [totalVolumeAccepted, setTotalVolumeAccepted] = useState<number>(0);
    const [totalRevenue, setTotalRevenue] = useState<number>(0);
    const [totalRevenueAccepted, setTotalRevenueAccepted] = useState<number>(0);
    const [alertType, setAlertType] = useState<BID_GOAL_ALERT_TYPE | null>(null);
    const [bidGoalAlertOpen, setBidGoalAlertOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    const sortByDescendingLaneScore = (rowA: DataRow, rowB: DataRow) => {
        return (
            rowA &&
            rowA.lane_score !== null &&
            rowB &&
            rowB.lane_score !== null &&
            Number(rowB.lane_score) - Number(rowA.lane_score)
        );
    };
    const displayAlert = useCallback((alertType: any) => {
        setBidGoalAlertOpen(true);
        setAlertType(alertType);
    }, []);
    const displayAcceptableRevenueExceededAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.EXCEEDS_ACCEPTABLE_REVENUE),
        [displayAlert]
    );
    const displayAcceptableLanesExceededAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.EXCEEDS_ACCEPTABLE_LANES),
        [displayAlert]
    );
    const displayAcceptableVolumeExceededAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.EXCEEDS_ACCEPTABLE_VOLUME),
        [displayAlert]
    );
    const displayTargetSpecifiedAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.TARGET_SPECIFIED),
        [displayAlert]
    );
    const displayTargetValueIsLessThanAcceptedValueAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.TARGET_LESS_THAN_ACCEPTED),
        [displayAlert]
    );
    const displayLanesExceededAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.EXCEEDS_TOTAL_LANES),
        [displayAlert]
    );
    const displayVolumeExceededAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.EXCEEDS_TOTAL_VOLUME),
        [displayAlert]
    );
    const displayRevenueExceededAlert = useCallback(
        () => displayAlert(BID_GOAL_ALERT_TYPE.EXCEEDS_TOTAL_REVENUE),
        [displayAlert]
    );

    /* // TODO Consider making the checkedRows variable a set instead of an array. This will allow us to update kpis in linear time.
     *  O(all lanes) rather than O(all lanes * checkedRows)
     * There is no lag, so this isn't imperative.
     */
    useEffect(() => {
        const updateKpiPreviews = () => {
            let lanesKpiPreview = 0;
            let volumeKpiPreview = 0;
            let revenueKpiPreview = 0;
            currBidAnalysisLanes.forEach((lane: DataRow) => {
                if (lane.recommendation === DECISION.ACCEPT || checkedRows.includes(lane.id)) {
                    lanesKpiPreview++;
                    volumeKpiPreview += checkedRows.includes(lane.id)
                        ? Number(lane.total_volume_available)
                        : Number(lane.volume_to_accept);
                    revenueKpiPreview +=
                        Number(lane.total_volume_available) *
                        Number(lane.mileage) *
                        Number(lane.rate_per_mile);
                }
            });

            dispatch(
                updateBidAnalysis({
                    kpiPreviews: {
                        lanes: lanesKpiPreview,
                        volume: volumeKpiPreview,
                        revenue: revenueKpiPreview
                    }
                })
            );
        };
        if (bidGoalApplied) updateKpiPreviews();
    }, [bidGoalApplied, currBidAnalysisLanes, dispatch, checkedRows]);

    const impossibleGoalWarning = () => {
        console.warn(
            'It is impossible to reach this goal even by including all unacceptable lanes. This code is unreachable when we limit the user from entering a target that is too high'
        );
    };

    const lanesSortedByLaneScore: DataRow[] = useMemo(
        () => currBidAnalysisLanes.slice().sort(sortByDescendingLaneScore),
        [currBidAnalysisLanes]
    );

    const rejectedLanes: DataRow[] = useMemo(
        () =>
            currBidAnalysisLanes
                .filter((lane: DataRow) => lane.recommendation === DECISION.REJECT)
                .sort(sortByDescendingLaneScore),
        [currBidAnalysisLanes]
    );

    const setBidTarget = useCallback(
        (goal: number | null, targetType: TARGET) => {
            if (!goal) {
                dispatch(clearBidGoals());
                return;
            }
            const goalExceedsAccepted = (goal: number, targetType: TARGET) => {
                if (targetType === TARGET.LANES) return goal > totalLanesAccepted;
                if (targetType === TARGET.VOLUME) return goal > totalVolumeAccepted;
                if (targetType === TARGET.REVENUE) return goal > totalRevenueAccepted;
                return false;
            };
            if (goalExceedsAccepted(goal, targetType)) displayTargetSpecifiedAlert();
            let _rowsToDisplay = new Set<number>();
            let canMeetBidGoalWithQualifyingLanes = true;
            let _checkedRows: number[] = [];
            let acceptedLanesRunningTotal = totalLanesAccepted;
            let acceptedVolumeRunningTotal = totalVolumeAccepted;
            let acceptedRevenueRunningTotal = totalRevenueAccepted;
            let i = 0;

            const displayAcceptableValueExceededAlert = () => {
                canMeetBidGoalWithQualifyingLanes = false;
                if (targetType === TARGET.REVENUE) {
                    displayAcceptableRevenueExceededAlert();
                } else if (targetType === TARGET.LANES) {
                    displayAcceptableLanesExceededAlert();
                } else if (targetType === TARGET.VOLUME) {
                    displayAcceptableVolumeExceededAlert();
                }
            };

            const updateRunningTotals = (lane: DataRow) => {
                if (
                    [
                        lane.volume_to_accept,
                        lane.total_volume_available,
                        lane.rate_per_mile,
                        lane.mileage
                    ].includes(null)
                ) {
                    console.warn(
                        'Could not calculate metrics for lane with ID ' +
                            lane.id +
                            'because at least one of the following values is null: volume_to_accept, total_volume_available, rate_per_mile, mileage'
                    );
                    // TODO we can update this to throw an error instead. And add a snackbar or notification when this occurs. Something like Unable to evaluate bid goals because... or These bid goal results may not be accurate because some data is missing.
                }
                if (lane.recommendation === DECISION.REJECT) {
                    acceptedLanesRunningTotal++;
                    acceptedVolumeRunningTotal += lane.total_volume_available as number;
                    acceptedRevenueRunningTotal +=
                        (lane.total_volume_available as number) *
                        (lane.mileage as number) *
                        (lane.rate_per_mile as number);
                    _rowsToDisplay.add(lane.id as number);
                    if (Number(lane.lane_score) >= 1) _checkedRows.push(lane.id as number);
                    else displayAcceptableValueExceededAlert();
                } else {
                    if (lane.volume_to_accept! < lane.total_volume_available!) {
                        acceptedVolumeRunningTotal +=
                            (lane.total_volume_available! as number) -
                            (lane.volume_to_accept! as number);
                        _rowsToDisplay.add(lane.id as number);
                        acceptedRevenueRunningTotal +=
                            ((lane.total_volume_available as number) -
                                (lane.volume_to_accept as number)) *
                            (lane.mileage as number) *
                            (lane.rate_per_mile as number);
                        if (Number(lane.lane_score) >= 1) _checkedRows.push(lane.id as number);
                        else displayAcceptableValueExceededAlert();
                    }
                }
            };

            const cannotMeetGoalAtAll = (allItems: DataRow[], i: number) => allItems.length === i;

            const laneIsAcceptedWithMaxVolume = (lane: DataRow) =>
                lane.recommendation === DECISION.ACCEPT &&
                lane.total_volume_available === lane.volume_accepted;

            if (targetType === TARGET.LANES) {
                while (acceptedLanesRunningTotal < goal) {
                    if (cannotMeetGoalAtAll(rejectedLanes, i)) {
                        impossibleGoalWarning();
                        break;
                    } else {
                        const lane = rejectedLanes[i];
                        updateRunningTotals(lane);
                        i++;
                    }
                }
            } else if (targetType === TARGET.VOLUME) {
                while (acceptedVolumeRunningTotal < goal) {
                    if (cannotMeetGoalAtAll(lanesSortedByLaneScore, i)) {
                        impossibleGoalWarning();
                        break;
                    } else {
                        const lane = lanesSortedByLaneScore[i];
                        i++;
                        if (laneIsAcceptedWithMaxVolume(lane)) {
                            continue;
                        }
                        updateRunningTotals(lane);
                    }
                }
            } else if (targetType === TARGET.REVENUE) {
                while (acceptedRevenueRunningTotal < goal) {
                    if (cannotMeetGoalAtAll(lanesSortedByLaneScore, i)) {
                        impossibleGoalWarning();
                        break;
                    } else {
                        const lane = lanesSortedByLaneScore[i];
                        i++;
                        if (laneIsAcceptedWithMaxVolume(lane)) {
                            continue;
                        }
                        updateRunningTotals(lane);
                    }
                }
            }

            const updatedBidGoalState = {
                bidGoals: {
                    lanes: acceptedLanesRunningTotal,
                    volume: acceptedVolumeRunningTotal,
                    revenue: acceptedRevenueRunningTotal
                },
                checkedRows: _checkedRows,
                userUpdatedSelectedLanes: false,
                canMeetBidGoalWithQualifyingLanes
            };
            if (_rowsToDisplay.size === currBidAnalysisLanes.length) {
                dispatch(
                    updateBidAnalysis({
                        ...updatedBidGoalState,
                        displayAllLanes: true,
                        lanesToMeetTarget: new Set()
                    })
                );
            } else {
                dispatch(
                    updateBidAnalysis({
                        ...updatedBidGoalState,
                        displayAllLanes: false,
                        lanesToMeetTarget: _rowsToDisplay
                    })
                );
            }
        },
        [
            currBidAnalysisLanes,
            displayAcceptableLanesExceededAlert,
            displayAcceptableVolumeExceededAlert,
            displayAcceptableRevenueExceededAlert,
            displayTargetSpecifiedAlert,
            lanesSortedByLaneScore,
            rejectedLanes,
            totalLanesAccepted,
            totalRevenueAccepted,
            totalVolumeAccepted,
            dispatch
        ]
    );

    useEffect(() => {
        dispatch(clearBidGoals());
    }, [dispatch]);

    useEffect(() => {
        const acceptedLanes = currBidAnalysisLanes.filter(
            (lane: DataRow) => lane.recommendation === 'accept'
        );
        const sumProperty = (lanes: DataRow[], property: string) =>
            lanes.reduce((sum, lane) => sum + Number(lane[property]), 0);

        const sumRevenue = (lanes: DataRow[], type: string) =>
            lanes.reduce(
                (sum: number, lane: DataRow) =>
                    sum +
                    Number(
                        type === 'numerator' ? lane.volume_to_accept : lane.total_volume_available
                    ) *
                        Number(lane.rate_per_mile) *
                        Number(lane.mileage),
                0
            );

        setTotalLanes(currBidAnalysisLanes.length);
        setTotalLanesAccepted(acceptedLanes.length);
        setTotalVolume(sumProperty(currBidAnalysisLanes, 'total_volume_available'));
        setTotalVolumeAccepted(sumProperty(acceptedLanes, 'volume_to_accept'));
        setTotalRevenueAccepted(sumRevenue(acceptedLanes, 'numerator'));
        setTotalRevenue(sumRevenue(currBidAnalysisLanes, 'denominator'));
    }, [currBidAnalysisLanes]);

    useEffect(() => {
        if (userUpdatedSelectedLanes && bidGoalApplied) displayTargetSpecifiedAlert();
    }, [bidGoalApplied, displayTargetSpecifiedAlert, userUpdatedSelectedLanes]);

    const updateSelectedBidGoalType = useCallback((isEmpty: boolean, targetType: TARGET) => {
        FS.event(`Bid Analysis_Enter Bid Goals_${targetType}`, { type: targetType });
        setBidGoalType(isEmpty ? null : targetType);
    }, []);

    return (
        <KPICardsContainer>
            <BidAnalysisKPICard
                key="bid-analysis-lanes-kpi-card"
                value={totalLanesAccepted}
                bidGoalType={bidGoalType}
                denominator={totalLanes}
                displayExceedsTotalError={displayLanesExceededAlert}
                displayTargetTooLowError={displayTargetValueIsLessThanAcceptedValueAlert}
                targetType={TARGET.LANES}
                setBidTarget={setBidTarget}
                updateSelectedBidGoalType={(isEmpty) =>
                    updateSelectedBidGoalType(isEmpty, TARGET.LANES)
                }
            />
            <BidAnalysisKPICard
                key="bid-analysis-volume-kpi-card"
                value={totalVolumeAccepted}
                bidGoalType={bidGoalType}
                denominator={totalVolume}
                displayExceedsTotalError={displayVolumeExceededAlert}
                displayTargetTooLowError={displayTargetValueIsLessThanAcceptedValueAlert}
                targetType={TARGET.VOLUME}
                setBidTarget={setBidTarget}
                updateSelectedBidGoalType={(isEmpty) =>
                    updateSelectedBidGoalType(isEmpty, TARGET.VOLUME)
                }
            />
            <BidAnalysisKPICard
                key="bid-analysis-revenue-kpi-card"
                value={totalRevenueAccepted}
                bidGoalType={bidGoalType}
                denominator={totalRevenue}
                displayExceedsTotalError={displayRevenueExceededAlert}
                displayTargetTooLowError={displayTargetValueIsLessThanAcceptedValueAlert}
                targetType={TARGET.REVENUE}
                setBidTarget={setBidTarget}
                updateSelectedBidGoalType={(isEmpty) =>
                    updateSelectedBidGoalType(isEmpty, TARGET.REVENUE)
                }
            />
            <BidGoalAlert
                alertType={alertType}
                open={bidGoalAlertOpen}
                onCloseHandler={() => setBidGoalAlertOpen(false)}
            />
        </KPICardsContainer>
    );
};

export default BidAnalysisKPICards;
