import React from 'react';
import clsx from 'clsx';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, CssBaseline, Button, Grid, Typography, Paper } from '@mui/material/';
import withStyles from '@mui/styles/withStyles';
import { ArrowForward } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';
import EnvConfig from 'config/config';
import { SignIn, SignOut, SignInClear, onTokenExpiry } from 'redux/actions';

const styles = (theme) => ({
    paper: {
        alignItems: 'center',
        padding: `${theme.spacing(7)} ${theme.spacing(7)} ${theme.spacing(4)}`,
        boxShadow: 'none'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    btnSignIn: {
        marginTop: theme.spacing(5),
        backgroundColor: '#312e3a',
        color: '#fff',
        justifyContent: 'inherit',
        '&:hover': {
            backgroundColor: '#47434f'
        }
    },
    signInSection: {
        height: '100vh',
        backgroundColor: 'white'
    },
    bannerImage: {
        backgroundImage: "url('assets/images/signin-background.png')",
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    branding: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    footer: {
        bottom: theme.spacing(5),
        padding: `${theme.spacing(5)} ${theme.spacing(7)} ${theme.spacing(4)}`
    },
    appBar: {
        backgroundColor: theme.palette.common.white,
        transform: 'none',
        borderBottom: `1px solid ${theme.palette.common.black}`
    },
    error: {
        color: '#F82A2A',
        fontWeight: 600
    }
});

class LogIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            userNameError: '',
            passwordError: ''
        };
    }

    componentDidMount() {
        this.props.SignOut();
    }

    componentDidUpdate(prevProps) {
        const { type } = this.props;
        const path = localStorage.getItem('path') ? localStorage.getItem('path') : '/';
        if (prevProps.type !== type && type === 'SIGNIN_SUCCESS') {
            this.props.history.push(path);
        }
    }

    render() {
        const {
            classes,
            auth0: { isAuthenticated, isLoading, loginWithRedirect }
        } = this.props;
        if (isLoading) return null;
        if (isAuthenticated) {
            const path = localStorage.getItem('path') ? localStorage.getItem('path') : '/';

            return <Navigate to={path} />;
        }

        const appHeader = `${EnvConfig.getConfig().appHeader} Sign In`;
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{appHeader}</title>
                </Helmet>
                <CssBaseline />
                <Grid container>
                    <Grid item md={4} className={classes.signInSection}>
                        <AppBar position="static" className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h6" className={classes.branding}>
                                    <img alt="logo" src="assets/images/ODLogo.svg" />
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Paper className={classes.paper}>
                            <Typography
                                component="h1"
                                style={{ fontSize: '2rem', fontWeight: 600 }}>
                                Sign In
                            </Typography>
                            <form className={classes.form}>
                                <Button
                                    id="sign-in"
                                    fullWidth
                                    variant="contained"
                                    style={{ width: '100%', justifyContent: 'space-between' }}
                                    onClick={loginWithRedirect}
                                    endIcon={<ArrowForward />}
                                    className={clsx(classes.btnSignIn, 'sign-in-btn')}>
                                    Sign In
                                </Button>
                            </form>
                            <Typography className={classes.error}>
                                {this.props?.errorMessages?.join('. ')}
                            </Typography>
                        </Paper>
                        <footer className={classes.footer}>
                            <b>Warning:</b> Use of this System is Restricted to Authorized Users.
                        </footer>
                    </Grid>
                    <Grid item md={8} className={classes.bannerImage} />
                </Grid>
            </React.Fragment>
        );
    }
}

LogIn.propTypes = {
    classes: PropTypes.object.isRequired,
    errorMessages: PropTypes.any
};

const mapStateToProps = (state) => {
    return state.SignInReducer;
};

const mapDispatchToProps = (dispatch) => ({
    SignIn: (username, password) => dispatch(SignIn(username, password)),
    SignOut: () => dispatch(SignOut()),
    SignInClear: () => dispatch(SignInClear()),
    onExpiry: () => dispatch(onTokenExpiry())
});

export default withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(withAuth0(LogIn))
);
