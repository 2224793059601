/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ClearIcon from '@mui/icons-material/Clear';
import TextFieldContainer from './TextFieldContainer';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

interface SearchContainerProps {
    searchText: string | null;
    updateSearchText: (x: string | null) => void;
    waittime?: number;
}
const SearchContainer = ({
    searchText,
    updateSearchText,
    waittime = 900
}: SearchContainerProps) => {
    const theme = useTheme();
    const [search, setSearch] = useState<string | null>(searchText);
    const [threshold, setThreshold] = useState<NodeJS.Timeout | null>(null);

    const update = (value: string | null) => {
        if (threshold) clearTimeout(threshold);
        const timerId = setTimeout(() => {
            updateSearchText(value);
        }, waittime);
        setThreshold(timerId);
    };

    const updateSearch = (value: string | null) => {
        setSearch(value);
        update(value);
    };

    return (
        <Box>
            <TextFieldContainer
                type="bucket_search"
                id="search_bucket_input"
                placeholder="Search"
                className="select-report-runs"
                multiple={true}
                value={search || ''}
                variant="outlined"
                style={{
                    color: theme.palette.common.white,
                    fontSize: 14,
                    height: 48
                }}
                handleChange={(event: any) => updateSearch(event.target.value)}
                endAdornment={
                    search ? (
                        <ClearIcon
                            sx={{
                                cursor: 'pointer',
                                color: theme.palette.common.black
                            }}
                            onClick={() => updateSearch(null)}
                        />
                    ) : (
                        <SearchSharpIcon sx={{ color: theme.palette.common.black }} />
                    )
                }
            />
        </Box>
    );
};
export default SearchContainer;
