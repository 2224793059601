import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Divider } from '@mui/material';
import { ODDrawer, ODButton } from 'shared';
import { RootState } from 'redux/reducers';
import { BUTTON_TYPE } from 'constants/colors';
import { SelectTextfield } from 'shared';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useSimulationHook } from 'utils/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isDraftReportUtil } from 'utils/network.util';
import { getConfigurationActions, onSetConfigurationSuccess } from 'redux/actions';
import { useParams } from 'react-router-dom';
import { useParamsProps } from '../../../types/hooks';
import AddDriverTypeAccordion from './AddDriverTypeAccordion';
import API from 'utils/axios';
import ODMultiSelectCore from 'shared/ODMultiSelect/ODMultiSelectCore';
import { addDomicileConfig } from './drivers.utils';
import { useTheme } from '@mui/system';

//TODO consolidate add new and edit slideout

const AddNewDriverSlideout = ({ onClose }: { onClose: () => void }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { base_run, compare_run }: useParamsProps = useParams();

    /*hooks/redux*/
    const { newSimulationFlow, newDomicileSlideout } = useFlags();
    const { createSimulationOnEdit } = useSimulationHook();
    const { reportFilters } = useSelector((state: RootState) => state.ReportsReducers);
    const { configuration } = useSelector(
        ({ ConfigurationReducer }: RootState) => ConfigurationReducer
    );
    const { slideout } = useSelector((state: RootState) => state.NetworkReducer);

    /**state */
    const [driverTypes, setDriverTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [displayedCities, setDisplayedCities] = useState([]);
    const [selectedLoc, setSelectedLoc] = useState({ name: '' }) as any;
    const [openDrawer, setOpenDrawer] = useState({
        domicile: true,
        addDriverTypes: true
    });

    /*ref*/
    const timeout = useRef(null) as any;
    const currReportId = compare_run || base_run;
    const cityStateOptions = useMemo(() => displayedCities, [displayedCities]);

    const resetDriverTypes = () => {
        const driverTypes = (reportFilters?.driver_types || []).map(({ id }: { id: string }) => ({
            id,
            value: id,
            name: id,
            total: 0
        }));

        setDriverTypes(driverTypes);
    };

    const handleClose = () => {
        onClose();
        setSelectedLoc({ name: '' });
        setSelectedTypes([]);
        resetDriverTypes();
    };

    const updateLocationOptions = async () => {
        if (selectedLoc?.name.length > 0) {
            let payload = {};

            if (Number(selectedLoc?.name)) {
                const zipcode = selectedLoc?.name;

                payload = {
                    city: '',
                    state: '',
                    zip_code: zipcode
                };
            } else {
                const cityState = selectedLoc?.name?.split(',');
                const city = cityState[0] || '';
                const state = cityState[1] || '';

                payload = {
                    city: city,
                    state: state,
                    zip_code: ''
                };
            }

            try {
                const data = (await API.post(`/location-options/`, payload)) as any;
                const cities = [] as any;
                const states = Object.keys(data?.data?.options);

                states.forEach((state) => {
                    Object.keys(data?.data?.options[state]).forEach((city) =>
                        cities.push({
                            name: `${city}, ${state}`,
                            id: Number(data?.data?.options[state][city][0].slice(0, 3)),
                            value: Number(data?.data?.options[state][city][0].slice(0, 3)),
                            total: Number(data?.data?.options[state][city][0].slice(0, 3))
                        })
                    );
                });

                setDisplayedCities(cities);
            } catch (err) {
                if (err) {
                    console.warn(err);
                }
            }
        }
    };

    const resetTextInput = () => {
        const _driverTypes = driverTypes.map((driverType: any) => {
            driverType.total = 0;
            return driverType;
        }) as any;

        setDriverTypes(_driverTypes);
    };

    const handleOnChangeDriverType = (e: any) => {
        let selected: React.SetStateAction<never[]> = [];

        if (e.includes('all')) {
            selected = e.length - 1 === driverTypes?.length ? [] : driverTypes;
        } else {
            selected = driverTypes.filter((type: any) => {
                return e.includes(type?.id);
            });
        }

        setSelectedTypes(selected);
    };

    const handleResetOption = () => {
        setSelectedTypes([]);
        resetTextInput();
    };

    const onChangeSelectDomicile = (_event: any, selectedList: React.SetStateAction<never[]>) => {
        setSelectedLoc(selectedList ? selectedList : { name: _event.target.value });
    };

    const updateTotal = (type: { id: any }, total: any): any => {
        const _driverTypes = driverTypes.map((driverType: any) => {
            if (driverType.id === type.id) driverType.total = total;
            return driverType;
        }) as any;

        setDriverTypes(_driverTypes);
    };

    const allowAddDrivers = () => {
        if (!isDraftReportUtil(configuration)) {
            dispatch(onSetConfigurationSuccess({ showTopAlert: true }));
            return false;
        } else return true;
    };

    const getConfigActions = (simulationId: string) => {
        dispatch(getConfigurationActions(currReportId, simulationId));
    };

    const handleSubmit = async () => {
        const simulationId = await createSimulationOnEdit();
        const domicile = selectedLoc as any;

        if (!simulationId) return;

        await Promise.all(
            selectedTypes.map((driverType: { total: number; name: string }) => {
                if (driverType.total === 0) return true;

                return addDomicileConfig(
                    {
                        location: domicile?.name,
                        isNew: true,
                        domicile_id: domicile?.id,
                        driver_type: driverType.name,
                        quantity: driverType.total
                    },
                    simulationId,
                    newSimulationFlow,
                    allowAddDrivers(),
                    dispatch,
                    currReportId
                );
            })
        );

        getConfigActions(simulationId);
        handleClose();
    };

    useEffect(() => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => updateLocationOptions(), 1000);
        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLoc?.name]);

    useEffect(() => {
        resetDriverTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportFilters?.driver_types]);

    return (
        <ODDrawer
            disableOutsideClickClose={true}
            open={newDomicileSlideout && slideout?.addNewDriver}
            title={'Add Drivers'}
            anchor="right"
            onClose={handleClose}
            enableCustomizedButton={true}
            children={
                <>
                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`min-load-count-accordion`}
                        expanded={openDrawer?.domicile}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e, _expanded) => {
                            setOpenDrawer({
                                ...openDrawer,
                                domicile: !openDrawer?.domicile
                            });
                        }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                marginTop: '2px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.common.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography
                                style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}>
                                Domicile
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={openDrawer?.domicile}
                        timeout="auto"
                        unmountOnExit
                        key={`select-min-load-count-collapse`}>
                        <Box sx={{ marginTop: '16px' }}>
                            <SelectTextfield
                                customIcon={(<SearchIcon />) as any}
                                dataTestId="select-domicile-location-textfield"
                                label="Select Domicile Location"
                                options={cityStateOptions}
                                onChange={onChangeSelectDomicile}
                                value={selectedLoc}
                            />
                        </Box>
                    </Collapse>
                    <Divider sx={{ color: theme.palette.neutral.neutral2, margin: '16px 0' }} />
                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`min-load-count-accordion`}
                        expanded={openDrawer?.addDriverTypes}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            setOpenDrawer({
                                ...openDrawer,
                                addDriverTypes: !openDrawer?.addDriverTypes
                            });
                        }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
                                borderRadius: '4px',
                                marginTop: '2px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.common.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Box sx={{ display: 'flex', color: theme.palette.common.black }}>
                                <Box
                                    sx={{
                                        marginRight: '16px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <PersonAddAlt1Icon />
                                </Box>

                                <Typography
                                    style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}>
                                    Add Driver Types
                                </Typography>
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={openDrawer?.addDriverTypes}
                        timeout="auto"
                        unmountOnExit
                        key={`select-min-load-count-collapse`}>
                        <Box sx={{ marginTop: '16px' }}>
                            <ODMultiSelectCore
                                fieldName="drivers"
                                label={'Select Driver Type'}
                                options={driverTypes.map((type: any) => type?.id)}
                                handleChange={(e) => handleOnChangeDriverType(e)}
                                clearSelectedOptions={() => handleResetOption()}
                                selectedOptions={selectedTypes?.map((type: any) => type?.id)}
                                getObjectValueFromId={(option) => option}
                            />
                        </Box>
                        <Box
                            sx={{
                                marginTop: '16px'
                            }}>
                            {selectedTypes?.map((type, i) => {
                                return (
                                    <AddDriverTypeAccordion
                                        currTypeName={type['name']}
                                        key={i}
                                        type={type}
                                        updateTotal={updateTotal}
                                    />
                                );
                            })}
                        </Box>
                    </Collapse>

                    <Box
                        display="flex"
                        justifyContent={'space-between'}
                        alignItems="center"
                        sx={{
                            marginTop: '16px',
                            flexDirection: 'column'
                        }}>
                        <ODButton
                            disabled={
                                !(selectedTypes?.length > 0) ||
                                Boolean(Object.keys(selectedLoc).length !== 4)
                            }
                            buttonType={BUTTON_TYPE.BLUE}
                            type="submit"
                            width="392px"
                            buttonText={'Save'}
                            onClick={() => handleSubmit()}
                        />
                        <ODButton
                            buttonText="Cancel"
                            width="392px"
                            sx={{ marginTop: '8px' }}
                            onClick={handleClose}
                        />
                    </Box>
                </>
            }
        />
    );
};

export default memo(AddNewDriverSlideout);
