import { ODInputWithAdornments } from 'shared';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Box, Collapse, Typography } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { handleIncrement, handleDecrement } from './drivers.utils';
import { useTheme } from '@mui/system';

const AddTotalDriverType = ({
    type,
    total = type?.total,
    updateTotal,
    currTypeName = ''
}: {
    type: any;
    total?: any;
    updateTotal: any;
    currTypeName: any;
}) => {
    const theme = useTheme();
    const [openDriverTypeDrawer, setOpenDriverTypeDrawer] = useState({} as any);

    const onUpdate = (count: any, type?: any) => {
        let total = 0;
        if (count || count.length > 0) {
            total = parseInt(count);
        }

        updateTotal(type, total);
    };

    const setAccordionOpen = () => {
        let updated = { ...openDriverTypeDrawer };
        updated[type.id] = !openDriverTypeDrawer[type.id];

        setOpenDriverTypeDrawer(updated);
    };

    useEffect(() => {
        setAccordionOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Accordion
                sx={{
                    '&:before': {
                        backgroundColor: 'transparent'
                    }
                }}
                key={`min-load-count-accordion`}
                expanded={openDriverTypeDrawer[type.id]}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e) => {
                    setAccordionOpen();
                }}>
                <AccordionSummary
                    sx={{
                        backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
                        borderRadius: '4px',
                        marginTop: '1px'
                    }}
                    expandIcon={
                        <KeyboardArrowDownSharp sx={{ color: theme.palette.common.black }} />
                    }
                    aria-controls="accordion-content"
                    id={`accordion`}>
                    <Box sx={{ display: 'flex', color: theme.palette.common.black }}>
                        <Box
                            sx={{
                                marginRight: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <PersonIcon />
                        </Box>

                        <Box display={'flex'} alignItems="center">
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 600
                                }}>
                                Current {currTypeName} Count:{' '}
                            </Typography>
                            <Box sx={{ fontWeight: 400, marginLeft: '4px' }}>
                                {0} ({`${total >= 0 ? '+' : ''}`} {total})
                            </Box>
                        </Box>
                    </Box>
                </AccordionSummary>
            </Accordion>
            <Collapse
                in={openDriverTypeDrawer[type?.id]}
                timeout="auto"
                unmountOnExit
                key={`select-min-load-count-collapse`}>
                <Box sx={{ margin: '16px 0' }}>
                    <Box>
                        <ODInputWithAdornments
                            inputLabel={`Enter ${type?.name} Count`}
                            handleRemoveOnClick={(e) => {
                                handleDecrement(type, onUpdate);
                            }}
                            handleAddOnClick={(e) => {
                                handleIncrement(type, onUpdate);
                            }}
                            value={total}
                            onChange={({ target: { value } }: any) => onUpdate(value)}
                            disabled={false}
                        />
                    </Box>
                </Box>
            </Collapse>
        </>
    );
};

export default AddTotalDriverType;
