import DropdownOption from 'shared/DropdownOption';

interface DropdownColumnOptionProps {
    selected: boolean;
    handleCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
    locked?: boolean;
    columnDef: any;
}
const DropdownColumnOption = ({
    handleCheckbox,
    selected,
    columnDef
}: DropdownColumnOptionProps) => {
    const option = {
        id: columnDef.id ?? 'error missing id',
        name: columnDef.meta?.name ?? 'error missing name',
        required: columnDef.meta?.required,
        visibleByDefault: columnDef.meta?.visibleByDefault
    };

    return <DropdownOption handleCheckbox={handleCheckbox} selected={selected} option={option} />;
};

export default DropdownColumnOption;
