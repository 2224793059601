import palette from 'theme/palette';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Accordion, AccordionSummary, Tooltip, Typography } from '@mui/material';
import BidFileListTable from './BidFileListTable';
const useStyles = makeStyles(() => ({
    accordionList: {
        backgroundColor: palette.neutral.neutral1,
        color: `${palette.black} !important`
    }
}));
interface BidGroupAccordionProps {
    items: any[];
    group: any;
    expanded: number | string;
    onChecked: any;
    onExpand: any;
    canSelectAll: boolean;
    toggleAllItemsInGroup: (e: React.ChangeEvent<HTMLInputElement>, groupId: any) => void; // must include if canSelectAll is true
}
const BidGroupAccordion = ({
    items,
    group,
    expanded,
    onChecked,
    onExpand,
    canSelectAll = true,
    toggleAllItemsInGroup
}: BidGroupAccordionProps) => {
    const classes = useStyles();

    return (
        <Box
            style={{ marginBottom: 2 }}
            key={`accordion-${group?.id}`}
            id={`bid-group-accordion-${group?.id}`}>
            <Accordion
                key={`group-list-${group?.id}`}
                // disabled={group.count === 0} // TODO can we put the number of bid files into the bidding/groups endpoint?
                disabled={false}
                expanded={expanded === group?.id}
                className={classes.accordionList}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e, expanded) => onExpand(expanded ? group?.id : null)}>
                <AccordionSummary
                    expandIcon={<KeyboardArrowDownSharpIcon sx={{ color: palette.black }} />}
                    aria-controls="accordion-content">
                    <ListAltSharpIcon sx={{ color: palette.black }} />
                    <Tooltip title={group.name}>
                        <Typography
                            style={{
                                paddingLeft: 16,
                                fontWeight: 600,
                                color: palette.black,
                                textOverflow: 'ellipsis',
                                width: '20rem'
                            }}
                            noWrap={true}>
                            {group.name}
                        </Typography>
                    </Tooltip>
                </AccordionSummary>
            </Accordion>
            {expanded === group?.id && items.length > 0 && (
                <Box key={`bid-file-group-details-${group?.id}`}>
                    <BidFileListTable
                        rows={items}
                        groupId={group?.id}
                        onExpand={onExpand}
                        onChecked={onChecked}
                        canSelectAll={canSelectAll}
                        toggleAll={(e: React.ChangeEvent<HTMLInputElement>) =>
                            toggleAllItemsInGroup(e, group?.id)
                        }
                    />
                </Box>
            )}
        </Box>
    );
};
export default BidGroupAccordion;
