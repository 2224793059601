import { useSelector, useDispatch } from 'react-redux';
import { ODButton as SharedButton } from 'shared';
import { Box, Link } from '@mui/material';
import palette from 'theme/palette';
import { settingsUpdate, rerunOutdatedReportByReportId, updateNetworkView } from 'redux/actions';
import { BUTTON_TYPE } from 'constants/colors';
import { RootState } from 'types/redux';
import { useAlerts } from './AlertsContext';
import { useSimulationHook } from 'utils/hooks';
import { styled } from '@mui/material/styles';
import ODSnackbar, { ODSNACKBAR_TYPE } from 'shared/Snackbar';
import { useFlags } from 'launchdarkly-react-client-sdk';

const SecondaryButton = styled(SharedButton)(() => ({
    marginRight: '16px',
    backgroundColor: 'transparent',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: palette.neutral.neutral9,
        color: palette.white,
        border: 'none'
    }
}));

export const getAlertInfo = (
    alertType: any,
    reportName?: string,
    odpt4185BidConfigUpdate?: boolean
) => {
    switch (alertType) {
        case 'accept_override':
            return {
                message: 'Editing this simulation will override current completed report.',
                barType: ODSNACKBAR_TYPE.WARNING
            };
        case 'outdated_bid':
            return {
                message: `One or more bid files used in this report has been updated 
            since the last run, please re-run your report to view the most up to date KPIs.`,
                barType: ODSNACKBAR_TYPE.INFO
            };
        case 'zip_aggregation':
            return {
                message: `Currently displaying driver data in an aggregated ZIP code view, 
            to enable data manipulation and other views reset aggregation.`,
                barType: ODSNACKBAR_TYPE.WARNING
            };
        case 'new_simulation_created':
            return {
                message:
                    'KPIs are not reflective of latest changes. To update the KPIs, click Run Report, and view the completed report.',
                barType: ODSNACKBAR_TYPE.INFO
            };
        case 'run_report':
            return {
                message:
                    "Congrats, your report is running, but KPIs are not reflective of latest changes. We'll let you know when it's complete.",
                barType: ODSNACKBAR_TYPE.SUCCESS
            };
        case 'report_failed':
            return {
                message: `${reportName}: Report run was unsuccessful. Please try running the report again, or open a support case if this has happened repeatedly.`,
                barType: ODSNACKBAR_TYPE.ERROR
            };
        case 'draft_bid_analysis_failed':
            return {
                message:
                    'Failed to create a draft bid analysis. Please try editing the analysis again or open a support case if this has happened repeatedly.',
                barType: ODSNACKBAR_TYPE.ERROR
            };
        case 'new_draft_bid_analysis_created':
            return {
                message: `Analysis is not reflective of latest changes. Click ${
                    odpt4185BidConfigUpdate ? 'Finalize' : 'Refresh'
                } Bid Analysis to update the recommendations.`,
                barType: ODSNACKBAR_TYPE.WARNING
            };
    }
};
interface NetworkViewAlertProps {
    alertType: any;
    reportName?: any;
    id?: any;
}
const NetworkViewAlert = ({ alertType, reportName, id }: NetworkViewAlertProps) => {
    const { baseReport } = useSelector((state: RootState) => state.NetworkReducer);
    const { maxAggregationLevel } = useSelector((state: RootState) => state.settingsReducer);
    const dispatch = useDispatch();
    const alertsContext = useAlerts();
    const { odpt4185BidConfigUpdate } = useFlags();
    const alerInfo = getAlertInfo(alertType, reportName, odpt4185BidConfigUpdate)?.barType;
    const barType = alerInfo ?? null;
    const { handleNewSimulation, handleOverrideSimulation } = useSimulationHook();

    const resetZipAggregation = () => {
        dispatch(settingsUpdate({ aggregationLevel: Number(maxAggregationLevel) }));
        alertsContext?.removeAlert(alertType);
    };

    const handleReRunReport = () => {
        rerunOutdatedReportByReportId(baseReport?.id).then(() => {
            dispatch(updateNetworkView({ isRunSimulation: true }));
            alertsContext?.removeAlert(alertType);
        });
    };

    const messsage = getAlertInfo(alertType, reportName, odpt4185BidConfigUpdate)?.message;

    const renderButtons = () => {
        switch (alertType) {
            case 'accept_override':
                return (
                    <>
                        <SecondaryButton
                            buttonText="New Simulation"
                            variant="outlined"
                            onClick={() => handleNewSimulation(alertType)}
                        />
                        <SharedButton
                            buttonText="Accept Override"
                            buttonType={BUTTON_TYPE.DARK}
                            onClick={() => handleOverrideSimulation(alertType)}
                        />
                    </>
                );
            case 'outdated_bid':
                return (
                    <SharedButton
                        buttonText="RUN REPORT"
                        buttonType={BUTTON_TYPE.BLUE}
                        onClick={handleReRunReport}
                    />
                );
            case 'zip_aggregation':
                return (
                    <SharedButton
                        buttonText="Reset Aggregation"
                        buttonType={BUTTON_TYPE.DARK}
                        onClick={resetZipAggregation}
                    />
                );
            case 'report_failed':
                return (
                    <Link component="button" color="inherit" onClick={() => window.openWidget()}>
                        Get Support
                    </Link>
                );
        }
    };
    return (
        <ODSnackbar
            open={true}
            text={messsage ?? ''}
            type={barType as any}
            buttons={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginRight: '16px'
                    }}>
                    {renderButtons()}
                </Box>
            }
            onCloseHandler={() => alertsContext?.removeAlert(alertType, id)}
        />
    );
};

export default NetworkViewAlert;
