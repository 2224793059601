import { useCallback, useMemo } from 'react';
import { Box, TableCell, IconButton, Radio } from '@mui/material';
import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import Undo from '@mui/icons-material/Undo';
import { ODIconButton, Checkbox } from 'shared';
import ODTableCell from './ODTableCell';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import { checkboxColumnWidth, settingsColumnWidth } from './ODTableConstants';
import { StyledTableRow, useODTableStyles } from './ODTableStyledComponents';
import { ActionButton, DataRow, CellError } from './ODTableTypes';
import { BUTTON_TYPE } from 'constants/colors';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';

const RowActionButtonsContainer = styled(Box)(() => ({
    position: 'absolute',
    right: 'calc(100% - 41px)', // 48 px for button width, 1px for padding
    top: '50%',
    marginTop: '-16px',
    display: 'flex',
    justifyContent: 'space-between'
}));

interface ODTableRowProps {
    row: Row<DataRow>;
    rowActionIndex?: number;
    setRowActionIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}
const ODTableRow = ({ row, rowActionIndex, setRowActionIndex }: ODTableRowProps) => {
    const {
        backgroundColorForSelectedRows,
        checkedRows,
        customActionButtons,
        deleteOption,
        displayRighthandColumn,
        downloadOption,
        editOption,
        handleClickDeleteOption,
        handleClickDownloadOption,
        handleClickEditOption,
        interactiveTable,
        radioCheckbox,
        removeOption,
        resetFillDown,
        rowActionsClosed,
        rowClickHandler,
        rowsToDelete,
        setCheckedRows,
        setRowActionsClosed,
        setRowsToDelete,
        showRowActions,
        table,
        tableDataErrors
    } = useODTable() as ODTableContextInterface & ODTableProviderProps;

    const classes = useODTableStyles({ rowHighlightColor: backgroundColorForSelectedRows });
    const theme = useTheme();
    const commonIconSX = {
        fill: theme.palette.common.black
    };
    const isDeleted = useCallback(
        (row: Row<DataRow>) => rowsToDelete.includes(Number(row.getValue('id'))),
        [rowsToDelete]
    );

    const isSelected = useCallback(
        (row: Row<DataRow>) => checkedRows.includes(row.getValue('id')),
        [checkedRows]
    );
    const checkRowForError = useCallback(
        (row: Row<DataRow>) => tableDataErrors?.[row.getValue('id') as number],
        [tableDataErrors]
    );

    const deleted = useMemo(() => isDeleted(row), [isDeleted, row]);
    const selected = useMemo(() => isSelected(row), [isSelected, row]);
    const rowErrors = useMemo(() => checkRowForError(row), [checkRowForError, row]);
    const rowHasErrors = rowErrors !== undefined;

    const undeleteRow = (currRowId: number) => {
        setRowsToDelete([...rowsToDelete].filter((rowId) => rowId !== currRowId));
    };

    const updateCheckboxes = (e: React.ChangeEvent<HTMLInputElement>, currRowId: number) => {
        e.stopPropagation(); // Prevents the click event from propagating to the Snackbar
        if (radioCheckbox) setCheckedRows?.([currRowId]);
        else if (e.target.checked) {
            setCheckedRows?.([...checkedRows, currRowId]);
        } else {
            setCheckedRows?.(checkedRows.filter((r) => r !== currRowId));
        }
    };

    return (
        <StyledTableRow
            key={row.id}
            id={`row-${row.id}`}
            showingActions={rowActionIndex === row.index}
            clickable={Boolean(rowClickHandler)}
            onClick={() => rowClickHandler && rowClickHandler(row)}>
            {interactiveTable && (
                <TableCell
                    className={clsx(
                        classes.tablecell,
                        classes.checkboxCell,
                        classes.actioncell,
                        deleted && 'deleted',
                        selected && 'selected',
                        rowHasErrors && 'error',
                        classes.sticky
                    )}
                    sx={{
                        left: 0,
                        paddingRight: 0,
                        width: `${checkboxColumnWidth}px`,
                        flex: '0 0 auto',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    key={`checkbox${row.id}`}>
                    {deleted ? (
                        <IconButton
                            sx={{ width: '18px', height: '18px' }}
                            onClick={() => undeleteRow(row.getValue('id'))}>
                            <Undo fill={theme.palette.neutral.neutral6} />
                        </IconButton>
                    ) : radioCheckbox ? (
                        <Radio
                            checked={checkedRows.includes(row.getValue('id'))}
                            onChange={(e: any) => updateCheckboxes(e, row.getValue('id'))}
                            sx={{ margin: 0, padding: 0 }}
                        />
                    ) : (
                        <Checkbox
                            isChecked={checkedRows.includes(row.getValue('id'))}
                            onChecked={(e: any) => updateCheckboxes(e, row.getValue('id'))}
                            sx={{ margin: 0, padding: 0 }}
                        />
                    )}
                </TableCell>
            )}
            {row.getVisibleCells().map((cell, i) => (
                <ODTableCell
                    key={i}
                    {...{ selected, deleted, rowHasErrors }}
                    cellError={rowErrors?.find(
                        (error: CellError) => error.columnId === cell.column.id
                    )}
                    cells={row.getVisibleCells()}
                    columnIndex={i}
                    {...{
                        cell
                    }}
                />
            ))}
            {displayRighthandColumn && (
                <TableCell
                    className={clsx(
                        classes.tablecell,
                        classes.actioncell,
                        deleted && 'deleted',
                        selected && 'selected',
                        rowHasErrors && 'error',
                        classes.sticky,
                        classes.stickyRight
                    )}
                    sx={{
                        width: `${settingsColumnWidth}px`,
                        right: 0,
                        flex: '0 0 auto',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'inherit'
                    }}
                    key={`${row.id}-settings`}>
                    {interactiveTable &&
                    showRowActions &&
                    !rowActionsClosed &&
                    rowActionIndex === row.index ? (
                        <RowActionButtonsContainer>
                            {customActionButtons?.map((actionButton: ActionButton) => (
                                <ODIconButton
                                    enableTooltip={true}
                                    id={`${actionButton.description}-${row.id}`}
                                    title={actionButton.description}
                                    sx={{ marginRight: '8px' }}
                                    buttonType={BUTTON_TYPE.GREY}
                                    icon={actionButton.icon}
                                    onClick={() =>
                                        actionButton.callback(Number(row.original.id), table)
                                    }
                                />
                            ))}
                            {removeOption && (
                                <ODIconButton
                                    enableTooltip={true}
                                    id={`remove-${row.id}`}
                                    title={'remove'}
                                    sx={{ marginRight: '8px' }}
                                    buttonType={BUTTON_TYPE.GREY}
                                    icon={<RemoveSharpIcon sx={{ ...commonIconSX }} />}
                                    onClick={() => {
                                        setRowsToDelete([
                                            ...rowsToDelete,
                                            Number(row.getValue('id'))
                                        ]);
                                        setCheckedRows?.(
                                            checkedRows.filter(
                                                (checkedRowId) =>
                                                    checkedRowId !== Number(row.getValue('id'))
                                            )
                                        );
                                    }}
                                />
                            )}
                            {deleteOption && (
                                <ODIconButton
                                    enableTooltip={true}
                                    id={`delete-${row.id}`}
                                    title={'delete'}
                                    sx={{ marginRight: '8px' }}
                                    buttonType={BUTTON_TYPE.GREY}
                                    icon={<DeleteSharpIcon sx={{ ...commonIconSX }} />}
                                    onClick={() => handleClickDeleteOption?.(row?.original)}
                                />
                            )}
                            {editOption && (
                                <ODIconButton
                                    enableTooltip={true}
                                    id={`edit-${row.id}`}
                                    title={'edit'}
                                    sx={{ marginRight: '8px' }}
                                    buttonType={BUTTON_TYPE.GREY}
                                    icon={<EditIcon sx={{ ...commonIconSX }} />}
                                    onClick={() => handleClickEditOption?.(row?.original)}
                                />
                            )}
                            {downloadOption && (
                                <ODIconButton
                                    disabled={Boolean(row?.original?.disableDownload)}
                                    enableTooltip={Boolean(!row?.original?.disableDownload)}
                                    id={`download-${row.id}`}
                                    title={'download'}
                                    sx={{ marginRight: '8px' }}
                                    buttonType={BUTTON_TYPE.GREY}
                                    icon={
                                        <DownloadIcon
                                            sx={{
                                                fill: row?.original?.disableDownload
                                                    ? theme.palette.neutral.neutral6
                                                    : theme.palette.common.black
                                            }}
                                        />
                                    }
                                    onClick={() => handleClickDownloadOption?.(row?.original)}
                                />
                            )}

                            <ODIconButton
                                title={'close'}
                                id={`close-${row.id}`}
                                buttonType={BUTTON_TYPE.DARK}
                                icon={
                                    <CloseIcon
                                        sx={{
                                            fill: theme.palette.common.white
                                        }}
                                    />
                                }
                            />
                        </RowActionButtonsContainer>
                    ) : (
                        interactiveTable &&
                        showRowActions &&
                        !deleted && (
                            <ODIconButton
                                onClick={(e) => {
                                    setRowActionIndex(row.index);
                                    setRowActionsClosed(false);
                                    resetFillDown();
                                    e.stopPropagation();
                                }}
                                buttonType={BUTTON_TYPE.TRANSPARENT}
                                icon={
                                    <MoreVertSharpIcon
                                        sx={{
                                            color: theme.palette.common.black
                                        }}
                                    />
                                }
                            />
                        )
                    )}
                </TableCell>
            )}
        </StyledTableRow>
    );
};
export default ODTableRow;
