import { Box, Divider, IconButton, Stack, Typography, List, ListItem, Drawer } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import palette from 'theme/palette';
import { listItemClasses } from '@mui/material/ListItem';

export const LeftNavbarContent = styled(Box)(() => ({
    position: 'absolute',
    top: 0,
    zIndex: 15
}));

export const ExpandedMenu = styled(Box)(() => ({
    width: 280,
    overflow: 'hidden',
    height: '100%',
    backgroundColor: palette.neutral.search
}));

export const ExpandedMenuHeading = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px'
}));

export const ExpandedMenuTextHeaders = styled(Box)(() => ({
    display: 'flex',
    margin: '0 8px',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const ExpandedMenuTopDivider = styled(Divider)(() => ({
    margin: '0 0 8px 0',
    borderColor: palette.neutral.neutral1
}));

export const ExpandedMenuIconButton = styled(IconButton)(() => ({
    transition: '0.5s ease-in-out',
    left: '30px',
    borderRadius: '4px',
    backgroundColor: palette.neutral.neutral1,
    minWidth: '0px',
    height: '40px',
    width: '40px',
    margin: '0px',
    '&:hover': {
        backgroundColor: palette.neutral.neutral3
    },
    '&:disabled': {
        backgroundColor: palette.neutral.neutral4,
        '& > svg': {
            color: palette.neutral.neutral6
        }
    }
}));

export const ExpandedMenuBottomDivider = styled(Divider)(() => ({
    margin: '8px',
    borderColor: palette.neutral.neutral1
}));

export const ExpandedMenuItems = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: `calc(100vh - 130px)`
}));

//TODO check
export const CollapsedMenu = styled(Box)(() => ({
    justifyContent: 'center',
    height: '100vh',
    width: '56px',
    backgroundColor: palette.neutral.search
}));

export const CollapsedMenuLogo = styled(Box)(() => ({
    minWidth: '0px',
    height: '40px',
    width: '40px',
    margin: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const CollapsedMenuStack = styled(Stack)(() => ({
    width: '56px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    '>:not(style)+:not(style)': {
        marginTop: 0
    }
}));

export const CollapsedMenuDivider = styled(Divider)(() => ({
    margin: `0px 0px 8px 0px !important`,
    width: '100%',
    borderColor: palette.neutral.neutral1
}));

export const CollapsedMenuIconButton: any = styled(IconButton)(
    ({ selected }: { selected: boolean }) => ({
        borderRadius: '4px',
        minWidth: '0px',
        height: '40px',
        width: '40px',
        marginBottom: '2px',
        backgroundColor: selected ? palette.ODBlue.odBlue9 : 'initial',
        '& > svg': {
            color: selected ? palette.white : palette.black
        },
        '&:hover': {
            backgroundColor: selected ? palette.ODBlue.odBlue8 : palette.neutral.neutral2,
            '& > svg': {
                color: selected ? palette.white : palette.black
            }
        },
        '&:disabled': {
            backgroundColor: palette.neutral.neutral4,
            '& > svg': {
                color: palette.neutral.neutral6
            }
        }
    })
);

export const sharedListItemStylesProps = () => {
    return {
        cursor: 'pointer',
        borderRadius: '100px',
        position: 'absolute',
        width: '8px',
        height: '8px',
        color: palette.black,
        fontWeight: 600,
        zIndex: 20,
        backgroundColor: palette.semantic.focusedBlue
    };
};

export const LeftNavbarCollapsedMenuItemsContent = styled(Box)(() => ({
    height: `calc(100vh - 71px)`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
}));

export const ExpandedTypoSet = styled(Typography)(() => ({
    fontWeight: 600,
    color: palette.black,
    fontSize: '16px',
    maxWidth: '194px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}));

export const ExpandedMenuList = styled(List)(() => ({
    padding: '0 10px',
    [`& .${listItemClasses.root}`]: {
        padding: '8px 10px',
        color: palette.white,
        fontSize: '14px !important',
        '& > svg': {
            marginRight: '16px'
        }
    }
}));

export const ExpandedMenuListItem = styled(ListItem)(
    ({ isItemSelected }: { isItemSelected: string }) => ({
        cursor: 'pointer',
        height: '40px',
        marginBottom: '2px',
        borderRadius: '4px',
        backgroundColor: isItemSelected ? palette.ODBlue.odBlue9 : 'initial',
        '& > svg': {
            color: isItemSelected ? palette.white : palette.ODBlue.odBlue10
        },
        '&:hover': {
            backgroundColor: isItemSelected ? palette.ODBlue.odBlue8 : palette.neutral.neutral2,
            borderRadius: '4px !important',
            color: isItemSelected ? palette.white : palette.black,
            '& > svg': {
                color: isItemSelected ? palette.white : palette.black
            },
            '& > p': {
                color: isItemSelected ? palette.white : palette.black
            }
        }
    })
);

export const LeftNavbarIconButton = styled(IconButton)(
    ({ showExpanded }: { showExpanded: any }) => ({
        cursor: 'pointer',
        position: 'fixed',
        left: showExpanded ? '34px' : '0px',
        top: '8px',
        zIndex: 14,
        borderRadius: '4px',
        backgroundColor: `${palette.neutral.neutral1} !important`,
        minWidth: '0px',
        height: '40px',
        width: '40px',
        margin: '0px !important',
        transition: '0.5s ease-in-out',
        '&:hover': {
            backgroundColor: `${palette.neutral.neutral3} !important`
        }
    })
);

const drawerWidth = 280;

export const openedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    })
});

export const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
});

export const CustomizedDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
        })
    })
);
