import { Divider } from '@mui/material';
import { useTheme } from '@mui/system';

const NavbarDivider = ({ light }: { light: boolean }) => {
    const theme = useTheme();
    return (
        <Divider
            orientation="vertical"
            sx={{
                borderColor: light
                    ? `${theme.palette.neutral.neutral2}`
                    : `${theme.palette.ODBlue.odBlue8}`,
                margin: '8px',
                height: '40px'
            }}
        />
    );
};

export default NavbarDivider;
