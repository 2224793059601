import { Cell, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
    NumberRangeFilterV8,
    DropdownFilterV8,
    DateRangeFilterV8,
    NumberRangeFilterV8WithThresholds
} from 'shared/Table/tableFilters';
import { FILTER_TYPE } from 'shared/Table/tableFilters/FilterConstants';
import { CellEdit, DataRow } from './ODTableTypes';
import { Box } from '@mui/material';
import { produce } from 'immer';
import type { Draft } from 'immer';

export const FilterRenderer = ({ column }: any) => {
    const filterType = column.columnDef.meta?.filterType;
    const sortedUniqueValues = useMemo(() => {
        if (filterType === (FILTER_TYPE.NUMBER_RANGE || FILTER_TYPE.NUMBER_RANGE_WITH_THRESHOLDS))
            return [];
        else {
            return Array.from(column.getFacetedUniqueValues().keys())
                .filter((k) => ![undefined, null, ''].includes(k as any))
                .sort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column.getFacetedUniqueValues(), filterType]);

    const renderFilter = () => {
        if (filterType === FILTER_TYPE.NUMBER_RANGE) {
            return <NumberRangeFilterV8 column={column} />;
        } else if (filterType === FILTER_TYPE.NUMBER_RANGE_WITH_THRESHOLDS) {
            return <NumberRangeFilterV8WithThresholds column={column} />;
        } else if (filterType === FILTER_TYPE.DROPDOWN) {
            return (
                <DropdownFilterV8 column={column} sortedUniqueValues={sortedUniqueValues as any} />
            );
        } else if (filterType === FILTER_TYPE.DATE_RANGE) {
            return (
                <DateRangeFilterV8 column={column} sortedUniqueValues={sortedUniqueValues as any} />
            );
        } else {
            return null;
        }
    };
    return (
        <Box id="filter" sx={{ marginTop: '10px', width: '90%' }}>
            {renderFilter()}
        </Box>
    );
};

export const isHidden = (columnDef: ColumnDef<DataRow, any>, hiddenColumns: string[]) => {
    return columnDef.id && hiddenColumns?.includes(columnDef.id);
};

export const getUpdatedHiddenColumns = (
    hiddenColumns: string[],
    e: React.ChangeEvent<HTMLInputElement>,
    column: ColumnDef<DataRow, any>
) => {
    const updatedHiddenColumns = [...hiddenColumns];

    if (isHidden(column, hiddenColumns) && column.id && e.target.checked) {
        updatedHiddenColumns.splice(hiddenColumns.indexOf(column.id), 1);
    } else if (!isHidden(column, hiddenColumns) && column.id && !e.target.checked) {
        updatedHiddenColumns.push(column.id);
    }
    return updatedHiddenColumns;
};

// When the react table is sorted or filtered, the table reorders its rows. The cell's row index does not change.
// This utility fn allows us to find the sorted row index of the cell given the cell's initial row index
export const getSortedTableRowIndex = (cell: Cell<DataRow, any>) => {
    const table = cell.getContext().table;
    return table.getRowModel().rows.findIndex((r) => r.index === cell.row.index);
};

export const isNewValueDifferent = (
    dataRow: DataRow,
    columnId: string,
    newValue: string | number | object | null
) => {
    return dataRow[columnId] !== newValue;
};

export const getModifiedPartialCopy = (cellEdits: CellEdit[], originalDataSet: DataRow[]) => {
    const modifiedDataRows = produce(originalDataSet, (draft: Draft<DataRow[]>) => {
        cellEdits.forEach((cellEdit) => {
            const { rowId, columnId, newValue } = cellEdit;
            const rowToUpdate = draft.find((row: DataRow) => row.id === rowId);
            if (rowToUpdate) {
                rowToUpdate[columnId] = newValue;
            }
        });
    });
    return modifiedDataRows;
};
