import { BIDDING_NAMES } from './bidding';

/* eslint-disable no-useless-escape */
export const FLATFILE_KEY_URI = '../flatfile/flatfile-key/';
export const POSITIVE_NUMBER_WITH_UP_TO_TWO_DECIMALS =
    '^(?!0(\\.0{1,2})?$)[0-9]\\d*(\\.\\d{1,2})?$';
export const NONNEGATIVE_DOLLAR_VALUE = '^\\d+(\\.\\d{1,2})?$';
export const POSITIVE_NUMERIC_CHARACTER_ONLY = '^(?!0(\\.0{1,2})?$)[0-9]\\d*$';
export const NUMERIC_CHARACTER_BETWEEN_3_AND_5 = '^(?!^d{3,5}$).*';
export const ALPHA_CHARACTER_ONLY_MIN_3_MAX_50 = '^(?![A-Za-z]{3,50}$).*';
export const ALPHA_CHARACTER_ONLY_MIN_2_MAX_60 = '^(?![A-Za-z]{2,60}$).*';
export const NULL_VALUES = '^(?!(^s*$|^null$|^NULL$|^None$|^NONE$)).*';

interface FeatureFlags {
    salesRanking: boolean;
    flatRates: boolean;
    flexibleLocationFlatfileFlag: boolean;
    lineHaulRevPerMile: boolean;
    zipCodeCliffordValidation: boolean;
    odpt407934Zip: boolean;
    remapFuelSurchargeToOtherRevenuePerMile: boolean;
}

//
// these are legacy ZipCode fields when flexibleLocationFlatfileFlag===false
//

export const LocationOriginFlatfileField = {
    key: 'origin_zip',
    label: `${BIDDING_NAMES.ORIGIN}*`,
    description: 'origin zip code',
    alternates: ['Origin Zip Code', 'Origin Zip'],
    validators: [
        { validate: 'required', error: 'Origin is required.' },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};
export const LocationDestinationFlatfileField = {
    key: 'destination_zip',
    label: `${BIDDING_NAMES.DESTINATION}*`,
    description: 'destination zip code',
    alternates: ['Destination Zip Code', 'Destination Zip'],
    validators: [
        { validate: 'required', error: 'Destination is required.' },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};
export const LocationOrigin4and5ZipFlatfileField = {
    key: 'origin_zip',
    label: `${BIDDING_NAMES.ORIGIN_ZIP}*`,
    description: 'The 3 or 5 digit zip code of the load Origin. Example Format: 925 or 90025.',
    alternates: ['Origin Zip Code', 'Origin Zip'],
    validators: [
        { validate: 'required', error: 'Origin is required.' },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};
export const LocationDestination4and5ZiFlatfileField = {
    key: 'destination_zip',
    label: `${BIDDING_NAMES.DESTINATION_ZIP}*`,
    description: 'The 3 or 5 digit zip code of the load Destination. Example Format: 925 or 90025.',
    alternates: ['Destination Zip Code', 'Destination Zip'],
    validators: [
        { validate: 'required', error: 'Destination is required.' },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};

//
// these are new location fields when flexibleLocationFlatfileFlag===true
//

// TODO:  Discovery on flat-file feature called addVirtualColumn that could
//        be used to combine separate columns into a virtual column; e.g.
//        combine columns for City, State and/or Zip Code for locations

// Any of the following fields can suffice to define a location:
// - OriginZip (preferred)
// - OriginCityStateZip (complete input, preferred)
// - OriginCityState (guess the zip code for this)
// - OriginCity and OriginState (guess the zip code for this)

export const LocationOriginZipFlatfileField = {
    key: 'origin_zip',
    label: BIDDING_NAMES.ORIGIN_ZIP,
    description:
        'The 3 or 5 digit zip code of the load Origin. Example Format: 925 or 90025. This value is required if Origin City and State are not provided.',
    alternates: ['Origin Zip Code', 'Origin Zip'],
    // https://optimaldynamics.atlassian.net/browse/ODPT-4078 - hide some optional fields (revise validators too)
    validators: [
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'OriginZipCode is required if OriginCityState is missing.',
            fields: ['origin_city_state']
        },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};

export const LocationOriginCityFlatfileField = {
    // This column cannot be validated as a single column of
    // location data, it must be combined with OriginState.
    key: 'origin_city',
    label: BIDDING_NAMES.ORIGIN_CITY,
    description:
        'origin city name (requires OriginState; required if no other location fields provided)',
    alternates: [BIDDING_NAMES.ORIGIN_CITY],
    validators: [
        {
            validate: 'required_with',
            error: 'Origin City is required if OriginState field is provided.',
            fields: ['origin_state']
        },
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Origin City is required if all of OriginZipCode, OriginCityState and OriginCityStateZip fields are missing.',
            fields: ['origin_zip', 'origin_city_state_zip', 'origin_city_state']
        },
        {
            validate: 'regex_excludes',
            regex: ALPHA_CHARACTER_ONLY_MIN_3_MAX_50,
            error: 'Please enter an alpha character value between 3 and 50 characters.'
        },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};

export const LocationOriginStateFlatfileField = {
    // This column cannot be validated as a single column of
    // location data, it must be combined with OriginCity.
    key: 'origin_state',
    label: BIDDING_NAMES.ORIGIN_STATE,
    description:
        'origin state acronym (requires OriginCity; required if no other location fields provided)',
    alternates: [BIDDING_NAMES.ORIGIN_STATE],
    validators: [
        {
            validate: 'required_with',
            error: 'Origin State is required if OriginCity field is provided.',
            fields: ['origin_city']
        },
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Origin State is required if all of OriginZipCode, OriginCityState and OriginCityStateZip fields are missing.',
            fields: ['origin_zip', 'origin_city_state_zip', 'origin_city_state']
        },
        {
            validate: 'regex_excludes',
            regex: ALPHA_CHARACTER_ONLY_MIN_2_MAX_60,
            error: 'Please enter an alpha character value between 2 and 60 characters.'
        },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};

export const LocationOriginCityStateFlatfileField = {
    // This column can be validated as a single column of
    // CSV 'city,state' values, but when it is submitted
    // as bid-lane uploads, it needs to be split up into
    // two fields for origin_city and origin_state.
    // Is it possible to split it up on the FE?  If not, changes
    // to the BE bid-lane uploads are required to support this.
    key: 'origin_city_state',
    label: BIDDING_NAMES.ORIGIN_CITY_STATE,
    description: 'origin city,state (required if no other location fields provided)',
    alternates: ['Origin City,State', 'Origin City/State', 'Origin CityState'],
    // https://optimaldynamics.atlassian.net/browse/ODPT-4078 - hide some optional fields (revise validators too)
    validators: [
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Origin City,State is required if OriginZipCode is missing.',
            fields: ['origin_zip']
        }
    ]
};

export const LocationOriginCityStateZipFlatfileField = {
    // This column can be validated as a single column of
    // CSV 'city,state,zip' values, but when it is submitted
    // as bid-lane uploads, it needs to be split up into
    // three fields for origin_city, origin_state, and origin_zip.
    // Is it possible to split it up on the FE?  If not, changes
    // to the BE bid-lane uploads are required to support this.
    key: 'origin_city_state_zip',
    label: BIDDING_NAMES.ORIGIN_CITY_STATE_ZIP,
    description:
        'origin city,state,zip code (preferred, sufficient; required if no other location fields provided)',
    alternates: ['Origin City,State,ZipCode'],
    validators: [
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Origin City, State, Zip is required if all of OriginZipCode, OriginCity, OriginCityState and OriginState fields are missing.',
            fields: ['origin_zip', 'origin_city_state']
        }
    ]
};

export const LocationDestinationZipFlatfileField = {
    key: 'destination_zip',
    label: BIDDING_NAMES.DESTINATION_ZIP,
    description:
        'The 3 or 5 digit zip code of the load Destination. Example Format: 925 or 90025. This value is required if Destination City and State are not provided.',
    alternates: ['Destination Zip Code', BIDDING_NAMES.DESTINATION_ZIP],
    // https://optimaldynamics.atlassian.net/browse/ODPT-4078 - hide some optional fields (revise validators too)
    validators: [
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Destination Zip is required if DestinationCityState is missing.',
            fields: ['destination_city_state']
        },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};

export const LocationDestinationCityFlatfileField = {
    // This column cannot be validated as a single column of
    // location data, it must be combined with DestinationState.
    key: 'destination_city',
    label: BIDDING_NAMES.DESTINATION_CITY,
    description:
        'destination city name (requires DestinationState; required if no other location fields provided)',
    alternates: [BIDDING_NAMES.DESTINATION_CITY],
    validators: [
        {
            validate: 'required_with',
            error: 'Destination City is required if DestinationState field is provided.',
            fields: ['destination_state']
        },
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Destination City is required if all of DestinationZipCode, DestinationCityState and DestinationCityStateZip fields are missing.',
            fields: ['destination_zip', 'destination_city_state_zip', 'destination_city_state']
        },
        {
            validate: 'regex_excludes',
            regex: ALPHA_CHARACTER_ONLY_MIN_3_MAX_50,
            error: 'Please enter an alpha character value between 3 and 50 characters.'
        },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};

export const LocationDestinationStateFlatfileField = {
    // This column cannot be validated as a single column of
    // location data, it must be combined with DestinationCity.
    key: 'destination_state',
    label: BIDDING_NAMES.DESTINATION_STATE,
    description:
        'destination state acronym (requires DestinationCity; required if no other location fields provided)',
    alternates: [BIDDING_NAMES.DESTINATION_STATE],
    validators: [
        {
            validate: 'required_with',
            error: 'Destination State is required if DestinationCity field is provided.',
            fields: ['destination_city']
        },
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Destination State is required if all of DestinationZipCode, DestinationCityState and DestinationCityStateZip fields are missing.',
            fields: ['destination_zip', 'destination_city_state_zip', 'destination_city_state']
        },
        {
            validate: 'regex_excludes',
            regex: ALPHA_CHARACTER_ONLY_MIN_2_MAX_60,
            error: 'Please enter an alpha character value between 2 and 60 characters.'
        },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        }
    ]
};

export const LocationDestinationCityStateFlatfileField = {
    // This column can be validated as a single column of
    // CSV 'city,state' values, but when it is submitted
    // as bid-lane uploads, it needs to be split up into
    // two fields for destination_city and destination_state.
    // Is it possible to split it up on the FE?  If not, changes
    // to the BE bid-lane uploads are required to support this.
    key: 'destination_city_state',
    label: BIDDING_NAMES.DESTINATION_CITY_STATE,
    description: 'destination city,state (required if no other location fields provided)',
    alternates: ['Destination City,State'],
    // https://optimaldynamics.atlassian.net/browse/ODPT-4078 - hide some optional fields (revise validators too)
    validators: [
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'DestinationCityState is required if DestinationZipCode is missing.',
            fields: ['destination_zip']
        }
    ]
};

export const LocationDestinationCityStateZipFlatfileField = {
    // This column can be validated as a single column of
    // CSV 'city,state,zip' values, but when it is submitted
    // as bid-lane uploads, it needs to be split up into
    // three fields for destination_city, destination_state, and destination_zip.
    // Is it possible to split it up on the FE?  If not, changes
    // to the BE bid-lane uploads are required to support this.
    key: 'destination_city_state_zip',
    label: BIDDING_NAMES.DESTINATION_CITY_STATE_ZIP,
    description:
        'destination city,state,zip code (preferred, sufficient; required if no other location fields provided)',
    alternates: ['Destination City,State,ZipCode'],
    validators: [
        {
            // this is required only if all of these are missing a mapping
            validate: 'required_without_all',
            error: 'Destination City, State, Zip is required if all of DestinationZipCode, DestinationCity, DestinationCityState and DestinationState fields are missing.',
            fields: ['destination_zip', 'destination_city_state']
        }
    ]
};

//
// These are common load or bid lane fields
//

export const LoadVolumeFlatfileField = {
    key: 'load_volume',
    label: `${BIDDING_NAMES.LOAD_VOLUME}*`,
    description: '[required field] load volume should be an integer value',
    validators: [
        { validate: 'required', error: 'Load Volume is required.' },
        //TODO: check with BE logic, for load volume we use the number/ endpoint which modify the values to decimal values (.00). Will need to either remove this requirement or not call the number/ endpoint or change to BE logic
        // {
        //     validate: 'regex_matches',
        //     regex: POSITIVE_NUMERIC_CHARACTER_ONLY,
        //     error: 'Please enter a numerical whole integer value.'
        // },
        {
            validate: 'regex_matches',
            regex: NULL_VALUES,
            error: 'The value cannot be null.'
        },
        {
            validate: 'regex_matches',
            regex: '^[1-9]d*|1$',
            error: 'Please enter a value greater or equal to 1'
        }
    ]
};

export const LoadRatePerMileFlatfileField = {
    // this is used when there is no preferred_rate_type and flatRate === false
    key: 'rate_per_mile',
    label: `${BIDDING_NAMES.RATE_PER_MILE}*`,
    description:
        '[required field] Rate Per Mile that you plan to offer to the customer for this lane. You will be able to change this later if desired.',
    validators: [
        {
            validate: 'required',
            error: 'Rate Per Mile is required.'
        },
        {
            validate: 'regex_matches',
            regex: POSITIVE_NUMBER_WITH_UP_TO_TWO_DECIMALS,
            error: 'Please enter a valid positive number with up to two decimals.'
        },
        {
            validate: 'regex_excludes',
            regex: '^-',
            error: 'Rate Per Mile cannot be a negative value.'
        },
        {
            validate: 'regex_excludes',
            regex: '^0\\.00$',
            error: 'Rate Per Mile cannot be $0.00.'
        }
    ]
};

export const LoadRateFlatfileField = {
    label: 'Rate*',
    key: 'rate_per_mile',
    description:
        '[required field] Rate that you plan to offer to the customer for this lane. You will be able to change this later if desired.',
    validators: [
        {
            validate: 'required',
            error: 'Rate is required.'
        },
        {
            validate: 'regex_matches',
            regex: POSITIVE_NUMBER_WITH_UP_TO_TWO_DECIMALS,
            error: 'Please enter a valid positive number with up to two decimal places.'
        },
        {
            validate: 'regex_excludes',
            regex: '^-',
            error: 'Rate cannot be a negative value.'
        },
        {
            validate: 'regex_excludes',
            regex: '^0\\.00$',
            error: 'Rate cannot be $0.00.'
        }
    ]
};

export const LoadPreferredRateTypeFlatfileField = {
    label: 'Rate Type',
    key: 'preferred_rate_type',
    description: 'Rate Per Mile or Flat Rate',
    type: 'select',
    options: [
        { value: 'rate_per_mile', label: BIDDING_NAMES.RATE_PER_MILE },
        { value: 'flat_rate', label: BIDDING_NAMES.FLAT_RATE }
    ]
};

export const LoadFrequencyFlatfileField = {
    key: 'load_volume_type',
    label: `${BIDDING_NAMES.LOAD_FREQUENCY}*`,
    description:
        '[required field] load volume type should be by-day, by-week, by-month, or by-year',
    validators: [{ validate: 'required', error: 'Load Frequency is required.' }],
    type: 'select',
    options: [
        {
            value: 'by-year',
            label: 'By-Year',
            alternates: ['year', 'yearly']
        },
        {
            value: 'by-month',
            label: 'By-Month',
            alternates: ['month', 'monthly']
        },
        {
            value: 'by-week',
            label: 'By-Week',
            alternates: ['week', 'weekly']
        },
        {
            value: 'by-day',
            label: 'By-Day',
            alternates: ['day', 'daily']
        }
    ]
};

export const EquipmentTypeFlatfileField = {
    key: 'equipment_type',
    label: BIDDING_NAMES.EQUIPMENT_TYPE
};

export const OriginLoadMethodFlatfileField = {
    key: 'origin_load_method',
    label: BIDDING_NAMES.ORIGIN_LOAD_METHOD,
    description: 'if there is a load method, it should be live or hook',
    type: 'select',
    options: [
        { value: 'live', label: 'live' },
        { value: 'hook', label: 'hook' },
        { value: 'all', label: 'all' },
        { value: '', label: '' }
    ],
    validators: [
        {
            validate: 'regex_matches',
            regex: '^(live|hook|all)$',
            error: 'Please enter one of the acceptable options'
        }
    ]
};

export const DestinationUnloadMethodFlatfileField = {
    key: 'destination_unload_method',
    label: BIDDING_NAMES.DESTINATION_UNLOAD_METHOD,
    description: 'if there is an unload method, it should be live or drop',
    type: 'select',
    options: [
        { value: 'live', label: 'live' },
        { value: 'drop', label: 'drop' },
        { value: 'all', label: 'all' },
        { value: '', label: '' }
    ],
    validators: [
        {
            validate: 'regex_matches',
            regex: '^(live|drop|all)$',
            error: 'Please enter one of the acceptable options'
        }
    ]
};

export const MileageFlatfileField = {
    key: 'mileage',
    label: `${BIDDING_NAMES.MILEAGE}*`,
    description:
        '[required field] Number of miles between the origin and destination locations for this lane. If this is not available, map this field to a blank column, and it will be automatically calculated. ',
    validators: [
        { validate: 'required', error: 'Mileage is required.' },
        {
            validate: 'regex_matches',
            regex: POSITIVE_NUMBER_WITH_UP_TO_TWO_DECIMALS,
            error: 'Please enter a valid positive number with up to two decimals.'
        }
    ]
};

export const FuelSurchargeFlatfileField = {
    key: 'other_revenue',
    label: BIDDING_NAMES.FUEL_SURCHARGE,
    description: 'Additional lane revenue besides the customer line haul (such as fuel)',
    validators: [
        {
            validate: 'regex_matches',
            regex: NONNEGATIVE_DOLLAR_VALUE,
            error: 'Please enter a valid positive number with up to two decimal places.'
        }
    ]
};

export const FuelSurchargeFlatfileFieldRemapped = {
    key: 'other_revenue_per_mile',
    label: BIDDING_NAMES.FUEL_SURCHARGE,
    description: 'Additional lane revenue besides the customer line haul (such as fuel)',
    validators: [
        {
            validate: 'regex_matches',
            regex: NONNEGATIVE_DOLLAR_VALUE,
            error: 'Please enter a valid positive number with up to two decimal places.'
        }
    ]
};

export const LineHaulRevenuePerMileFlatfileField = {
    key: 'other_revenue_per_mile',
    label: 'Line Haul Revenue Per Mile',
    description: 'Additional lane revenue per mile besides the customer line haul (such as fuel)',
    validators: [
        {
            validate: 'regex_matches',
            regex: NONNEGATIVE_DOLLAR_VALUE,
            error: 'Please enter a valid positive number with up to two decimal places.'
        }
    ]
};

export const CustomerLaneIdFlatfileField = {
    key: 'customer_bid_id',
    label: BIDDING_NAMES.CUSTOMER_LANE_ID,
    description: 'Unique key used to identify the bids lane',
    validators: [
        {
            validate: 'unique',
            error: 'Duplicate Lanes ID’s detected. Please remove these lanes from your file and try again.'
        }
    ]
};

export const bidCommonFlatfileFields = [
    LoadVolumeFlatfileField,
    LoadFrequencyFlatfileField,
    EquipmentTypeFlatfileField,
    OriginLoadMethodFlatfileField,
    DestinationUnloadMethodFlatfileField,
    MileageFlatfileField,
    CustomerLaneIdFlatfileField
];

export const bidLocationFlatfileFields = [
    LocationOriginFlatfileField,
    LocationDestinationFlatfileField
];
export const bidLocation3and4ZipFlatfileFields = [
    LocationOrigin4and5ZipFlatfileField,
    LocationDestination4and5ZiFlatfileField
];

export const bidFlexibleLocationFlatfileFields = [
    // Origin location fields
    LocationOriginZipFlatfileField,
    LocationOriginCityStateFlatfileField,
    // Destination location fields
    LocationDestinationZipFlatfileField,
    LocationDestinationCityStateFlatfileField

    // https://optimaldynamics.atlassian.net/browse/ODPT-4078 - hide these fields
    // LocationOriginCityFlatfileField,
    // LocationOriginStateFlatfileField,
    // LocationOriginCityStateZipFlatfileField,
    // LocationDestinationCityFlatfileField,
    // LocationDestinationStateFlatfileField,
    // LocationDestinationCityStateZipFlatfileField
];

export const salesRankingFlatfileField = {
    key: 'sales_ranking',
    label: 'Sales Ranking',
    description: 'Sales Ranking',
    validators: [
        {
            validate: 'regex_matches',
            regex: POSITIVE_NUMERIC_CHARACTER_ONLY,
            error: 'Please enter a numerical whole integer value.'
        }
    ]
};

export const bidFields = (featureFlags: FeatureFlags) => {
    let fields;
    const hiddenFieldsUnderFlag = featureFlags.lineHaulRevPerMile
        ? [LineHaulRevenuePerMileFlatfileField]
        : [];

    if (featureFlags.flexibleLocationFlatfileFlag) {
        fields = [
            ...bidFlexibleLocationFlatfileFields,
            ...bidCommonFlatfileFields,
            ...hiddenFieldsUnderFlag
        ];
    } else if (featureFlags.odpt407934Zip) {
        fields = [
            ...bidLocation3and4ZipFlatfileFields,
            ...bidCommonFlatfileFields,
            ...hiddenFieldsUnderFlag
        ];
    } else {
        fields = [
            ...bidLocationFlatfileFields,
            ...bidCommonFlatfileFields,
            ...hiddenFieldsUnderFlag
        ];
    }

    if (featureFlags.flatRates) {
        fields = [...fields, LoadRateFlatfileField, LoadPreferredRateTypeFlatfileField];
    } else {
        fields = [...fields, LoadRatePerMileFlatfileField];
    }

    if (featureFlags.salesRanking) {
        fields = [...fields, salesRankingFlatfileField];
    }

    //TODO: The remapped version can become standard once flag is deprecated
    if (featureFlags.remapFuelSurchargeToOtherRevenuePerMile) {
        fields = [...fields, FuelSurchargeFlatfileFieldRemapped];
    } else {
        fields = [...fields, FuelSurchargeFlatfileField];
    }

    return fields;
};

export const importerSettings = (featureFlags: FeatureFlags) => ({
    type: 'Bid',
    fields: bidFields(featureFlags),
    managed: true,
    title: 'Upload Bids',
    theme: {
        dropzone: {}
    }
});

// DATA requirements from Forecasting/SmartTL engine

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const data_description = {
    BID_CONFIG_ID: ['*', 'The unique identifier for a bid file', 'String'],
    BID_ID: [
        '*',
        'It has to uniquely identify a bid. Each row in the bid file represents a single bid.',
        'String non-duplicated-record'
    ],
    ORIG: [
        '*',
        'Location where the loads generated from this bid should be picked up',
        'String LOCATION'
    ],
    DEST: [
        '*',
        'Location where the loads generated from this bid should be delivered',
        'String LOCATION'
    ],
    VOL: ['*', 'Number of loads desired on average over the period designated by the FREQ', 'int'],
    FREQ: [
        '*',
        'Frequency to designate the volume per unit of time',
        ['BY-DAY', 'BY-WEEK', 'BY-MONTH', 'BY-YEAR']
    ],
    MILEAGE: ['*', 'Mileage between ORIG/DEST in miles. Positive number', 'float'],
    REV_PER_MILE: ['*', 'Revenue per mile on the lane being bid on. Positive number', 'float'],
    SHIPPER_ID: ['RegularShipper', 'The ID of the shipper moving the load', 'String'],
    EQUIP: ['NONE', 'The equipment required to move the load', 'String'],
    PKUP_OPER_HRS: ['24-7', 'Operating Hours at the Pickup Location', 'String'],
    DLVERY_OPER_HRS: ['24-7', 'Operating Hours at the Delivery Location', 'String'],
    ORIG_LOAD_METHOD: [
        'LIVE',
        'How the loads are loaded at the origin location',
        'String (needs to be changed to [LIVE, HOOK] in the future)'
    ],
    DEST_LOAD_METHOD: [
        'DROP',
        'How the loads are unloaded at the destination location',
        'String (needs to be changed to [LIVE, DROP] in the future)'
    ],
    DLVERY_LEAD_TIME: [
        'NONE',
        'Delivery location lead time in the units specified by the DLVERY_LEAD_TYPE',
        'String'
    ],
    DLVERY_LEAD_TYPE: ['STANDARD', 'Delivery location lead type', 'String'],
    OTHER_REV: [0, 'Other revenue', 'float'],
    OTHER_REV_PER_MILE: [0, 'Other revenue per mile', 'float']
};
