import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import CheckboxComponent from './CheckboxComponent';
import ReportStatusInfo from './ReportStatusInfo';
import palette from 'theme/palette';
import SwapVertSharpIcon from '@mui/icons-material/SwapVertSharp';
import { styled } from '@mui/material/styles';

const Label = styled(Box)(() => ({
    marginBottom: '8px',
    color: palette.ODBlue.odBlue7,
    fontWeight: 'normal',
    fontSize: '12px'
}));

const NoReport = styled(Box)(() => ({
    width: 424,
    height: 40,
    border: `1px solid ${palette.neutral.neutral1}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    color: palette.ODBlue.odBlue9
}));

const ReportBox = styled(Box)(() => ({
    backgroundColor: palette.neutral.neutral1,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    width: '424px',
    borderRadius: '4px'
}));

const ReportBoxDetails = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
}));

const SwapIconContainer = styled(Box)(() => ({
    marginRight: '10px',
    display: 'flex',
    alignSelf: 'center'
}));

const SelectedReport = ({
    onChecked,
    label,
    handleSwap,
    report
}: {
    onChecked: (report: any, e: any) => {};
    label: string;
    handleSwap: () => {};
    report: any;
}) => {
    const reportName = report?.name;
    const reportStatus = report?.report_status;

    return (
        <Box style={{ marginBottom: '16px' }}>
            <Label>{label}</Label>
            {report !== null ? (
                <ReportBox>
                    <ReportBoxDetails>
                        <CheckboxComponent
                            isChecked={true}
                            onChecked={(e: any) => {
                                onChecked(report, e);
                            }}
                        />
                        <ReportStatusInfo status={reportStatus} showLabel={false} />
                        <Tooltip title={reportName}>
                            <Typography
                                style={{
                                    color: palette.black,
                                    paddingLeft: '16px',
                                    fontSize: '14px',
                                    width: '300px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    marginRight: '16px',
                                    textOverflow: 'ellipsis'
                                }}>
                                {reportName}
                            </Typography>
                        </Tooltip>
                    </ReportBoxDetails>
                    <SwapIconContainer>
                        <SwapVertSharpIcon
                            sx={{ cursor: 'pointer', color: palette.semantic.semanticGreen }}
                            onClick={handleSwap}
                        />
                    </SwapIconContainer>
                </ReportBox>
            ) : (
                <NoReport>No Report Selected</NoReport>
            )}
        </Box>
    );
};

export default SelectedReport;
