import React from 'react';
import { Box, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useNetworkViewHook } from 'utils/hooks';
import { NETWORK_TABLE_TYPE, ROW_TYPE } from 'constants/network';
import { DRIVER_MODE } from 'constants/drivers';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';

const NetworkViewBreadcrumbs = () => {
    const theme = useTheme();
    const { loadFilters } = useSelector((state: RootState) => state.ReportsReducers);
    const { handleBackToPreviousView_NEW } = useNetworkViewHook();
    const { currTab, driverMode, networkBreadcrumbs } = useSelector(
        ({ NetworkReducer }: RootState) => NetworkReducer
    );

    const crumbsForTypes = [
        { name: DRIVER_MODE.DOMICILE, altName: networkBreadcrumbs.domicile, isLink: true },
        { name: DRIVER_MODE.DRIVER_TYPES, altName: networkBreadcrumbs.driverTypes, isLink: false }
    ];

    const crumbsForMoves = [
        { name: DRIVER_MODE.DOMICILE, altName: networkBreadcrumbs.domicile, isLink: true },
        { name: DRIVER_MODE.DRIVER_TYPES, altName: networkBreadcrumbs.driverTypes, isLink: true },
        { name: DRIVER_MODE.DRIVER_ID, altName: networkBreadcrumbs.driverId, isLink: false }
    ];

    const BreadcrumbsContainer = styled(Box)(() => ({
        borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
        borderBottom: `1px solid ${theme.palette.neutral.neutral1}`,
        padding: '10px 16px',
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-start',
        alignItems: 'center',
        zIndex: 13,
        backgroundColor: theme.palette.common.white
    }));

    const BradcrumbsHeaderText = styled(Typography)(() => ({
        textTransform: 'capitalize',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.ODBlue.odBlue7
    }));

    const BreadcrumbsText = styled(Typography)(() => ({
        color: theme.palette.common.black,
        textTransform: 'capitalize'
    }));

    const setCrumbs = (tab: string) => {
        if (tab === NETWORK_TABLE_TYPE.SHIPPERS) {
            return <BreadcrumbsText>{NETWORK_TABLE_TYPE.LANES}</BreadcrumbsText>;
        } else if (tab === NETWORK_TABLE_TYPE.LANES) {
            return <BreadcrumbsText>{NETWORK_TABLE_TYPE.SHIPPERS}</BreadcrumbsText>;
        } else if (tab === NETWORK_TABLE_TYPE.DRIVERS) {
            if (driverMode === DRIVER_MODE.DOMICILE) {
                return <BreadcrumbsText>{networkBreadcrumbs.domicile}</BreadcrumbsText>;
            } else if (driverMode === DRIVER_MODE.DRIVER_TYPES) {
                return crumbsForTypes.map((item: any, i: number) => {
                    const { name, altName, isLink } = item;

                    return (
                        <BreadcrumbsText
                            key={i}
                            sx={{
                                cursor: isLink ? 'pointer' : 'inherit',
                                textDecoration: isLink ? 'underline' : 'inherit'
                            }}
                            onClick={() =>
                                isLink
                                    ? handleBackToPreviousView_NEW(false, name)
                                    : console.info('No actions for this breadcrumbs')
                            }>
                            {altName ? altName : name}
                        </BreadcrumbsText>
                    );
                });
            } else if (driverMode === DRIVER_MODE.DRIVER_ID) {
                return crumbsForMoves.map((item: any, i: number) => {
                    const { name, altName, isLink } = item;

                    return (
                        <BreadcrumbsText
                            key={i}
                            sx={{
                                cursor: isLink ? 'pointer' : 'inherit',
                                textDecoration: isLink ? 'underline' : 'inherit'
                            }}
                            onClick={() =>
                                isLink
                                    ? handleBackToPreviousView_NEW(false, name)
                                    : console.info('No actions for this breadcrumbs')
                            }>
                            {altName ? altName : name}
                        </BreadcrumbsText>
                    );
                });
            } else return null;
        } else return null;
    };

    return (
        <>
            {loadFilters?.isFilterSet ? (
                <BreadcrumbsContainer>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        sx={{
                            color: theme.palette.common.black
                        }}
                        separator=">">
                        <BradcrumbsHeaderText
                            onClick={() => handleBackToPreviousView_NEW(false, currTab)}>
                            {networkBreadcrumbs[
                                currTab === NETWORK_TABLE_TYPE.SHIPPERS
                                    ? ROW_TYPE.SHIPPER
                                    : ROW_TYPE.LANE
                            ] || currTab}
                        </BradcrumbsHeaderText>
                        {setCrumbs(currTab)}
                    </Breadcrumbs>
                </BreadcrumbsContainer>
            ) : null}
        </>
    );
};

export default NetworkViewBreadcrumbs;
