import { createSvgIcon } from '@mui/material';

export const OutdatedReportIcon = createSvgIcon(
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5 1.5H8L6.5 0H0.5V12H15.5V1.5ZM13.25 8.34L11.66 6.75L13.25 5.16L11.84 3.75L10.25 5.34L8.66 3.75L7.25 5.16L8.84 6.75L7.25 8.34L8.66 9.75L10.25 8.16L11.84 9.75L13.25 8.34Z"
            fill="currentColor"
        />
    </svg>,
    'OutdatedReportIcon'
);
