/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from 'redux/reducers';
import { useNavigate } from 'react-router-dom';
import ODSnackbar, { ODSNACKBAR_TYPE } from 'shared/Snackbar';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { updateBidAnalysis } from 'redux/actions';

const OutdatedBaseReportSnackbar = ({
    showOutdatedBaseAlert
}: {
    showOutdatedBaseAlert: boolean;
}) => {
    const { currBidAnalysisData } = useSelector((state: RootState) => state.BidAnalysisReducer);
    const latestBaseReport = currBidAnalysisData?.latest_base;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCloseSnack = () => {
        dispatch(updateBidAnalysis({ showOutdatedReportAlert: false }));
    };

    return (
        <ODSnackbar
            text={
                'Update Your Bid Analysis: This Bid Analysis was created on an outdated version of your Base Network. To access your latest results and view their impact on your Network, simply click “View Latest Base” button below and create a new Bid Analysis using the latest version of your Base Network.'
            }
            buttons={
                <ODButton
                    onClick={() => {
                        const url = `/reports/${
                            latestBaseReport?.bucket
                                ? `bucket/${latestBaseReport?.bucket}/`
                                : `report-run/`
                        }${latestBaseReport?.id}/network/shipper/`;
                        navigate(url);
                    }}
                    buttonType={BUTTON_TYPE.YELLOW}
                    buttonText="VIEW LATEST BASE"
                    sx={{ fontWeight: 600 }}
                />
            }
            open={showOutdatedBaseAlert}
            onCloseHandler={handleCloseSnack}
            type={ODSNACKBAR_TYPE.WARNING}
        />
    );
};

export default OutdatedBaseReportSnackbar;
