import { createSvgIcon } from '@mui/material';

export const GroupIcon = createSvgIcon(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
            <path
                id="Subtract"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 1.5H10.5L15 6V16.5H3V1.5ZM9.75 2.625V6H13.125L9.75 2.625ZM12 12H9.75V14.25H8.25V12H6V10.5H8.25V8.25H9.75V10.5H12V12Z"
                fill="currentColor"
            />
        </g>
    </svg>,
    'GroupIcon'
);
