import { styled, Box, Toolbar } from '@mui/material';
import palette from 'theme/palette';

export const BidConfigPage = styled(Box)(() => ({
    backgroundColor: palette.white,
    fontSize: '14px',
    inset: '9px',
    borderRadius: '4px',
    height: '100vh'
}));

export const BidConfigPage4185 = styled(Box)(() => ({
    backgroundColor: palette.white,
    fontSize: '14px',
    marginRight: '56px',
    inset: 0,
    borderRadius: 0,
    height: '100vh'
}));

export const TableFooterContainer = styled(Box)(
    ({ openRunBidAnalysesSlideout }: { openRunBidAnalysesSlideout: boolean }) => ({
        width: openRunBidAnalysesSlideout ? 'calc(100vw - 568px)' : 'calc(100vw - 112px)', // TODO may need to rectify when adding pagination
        marginTop: '16px',
        position: 'absolute',
        bottom: 0,
        marginBottom: '56px'
    })
);

export const StyledBidConfigBodyContainer = styled(Box)({
    padding: '0',
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden'
});

export const BidConfigRightContent = styled(Box)({
    width: '456px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.white,
    overflowY: 'auto',
    height: '100%',
    borderLeft: `1px solid ${palette.neutral.neutral1}`
});

export const BidConfigsLeftContent = styled(Box)(
    ({
        openRunBidAnalysesSlideout,
        odpt4185BidConfigUpdate
    }: {
        openRunBidAnalysesSlideout: boolean;
        odpt4185BidConfigUpdate: boolean;
    }) => ({
        flexDirection: 'column',
        display: 'flex',
        width: openRunBidAnalysesSlideout ? 'calc(100vw - 572px)' : '100%',
        overflow: 'hidden',
        padding: '0',
        marginRight: odpt4185BidConfigUpdate ? 0 : '16px',
        height: '100%'
    })
);

export const BreadcrumbsSection = styled(Box)({
    display: 'flex',
    backgroundColor: palette.white,
    color: palette.black,
    borderBottom: `1px solid ${palette.neutral.neutral1}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '40px'
});

export const NewBidConfigHeaderToolbar = styled(Toolbar)({
    display: 'flex',
    zIndex: 9,
    boxShadow: 'none',
    backgroundColor: palette.white,
    color: palette.black,
    borderBottom: `1px solid ${palette.neutral.neutral1}`,
    borderRadius: '4px',
    minHeight: '56px !important',
    justifyContent: 'space-between',
    padding: '0 !important',
    width: '100%',
    height: '56px'
});

export const TabsHeaderSection = styled(Box)({
    display: 'flex',
    backgroundColor: palette.white,
    color: palette.black,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
});
