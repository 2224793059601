import { useEffect, useRef, useState } from 'react';
import { Box, Snackbar, SnackbarContent, Typography } from '@mui/material';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { PriorityHigh, Check, HelpOutline, InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/system';

const StyledSnackbarContent = styled(SnackbarContent)(
    ({
        textColor,
        backgroundColor,
        isMultiline
    }: {
        textColor: string;
        backgroundColor: string;
        isMultiline: boolean;
    }) => ({
        backgroundColor: backgroundColor,
        color: textColor,
        width: '640px',
        padding: `8px 16px ${isMultiline ? '16px' : '8px'} 16px`,
        '& .MuiSnackbarContent-message': { padding: 0 },
        '& .MuiSnackbarContent-action': { marginRight: 0 }
    })
);
const IconContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    height: '1.43rem'
}));
const MessageContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'start',
    gap: '16px',
    paddingTop: '8px',
    paddingBottom: '8px'
}));
const useStyles = makeStyles(() => ({
    root: {
        bottom: '71px',
        right: '73px',
        left: 'auto'
    }
}));
const commonIconSX = {
    paddingBottom: '2px'
};

export enum ODSNACKBAR_TYPE {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    HELP = 'help'
}

const ODSnackbar = ({
    open,
    onCloseHandler,
    text,
    type,
    buttons,
    backButtons
}: {
    open: boolean;
    onCloseHandler: () => void;
    text: string;
    type: ODSNACKBAR_TYPE;
    buttons?: JSX.Element;
    backButtons?: JSX.Element;
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const textRef = useRef<HTMLDivElement | null>(null);
    const [isMultiline, setIsMultiline] = useState(false);

    useEffect(() => {
        const textElement = textRef.current;
        if (textElement) {
            setIsMultiline(textElement.clientWidth > 509);
        }
    }, [text]);

    let backgroundColor: string;
    let icon;
    let buttonType;
    let textColor: string;
    switch (type) {
        case 'error':
            backgroundColor = theme.palette.semantic.semanticRed;
            icon = <PriorityHigh fill={theme.palette.common.white} sx={{ ...commonIconSX }} />;
            buttonType = BUTTON_TYPE.RED;
            textColor = theme.palette.common.white;
            break;
        case 'success':
            backgroundColor = theme.palette.success.main;
            icon = <Check fill={theme.palette.common.white} sx={{ ...commonIconSX }} />;
            buttonType = BUTTON_TYPE.GREEN;
            textColor = theme.palette.common.white;
            break;
        case 'info':
            backgroundColor = theme.palette.semantic.focusedBlue;
            icon = <InfoOutlined fill={theme.palette.common.white} sx={{ ...commonIconSX }} />;
            buttonType = BUTTON_TYPE.BLUE;
            textColor = theme.palette.common.white;
            break;
        case 'help':
            backgroundColor = theme.palette.semantic.focusedBlue;
            icon = <HelpOutline fill={theme.palette.common.white} sx={{ ...commonIconSX }} />;
            buttonType = BUTTON_TYPE.BLUE;
            textColor = theme.palette.common.white;
            break;
        case 'warning':
            backgroundColor = theme.palette.semantic.semanticYellow;
            icon = <PriorityHigh fill={theme.palette.common.black} sx={{ ...commonIconSX }} />;
            buttonType = BUTTON_TYPE.YELLOW;
            textColor = theme.palette.common.black;
            break;
        default:
            backgroundColor = theme.palette.common.white;
            buttonType = BUTTON_TYPE.DARK;
            textColor = theme.palette.common.black;
            break;
    }
    return (
        <Snackbar
            open={open}
            onClose={onCloseHandler}
            classes={{
                root: classes.root
            }}>
            <StyledSnackbarContent
                isMultiline={isMultiline}
                textColor={textColor}
                backgroundColor={backgroundColor}
                message={
                    <MessageContainer>
                        <IconContainer>{icon}</IconContainer>
                        <Typography ref={textRef}>{text}</Typography>
                    </MessageContainer>
                }
                action={
                    <>
                        {buttons}
                        <ODButton
                            onClick={onCloseHandler}
                            buttonType={buttonType}
                            buttonText="DISMISS"
                            sx={{ fontWeight: 600 }}
                        />
                        {backButtons}
                    </>
                }
            />
        </Snackbar>
    );
};

export default ODSnackbar;
