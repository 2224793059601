import { createSvgIcon } from '@mui/material';

export const MultipleFoldersIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.5999 5.6H21.5999V16.8H5.5999V4H11.9999L13.5999 5.6ZM7.2 7.2V15.2H20V7.2H7.2ZM2.3999 20V7.2H3.9999V18.4H18.3999V20H2.3999Z"
            fill="currentColor"
        />
    </svg>,
    'MultipleFoldersIcon'
);
