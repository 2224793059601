/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ODDrawer, ODTextField, ODSelect, ODButton } from 'shared';
import API from 'utils/axios';
import { updateConfigurationName } from 'redux/actions/';
import { REPORT_STATUS } from 'constants/network';
import { hasDuplicates } from 'utils/common.util';
import { DUPLICATE_ERROR_MESSAGE } from 'constants/network';
import { Formik } from 'formik';
import { Box, Typography } from '@mui/material';
import { BUTTON_TYPE } from 'constants/colors';
import * as yup from 'yup';
import { useTheme } from '@mui/system';

interface editReportDetailsSlideoutProps {
    reportRun: any;
    onClose: any;
    buckets: any;
    reportRunsInfo: any;
}

const EditReportDetailsSlideout = ({
    reportRun,
    onClose,
    buckets,
    reportRunsInfo
}: editReportDetailsSlideoutProps) => {
    const theme = useTheme();
    const { newSimulationFlow } = useFlags();
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [errorDuplicatedName, setErrorDuplicateName] = useState<string | null>(null);

    const onChange = (event: any) => {
        setSelectedGroup(event.target.value);
    };

    const submitGroup = async (name: string) => {
        if (!name) return;
        try {
            if (name !== reportRun.name && hasDuplicates(reportRun, reportRunsInfo, name)) {
                setErrorDuplicateName(DUPLICATE_ERROR_MESSAGE);
            } else if (newSimulationFlow && reportRun.report_status === REPORT_STATUS.DRAFT) {
                setErrorDuplicateName(null);
                await dispatch(
                    updateConfigurationName(reportRun.id, reportRun.config_id, name, selectedGroup)
                );
                onClose(true);
            } else {
                setErrorDuplicateName(null);
                await API.put(`/reports/report-runs/${reportRun.id}/`, {
                    name,
                    bucket: selectedGroup
                });
                onClose(true);
            }
        } catch (error) {
            console.warn(
                `/report-runs/${reportRun.id} could not update the report details (src: EditReportDetailsSlideout.tsx)`
            );
        }
    };
    const filteredBucket =
        buckets?.map((item: any) => {
            return {
                value: item?.id,
                name: item?.name
            };
        }) || [];

    useEffect(() => {
        const runsList = buckets.find(({ id }: any) => reportRun?.bucket === id);
        setSelectedGroup(runsList?.id);
    }, []);

    const refreshBidAnalysisSchema = yup.object({
        reportName: yup.string().min(1).max(100).required('Required')
    });

    return (
        <ODDrawer
            open={true}
            title="Edit Report Details"
            anchor="right"
            onClose={onClose}
            enableCustomizedButton={true}
            children={
                <>
                    <Formik
                        initialValues={{ reportName: reportRun?.name }}
                        validationSchema={refreshBidAnalysisSchema}
                        onSubmit={async (values, { resetForm }) => {
                            submitGroup(values?.reportName);
                            resetForm();
                            onClose(true);
                        }}>
                        {({ handleSubmit, values, handleChange, isValid, resetForm }: any) => {
                            const clearAndClose = () => {
                                resetForm();
                                onClose(true);
                            };

                            return (
                                <form onSubmit={handleSubmit}>
                                    <ODTextField
                                        customInputProps={{ maxLength: 100 }}
                                        placeholder="Enter Name"
                                        dataTestId="rename-file-name-input"
                                        label="Rename File"
                                        value={values?.reportName}
                                        onChange={handleChange}
                                        name="reportName"
                                        errorText={errorDuplicatedName}
                                    />
                                    <Typography
                                        sx={{
                                            marginTop: '2px',
                                            textAlign: 'right',
                                            fontSize: '12px',
                                            color: theme.palette.neutral.neutral6
                                        }}>
                                        {values?.reportName.length}/100 Character Limit
                                    </Typography>
                                    <ODSelect
                                        sx={{
                                            marginTop: '16px'
                                        }}
                                        dataTestId="group-select-report-run"
                                        title="Edit Group"
                                        name="group-select-report-runs"
                                        displayEmpty
                                        value={selectedGroup}
                                        onChange={onChange}
                                        placeholder="Select Option"
                                        itemList={filteredBucket}
                                    />

                                    <Box display="flex" flexDirection="column">
                                        <ODButton
                                            width="100%"
                                            buttonType={BUTTON_TYPE.BLUE}
                                            type="submit"
                                            buttonText={'Save'}
                                            disabled={!isValid}
                                        />
                                        <ODButton
                                            width="100%"
                                            onClick={clearAndClose}
                                            type="button"
                                            sx={{ marginTop: '8px' }}
                                            buttonText="Cancel"
                                        />
                                    </Box>
                                </form>
                            );
                        }}
                    </Formik>
                </>
            }
        />
    );
};

export default EditReportDetailsSlideout;
