import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { NavbarDivider, ODButton, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DownloadBidAnalysisModal from './DownloadBidAnalysisModal';
import RefreshBidAnalysisModal from './RefreshBidAnalysisModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { convertDateObjIntoUSFormat } from 'utils/date.util';
import { OutdatedReportIcon } from 'assets/images';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';

import {
    RightSideHeaderContent,
    BidAnalysisHeaderContainer,
    DateRangeWrapper
} from './Header.styles';
import FolderSpecialSharpIcon from '@mui/icons-material/FolderSpecialSharp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { updateBidAnalysis } from 'redux/actions';
import { useBidAnalysisHook } from 'utils/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as FS from '@fullstory/browser';
import { useParamsProps } from 'types/hooks';
import { useParams } from 'react-router-dom';
import { HISTORY_CHANGES_TYPES } from 'constants/network';
import DownloadNetworkViewImpactModal from './DownloadNetworkImpactModal';
import { REPORT_SUB_TYPE } from 'constants/reports';
import { updateModalState } from '../../../redux/actions/modalsAction';
import { useTheme } from '@mui/system';

const BidAnalysisHeader = () => {
    const theme = useTheme();
    const { currBidAnalysisData, bidAnalysisTableDataErrors, selectedLaneBreadcrumb } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );

    const { odpt4185BidConfigUpdate, refreshBid } = useFlags();

    //TODO check changes made in bid analysis page
    const { configurationActions, prevConfigurationActions } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );
    const { config_id }: useParamsProps = useParams();
    const configuration = useMemo(() => {
        return {
            ...currBidAnalysisData,
            id: config_id,
            actions: configurationActions,
            prevActions: prevConfigurationActions
        };
    }, [config_id, configurationActions, currBidAnalysisData, prevConfigurationActions]);
    const hasBidAnalysisChange = useMemo(() => {
        return (
            configuration.actions?.filter(
                (type: any) => type?.type === HISTORY_CHANGES_TYPES.BID_ANALYSIS
            )?.length > 0
        );
    }, [configuration.actions]);

    const dispatch = useDispatch();
    const [openExportModal, setOpenExportModal] = useState(false);
    const [openRefreshModal, setOpenRefreshModal] = useState(false);
    const [openDownloadNetworkImpactModal, setOpenDownloadNetworkImpactModal] = useState(false);
    const { navigateToBidAnalysisPage, isLatest } = useBidAnalysisHook();

    const handleBaseReportNameClick = () => {
        dispatch(updateBidAnalysis({ showOutdatedReportAlert: true }));
    };

    const handleClickOutdatedReportName = () => {
        FS.init({ orgId: '18AJTW' });
        FS.event('Bid Analysis_Click Outdated Base Report', {});
        handleBaseReportNameClick();
    };

    const isFinalizedBid = Boolean(
        currBidAnalysisData?.file_sub_type === REPORT_SUB_TYPE.FINALIZED
    );

    return (
        <BidAnalysisHeaderContainer>
            <Box display="flex" justifyContent="center" alignItems="center">
                {selectedLaneBreadcrumb && (
                    <ODIconButton
                        onClick={() => {
                            FS.event('BidAnalysis_Uesr_Clicks_Go_Back', {});
                            navigateToBidAnalysisPage();
                            dispatch(updateBidAnalysis({ selectedLaneId: null }));
                        }}
                        enableTooltip={true}
                        title={'Go back'}
                        sx={{ marginRight: '16px', cursor: 'pointer' }}
                        id={`go-to-previous-page`}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        icon={
                            <ArrowBackIcon
                                sx={{ color: theme.palette.common.black }}
                                fontSize="small"
                            />
                        }
                    />
                )}

                {isFinalizedBid ? (
                    <InsightsOutlinedIcon sx={{ marginRight: '16px' }} />
                ) : (
                    <FolderSharpIcon sx={{ marginRight: '16px' }} />
                )}

                <Typography sx={{ fontWeight: 600 }}>{currBidAnalysisData?.name}</Typography>
            </Box>
            <RightSideHeaderContent key={currBidAnalysisData?.parent_name}>
                <DateRangeWrapper>
                    {isLatest ? (
                        <FolderSpecialSharpIcon
                            fontSize="small"
                            sx={{
                                marginRight: '16px',
                                color: theme.palette.neutral.neutral6
                            }}
                        />
                    ) : (
                        <Box sx={{ marginRight: '16px' }}>
                            <OutdatedReportIcon
                                style={{ color: theme.palette.semantic.semanticRed }}
                            />
                        </Box>
                    )}
                    <Typography
                        sx={{
                            color: theme.palette.neutral.neutral6,
                            textTransform: 'initial',
                            textDecoration: isLatest ? 'none' : 'underline',
                            cursor: isLatest ? 'default' : 'pointer'
                        }}
                        onClick={isLatest ? () => '' : handleClickOutdatedReportName}>
                        {currBidAnalysisData?.parent_name
                            ? currBidAnalysisData?.parent_name
                            : 'Base Report'}
                    </Typography>
                </DateRangeWrapper>
                <NavbarDivider light={true} />
                <DateRangeWrapper>
                    <CalendarTodaySharpIcon fontSize="small" sx={{ marginRight: '16px' }} />
                    <Box>{`${convertDateObjIntoUSFormat(
                        currBidAnalysisData?.start_date
                    )} - ${convertDateObjIntoUSFormat(currBidAnalysisData?.end_date)}`}</Box>
                </DateRangeWrapper>
                <NavbarDivider light={true} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginLeft: '8px' }}>
                    <ODButton
                        buttonType={BUTTON_TYPE.LIGHT_BLUE}
                        buttonText="Download Bid Analysis"
                        endIcon={<GetAppSharpIcon />}
                        sx={{ marginRight: '16px' }}
                        onClick={() => {
                            setOpenExportModal(true);
                            FS.event('Click Download Bid Analysis Button', {});
                        }}
                    />
                    {isFinalizedBid && odpt4185BidConfigUpdate && (
                        <>
                            <ODButton
                                disabled={hasBidAnalysisChange}
                                buttonType={BUTTON_TYPE.LIGHT_BLUE}
                                buttonText="Download Network Impact"
                                endIcon={<GetAppSharpIcon />}
                                sx={{ marginRight: '16px' }}
                                onClick={() => {
                                    setOpenDownloadNetworkImpactModal(true);
                                    FS.event('View Download Network Impact Button', {});
                                }}
                            />

                            <ODButton
                                disabled={hasBidAnalysisChange}
                                buttonType={BUTTON_TYPE.BLUE}
                                buttonText="View Network Impact"
                                endIcon={<CompareArrowsIcon />}
                                sx={{ marginRight: '16px' }}
                                onClick={() => {
                                    dispatch(updateModalState({ showNetworkImpact: true }));
                                    FS.event('View Network Impact Button', {});
                                }}
                            />
                        </>
                    )}
                    {refreshBid && (!isFinalizedBid || hasBidAnalysisChange) && (
                        <ODButton
                            buttonType={BUTTON_TYPE.BLUE}
                            buttonText={`${
                                odpt4185BidConfigUpdate ? 'Finalize' : 'Refresh'
                            } Bid Analysis`}
                            endIcon={<InsightsOutlinedIcon />}
                            onClick={() => setOpenRefreshModal(true)}
                            disabled={Object.keys(bidAnalysisTableDataErrors).length > 0}
                        />
                    )}
                </Box>
            </RightSideHeaderContent>

            {odpt4185BidConfigUpdate && (
                <DownloadNetworkViewImpactModal
                    setOpenNetworkViewImpactModal={setOpenDownloadNetworkImpactModal}
                    openNetworkViewImpactModal={openDownloadNetworkImpactModal}
                />
            )}

            <RefreshBidAnalysisModal
                setOpenRefreshModal={setOpenRefreshModal}
                openRefreshModal={openRefreshModal}
            />

            <DownloadBidAnalysisModal
                isFinalizedBid={isFinalizedBid}
                setOpenExportModal={setOpenExportModal}
                openExportModal={openExportModal}
                setOpenRefreshModal={setOpenRefreshModal}
            />
        </BidAnalysisHeaderContainer>
    );
};

export default BidAnalysisHeader;
