import { createSvgIcon } from '@mui/material';

export const DriverLanesIcon = createSvgIcon(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0H10.5V12H12V0Z" fill="currentColor" />
        <path d="M1.5 0H0V12H1.5V0Z" fill="currentColor" />
        <path d="M6.75 0H5.25V3H6.75V0Z" fill="currentColor" />
        <path d="M6.75 4.5H5.25V7.5H6.75V4.5Z" fill="currentColor" />
        <path d="M6.75 9H5.25V12H6.75V9Z" fill="currentColor" />
    </svg>,
    'DriverLanesIcon'
);
