import { createSvgIcon } from '@mui/material';

export const HistoricRateIcon = createSvgIcon(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon">
            <g id="Union">
                <path
                    d="M3 9C3 5.2725 6.0225 2.25 9.75 2.25C13.4775 2.25 16.5 5.2725 16.5 9C16.5 12.7275 13.4775 15.75 9.75 15.75C7.8825 15.75 6.2025 14.9925 4.98 13.77L6.045 12.705C6.99 13.6575 8.3025 14.25 9.75 14.25C12.6525 14.25 15 11.9025 15 9C15 6.0975 12.6525 3.75 9.75 3.75C6.8475 3.75 4.5 6.0975 4.5 9H6.75L3.75 11.9925L0.75 9H3Z"
                    fill="currentColor"
                />
                <path
                    d="M12 7.5V6H10.5V5.25H9V6H7.5V9.75H10.5V10.5H7.5V12H9V12.75H10.5V12H12V8.25H9V7.5H12Z"
                    fill="currentColor"
                />
            </g>
        </g>
    </svg>,
    'HistoricRateIcon'
);
