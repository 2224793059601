import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography
} from '@mui/material';
import palette from 'theme/palette';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { ODButton, Label } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';

const Title = styled(Box)(() => ({
    backgroundColor: palette.ODLightBlueNeutral.lightBlue1,
    borderRadius: '4px',
    paddingLeft: '19px',
    height: '40px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
}));
const DialogTitleRow = ({ onClose, title, ...other }: DialogTitleRowPropTypes) => {
    return (
        <DialogTitle
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '32px'
            }}
            {...other}>
            <Box sx={{ fontSize: '18px' }} component={Typography}>
                {title}
            </Box>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    borderRadius: '4px',
                    width: '40px',
                    height: '40px'
                }}>
                <ClearIcon
                    sx={{
                        color: palette.black,
                        cursor: 'pointer'
                    }}
                />
            </IconButton>
        </DialogTitle>
    );
};

type DialogTitleRowPropTypes = {
    onClose: () => void;
    title: string;
};
type ODDialogProps = {
    actionButtonText: string;
    actionButtonDisabled?: boolean;
    dialogDescription: string;
    itemIcon?: React.ReactNode;
    itemTitle?: string | null;
    message?: string;
    bodyOverride?: JSX.Element;
    onClose: () => void;
    onConfirm: () => void;
    open: boolean;
};
const ODDialog = (props: ODDialogProps) => {
    const {
        actionButtonText,
        actionButtonDisabled = false,
        bodyOverride,
        dialogDescription,
        itemIcon,
        itemTitle,
        message,
        onClose,
        onConfirm,
        open
    } = props;

    return (
        <Dialog key="dialog" sx={{ padding: 0 }} onClose={onClose} open={open}>
            <DialogTitleRow onClose={onClose} title={dialogDescription} />
            <DialogContent sx={{ padding: '32px', marginTop: '11px' }}>
                {itemTitle && (
                    <Title>
                        <Label text={itemTitle} startIcon={itemIcon} />
                    </Title>
                )}
                {message && (
                    <Box sx={{ margin: '16px 0' }} component={Typography}>
                        {message}
                    </Box>
                )}
                {Boolean(bodyOverride) && bodyOverride}
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <ODButton
                        // disableFocusRipple={true}
                        width="188px"
                        buttonText={actionButtonText}
                        buttonType={BUTTON_TYPE.BLUE}
                        onClick={onConfirm}
                        disabled={actionButtonDisabled}
                    />
                    <ODButton
                        // disableFocusRipple={true}
                        width="188px"
                        buttonText="Cancel"
                        onClick={onClose}
                    />
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ODDialog;
