/* eslint-disable react-hooks/exhaustive-deps */
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/redux';
import * as yup from 'yup';
import { Box, Stack } from '@mui/material';
import { ODButton, ODTextField } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import ODMultiSelect from 'shared/ODMultiSelect';
import SelectedList from 'shared/SelectedList';
import {
    createNewGroup,
    getAllBidFiles,
    getAllGroups,
    getConfigsByGroupId,
    updateBidFilesByGroupId,
    updateGroupNameByGroupId
} from 'redux/actions';
import { SLIDE_OUT_TYPE } from 'constants/bidding';
import { ConfigurationIcon } from 'assets/images';
import palette from 'theme/palette';
interface createNewGroupProps {
    handleClose: () => void;
}
interface onSubmitProps extends HTMLAttributes<HTMLElement> {
    groupName?: string;
    allFiles?: any;
}

const validationSchema = yup.object({
    groupName: yup.string().min(1).max(999).required('Required')
});

const HandleGroupForm = ({ handleClose }: createNewGroupProps) => {
    const dispatch = useDispatch();
    const [fetchedConfigs, setFetchedConfigs] = useState([]);
    const { slideoutMode, allBidFiles } = useSelector((state: RootState) => state.BiddingReducer);

    const initialFormState =
        slideoutMode?.mode === SLIDE_OUT_TYPE.EDIT_GROUP
            ? {
                  groupName: slideoutMode?.data?.data?.groupName,
                  allFiles: fetchedConfigs
              }
            : {
                  groupName: '',
                  allFiles: []
              };

    const fileOptions = allBidFiles?.map((file: any) => {
        return {
            id: file.id,
            value: file.file_name,
            ...file
        };
    });

    const fileIds = allBidFiles?.map((file: any) => file.id);

    const onSubmit = ({ groupName, allFiles }: onSubmitProps) => {
        if (slideoutMode?.mode === SLIDE_OUT_TYPE.CREATE_GROUP) {
            dispatch(createNewGroup(groupName, allFiles));
        } else if (slideoutMode?.mode === SLIDE_OUT_TYPE.EDIT_GROUP) {
            const currGroupId = slideoutMode?.data?.data?.id;

            Promise.all([
                updateGroupNameByGroupId(currGroupId, groupName),
                updateBidFilesByGroupId(currGroupId, allFiles)
            ])
                .then(() => {
                    dispatch(getAllGroups());
                    dispatch(getAllBidFiles());
                })
                .catch((err) => {
                    console.warn(`there was an error updating group`);
                });
        } else {
            console.warn(`This doesn't have any slide out mode selected`);
        }
    };

    useEffect(() => {
        if (slideoutMode?.mode === SLIDE_OUT_TYPE.EDIT_GROUP) {
            getConfigsByGroupId(slideoutMode?.data?.data?.id).then((res) => {
                const configList = res?.results;
                const configsByCurrGroupId = configList.map((item: any) => item.id);
                setFetchedConfigs(configsByCurrGroupId);
            });
        }
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={initialFormState}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }: any) => {
                onSubmit(values);
                resetForm();
                handleClose();
                setSubmitting(false);
            }}>
            {({
                handleSubmit,
                values,
                setFieldValue,
                handleChange,
                dirty,
                isValid,
                resetForm
            }: any) => {
                const clearAndClose = () => {
                    resetForm();
                    handleClose();
                };

                const removeSelectedItem = (item: any) => {
                    let filteredArr = values?.allFiles?.filter((e: any) => e !== item);
                    setFieldValue('allFiles', filteredArr);
                };

                let showValueLabel = (option: any) => {
                    let selectedValueObj = fileOptions.find((item: any) => option === item?.id);

                    return selectedValueObj?.value;
                };

                const handleClearAll = () => {
                    setFieldValue('allFiles', []);
                };

                return (
                    <form onSubmit={handleSubmit}>
                        <ODTextField
                            dataTestId="enter-new-group-name"
                            label="Group Name"
                            value={values?.groupName}
                            onChange={handleChange}
                            name="groupName"
                            placeholder="Enter Name"
                        />
                        <Box marginTop="16px">
                            <Field
                                className="all-files-select"
                                name="allFiles"
                                label={'Add Files'}
                                options={fileIds}
                                component={ODMultiSelect}
                                data={fileOptions}
                                clearSelectedOptions={handleClearAll}
                            />
                        </Box>

                        <SelectedList
                            items={values.allFiles}
                            removeSelectedItem={removeSelectedItem}
                            showValueLabel={showValueLabel}
                            itemIcon={
                                <ConfigurationIcon style={{ color: palette.neutral.black }} />
                            }
                        />

                        <Stack justifyContent={'space-between'} marginTop="32px">
                            <ODButton
                                buttonType={BUTTON_TYPE.BLUE}
                                type="submit"
                                width="392px"
                                buttonText="Save"
                                disabled={!isValid || !dirty}
                            />
                            <ODButton
                                buttonText="Cancel"
                                width="392px"
                                sx={{ marginTop: '8px' }}
                                onClick={clearAndClose}
                            />
                        </Stack>
                    </form>
                );
            }}
        </Formik>
    );
};

export default HandleGroupForm;
