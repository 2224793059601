import { useCallback, useEffect, useRef, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import { ODIconButton } from 'shared';
import { Check, Close } from '@mui/icons-material';
import { inputValid, VALIDATION_RULE } from 'utils/common.util';
import { styled } from '@mui/material/styles';
import { Input } from '@mui/material';
import NumericFormat from 'react-number-format';
import * as FS from '@fullstory/browser';
import { useTheme } from '@mui/system';

const StyledButton = styled(ODIconButton)(() => ({
    width: '30px',
    height: '30px',
    backgroundColor: 'inherit',
    borderRadius: 0,
}));

const CustomInput = (props: any) => {
    const theme = useTheme();
    return (
        <Input
            {...props}
            sx={{
                fontSize: '14px',
                height: '100%',
                borderRadius: '1px',
                '& input::placeholder': {
                    color: theme.palette.neutral.neutral6,
                    opacity: 1 // otherwise firefox shows a lighter color
                },
                backgroundColor: props.disabled ? theme.palette.neutral.neutral1 : '',
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                },
                '& .MuiInputBase-input': { padding: '7px 10px' }
            }}
            disableUnderline={true}
        />
    );
};

const NumericInputWithDeleteAndConfirmButtons = ({
    disabled = false,
    targetName,
    exceedsMaxCallback,
    belowMinCallback,
    maxAllowable,
    minAllowable,
    updateValue,
    validationRule,
    format,
    setIsInputEmpty,
    inputValue,
    setInputValue
}: {
        targetName: string;
        updateValue: (value: number | null) => void;
        exceedsMaxCallback: () => void;
        belowMinCallback: () => void;
        validationRule: VALIDATION_RULE;
        maxAllowable?: number;
        minAllowable?: number;
        disabled: boolean;
        format?: 'dollars' | 'number';
        setIsInputEmpty: (isEmpty: boolean) => void;
        inputValue: any;
        setInputValue: any;
    }) => {
    const theme = useTheme();
    const [localValue, setLocalValue] = useState(inputValue);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const [unallowed, setUnallowed] = useState<boolean>(false);
    
    const clearValue = useCallback(() => {
        setLocalValue(null);
        updateValue(null);
        setInputValue(null); 
        setIsInputEmpty(true);
    }, [setInputValue, setIsInputEmpty, updateValue]);

    
    const handleConfirm = () => {
        FS.event('Bid Analysis_Bid Goals_Click Accept Button', { targetFieldName: targetName });
        if (minAllowable && !(Number(localValue) > minAllowable)) {
            setUnallowed(true);
            belowMinCallback();
        } else {
            updateValue(localValue);
            setInputValue(localValue); 
        }
    };



    const onChange = useCallback((newValue: any) => {
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
            if (newValue === null && localValue !== null) {
                setLocalValue(newValue);
                setIsInputEmpty(true);
            } else if (inputValid(validationRule, newValue)) {
                if (localValue === null) setIsInputEmpty(false);
                setLocalValue(newValue);
                setInputValue(newValue);
            }
        }, 1000);
    }, [localValue, setIsInputEmpty, validationRule, setInputValue]);

    useEffect(() => {
        setLocalValue(inputValue); 
    }, [inputValue]);

    useEffect(() => {
        if (
            Boolean(
                inputValue !== null &&
                    maxAllowable !== null &&
                    maxAllowable !== undefined &&
                    Number(inputValue) > maxAllowable
            )
        ) {
            setUnallowed(true);
            exceedsMaxCallback();
            clearValue()
        } else {
            setUnallowed(false);
        }
    }, [exceedsMaxCallback, clearValue, inputValue, maxAllowable]);

    const StyledStack = styled(Stack)(({ unallowed }: { unallowed: boolean }) => ({
        height: '30px',
        width: '184px',
        border: `1px solid ${theme.palette.neutral.neutral2}`,
        borderRadius: '2px',
        ...(unallowed ? { borderColor: theme.palette.semantic.semanticRed } : {})
    }));

    const StyledCloseIcon = styled(Close)(
        ({ disableIcon, value }: { disableIcon: boolean; value: number | null }) => ({
            color:
                value === null || disableIcon
                    ? theme.palette.neutral.neutral6
                    : theme.palette.common.black
        })
    );
    const StyledCheckIcon = styled(Check)(
        ({
            disableIcon,
            value,
            unallowed
        }: {
            disableIcon: boolean;
            value: number | null;
            unallowed: boolean;
        }) => ({
            color:
                value === null || unallowed || disableIcon
                    ? theme.palette.neutral.neutral6
                    : theme.palette.success.main
        })
    );

    return (
        <StyledStack
            direction="row"
            divider={<Divider orientation="vertical" flexItem sx={{ borderColor: unallowed ? theme.palette.semantic.semanticRed : '' }} />} unallowed={unallowed}        >
            <NumericFormat
                inputRef={inputRef}
                disabled={disabled}
                customInput={CustomInput}
                prefix={format === 'dollars' ? '$' : ''}
                thousandSeparator
                displayType="input"
                value={localValue ?? ''}
                decimalScale={format === 'dollars' ? 2 : 0}
                fixedDecimalScale
                onValueChange={(values, sourceInfo) => {
                    if (sourceInfo.source === 'event') onChange(values.floatValue ?? null);
                }}
                isNumericString
                onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter' && !unallowed) handleConfirm();
                }}
            />
            <StyledButton
                disabled={disabled}
                icon={<StyledCloseIcon disableIcon={disabled} value={inputValue} />}
                onClick={clearValue}
            />
            <StyledButton
                disabled={disabled || unallowed}
                icon={
                    <StyledCheckIcon
                        disableIcon={disabled}
                        value={inputValue}
                        unallowed={unallowed}
                    />
                }
                onClick={handleConfirm}
            />
        </StyledStack>
        
    );
};

export default NumericInputWithDeleteAndConfirmButtons;
