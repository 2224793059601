import React from 'react';
import { ODButton, ODModal, Label } from 'shared';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useBidAnalysisHook } from 'utils/hooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OutdatedReportIcon } from 'assets/images';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { SpecialDocIcon } from 'assets/images';
import { useNavigate, useParams } from 'react-router-dom';
import { useParamsProps } from 'types/hooks';
import { updateModalState } from 'redux/actions/modalsAction';
import { useTheme } from '@mui/system';

const commonLabelSx = {
    width: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
};

const ViewNetworkImpactModal = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { analysis_id }: useParamsProps = useParams();
    const { currBidAnalysisData } = useSelector((state: RootState) => state.BidAnalysisReducer);

    const { handleViewBaseReport, isLatest } = useBidAnalysisHook();
    const { showNetworkImpact } = useSelector((state: RootState) => state.ModalsReducer);

    const handleViewNetworkImpact = () => {
        const baseReportId = currBidAnalysisData.parent_id;
        const compareReportId = analysis_id;
        const bucketId = currBidAnalysisData.bucket_id;
        dispatch(updateModalState({ showNetworkImpact: false }));
        const redirectUrl = `/reports/bucket/${bucketId}/${baseReportId}/${compareReportId}/network/shipper/ `;
        navigate(redirectUrl, { state: { networkImpactView: true } });
    };

    const outOfDateBaseMessage = (
        <Stack direction="row">
            <InfoOutlinedIcon sx={{ marginRight: '16px' }} />
            <Box>
                You're about to view the network impact of this bid analysis, but the original file
                it was created on is outdated. For current results, create a new bid analysis with
                the latest base network.
            </Box>
        </Stack>
    );

    const reportRibbons = () => (
        <>
            <Box
                sx={{
                    color: theme.palette.neutral.neutral6,
                    fontSize: '12px',
                    marginBottom: '4px'
                }}>
                {isLatest ? '' : 'Outdated '}Base Network / Bid Analysis
            </Box>
            <Stack
                sx={{
                    backgroundColor: isLatest
                        ? theme.palette.ODLightBlueNeutral.lightBlue1
                        : theme.palette.neutral.search,
                    fontSize: '14px',
                    borderRadius: '4px',
                    padding: '16px'
                }}>
                <Stack
                    sx={{ fontWeight: 500 }}
                    divider={
                        <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{
                                borderColor: isLatest
                                    ? theme.palette.ODLightBlueNeutral.lightBlue2
                                    : theme.palette.neutral.neutral2
                            }}
                        />
                    }
                    spacing={'10px'}>
                    {isLatest ? null : outOfDateBaseMessage}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={isLatest ? {} : { paddingX: '16px' }}>
                        <Label
                            startIcon={
                                isLatest ? (
                                    <SpecialDocIcon
                                        style={{ color: theme.palette.semantic.focusedBlue }}
                                    />
                                ) : (
                                    <OutdatedReportIcon
                                        style={{ color: theme.palette.semantic.semanticRed }}
                                    />
                                )
                            }
                            labelSx={commonLabelSx}
                            sx={{
                                color: isLatest
                                    ? theme.palette.common.black
                                    : theme.palette.neutral.neutral6
                            }}
                            text={currBidAnalysisData.parent_name}
                            fontWeight={600}
                        />
                        {isLatest ? null : (
                            <Typography
                                color={theme.palette.neutral.neutral6}
                                sx={{ fontWeight: 600 }}>
                                Outdated
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={isLatest ? {} : { paddingX: '16px' }}>
                        <Label
                            labelSx={commonLabelSx}
                            startIcon={
                                <>
                                    {isLatest && <SwapHorizIcon sx={{ marginRight: '16px' }} />}
                                    <InsightsOutlinedIcon />
                                </>
                            }
                            text={currBidAnalysisData.name}
                            fontWeight={600}
                        />
                        {isLatest ? null : <Typography sx={{ fontWeight: 600 }}>New</Typography>}
                    </Stack>
                </Stack>
                {!isLatest ? (
                    <ODButton
                        sx={{
                            marginTop: '16px',
                            border: `1px solid ${theme.palette.neutral.neutral2}`
                        }}
                        width="100%"
                        buttonText="View Latest Base Network"
                        onClick={() => {
                            handleViewBaseReport();
                            dispatch(updateModalState({ showNetworkImpact: false }));
                        }}
                    />
                ) : null}
            </Stack>

            <Typography marginTop="16px">
                Click “View” to see how this Bid Analysis impacts the current base network.
            </Typography>
        </>
    );

    return (
        <ODModal
            show={showNetworkImpact}
            heading="Bid Network Impact"
            handleClose={() => dispatch(updateModalState({ showNetworkImpact: false }))}
            actionButtonText="View Network Impact"
            handleAction={handleViewNetworkImpact}
            children={<>{reportRibbons()}</>}
        />
    );
});

export default ViewNetworkImpactModal;
