import { createSvgIcon } from '@mui/material';

export const FolderWithStarIcon = createSvgIcon(
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 0.5H0V15.5H12V5L7.5 0.5ZM6.75 5V1.625L10.125 5H6.75ZM6 12.1033L7.85684 13.25L7.36421 11.03L9 9.34333H6.84632L6 7.25L5.15368 9.34333H3L4.63579 11.03L4.14316 13.25L6 12.1033Z"
            fill="currentColor"
        />
    </svg>,
    'FolderWithStarIcon'
);
