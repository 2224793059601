import React from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/system';

export default function HelperMessage({ message }: any) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.semantic.focusedBlue,
                color: theme.palette.common.white,
                borderRadius: '4px',
                padding: '10px',
                display: 'flex',
                alignItems: 'center'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '17.5px',
                    fontSize: 'large'
                }}>
                <InfoOutlinedIcon />
            </Box>
            <Box component={Typography}>{message}</Box>
        </Box>
    );
}
