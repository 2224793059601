import { ListItemText, ListItemButton, Box, Typography, Divider, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import palette from 'theme/palette';

// CompletedReportSimulationModal
interface StyledTextItemProps {
    selected?: boolean;
}
export const StyledTextItem = styled(ListItemText)<StyledTextItemProps>(
    ({ selected }: { selected?: any }) => ({
        '.MuiListItemText-primary': {
            fontSize: '14px',
            fontWeight: 600
        },
        '.MuiListItemText-secondary': {
            color: selected ? palette.black : palette.neutral.neutral6,
            paddingTop: '8px'
        }
    })
);

export const StyledButtonItem = styled(ListItemButton)(() => ({
    color: palette.neutral.neutral6,
    marginTop: '8px',
    borderRadius: '4px',
    border: `1px solid ${palette.neutral.neutral1}`,
    '&.Mui-selected': { color: palette.black }
}));

export const ReportName = styled(Typography)(() => ({
    width: '100%',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}));

// CompletedModal
export const StyledCompletedMessageBox = styled(Box)(() => ({
    backgroundColor: palette.ODLightBlueNeutral.lightBlue1,
    fontSize: '14px',
    color: palette.black,
    padding: '10px 16px',
    height: '20px',
    display: 'flex',
    fontWeight: 600,
    borderRadius: '4px',
    justifyContent: 'flex-start',
    alignItems: 'center'
}));

// FreightBonusModal
export const SubHeader = styled(Box)(() => ({
    color: palette.neutral.neutral6,
    fontSize: '12px',
    marginTop: '16px',
    marginBottom: '4px'
}));

export const LocationWrapper = styled(Box)(() => ({
    display: 'flex',
    width: '125px'
}));

export const StyledSelectedRowDetail = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '20px',
    fontSize: '14px',
    padding: '10px',
    margin: '2px 0',
    borderRadius: '4px',
    backgroundColor: palette.ODLightBlueNeutral.lightBlue1
}));

export const StyledNoRowSelectedBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    color: palette.neutral.neutral6
}));

export const StyledFreightBonusBody = styled(Box)(() => ({
    maxHeight: '180px',
    border: `1px solid ${palette.neutral.neutral1}`,
    borderRadius: '4px',
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '8px'
}));

// SaveDraftReportModal
export const SavedDraftMessageBox = styled(Box)(() => ({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    margin: '16px 0'
}));

// ParametersModal
export const useStyles = makeStyles(() => ({
    menuIconButton: {
        minWidth: '0px',
        height: '40px',
        width: '40px'
    },
    iconButton: {
        backgroundColor: `${palette.neutral.neutral1} !important`,
        minWidth: '0px',
        height: '40px',
        width: '40px',
        margin: '0px !important',
        '&:hover': {
            backgroundColor: `${palette.neutral.neutral2} !important`
        }
    },
    dialogPaper: {
        margin: '16px',
        height: '868px',
        width: '98vw',
        minWidth: '98%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    editParamButton: {
        fontFamily: 'IBM Plex Sans',
        color: palette.black,
        height: '40px',
        marginRight: '16px',
        borderRadius: '100px',
        backgroundColor: palette.neutral.neutral1,
        textTransform: 'capitalize',
        fontWeight: '400',
        padding: '10px 24px',
        '&:hover': {
            backgroundColor: palette.neutral.neutral2
        }
    }
}));

// OutdatedBaseReportModal
export const StyldOutdatedMessage = styled(Stack)(() => ({
    backgroundColor: palette.neutral.search,
    fontSize: 14,
    color: palette.black,
    padding: '10px 16px',
    fontWeight: 600,
    borderRadius: '4px'
}));

export const OutdatedBaseReportHelperText = styled(Typography)(() => ({
    fontSize: 14
}));

export const CardLabel = styled(Typography)(() => ({
    fontSize: 12,
    marginTop: '16px',
    marginBottom: '4px',
    color: palette.neutral.neutral6
}));

export const OutdatedReport = styled(Stack)(() => ({
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
}));

export const ButtonWrapper = styled(Stack)(() => ({
    marginTop: '32px',
    flexDirection: 'row',
    justifyContent: 'space-between'
}));

export const CustomDivider = styled(Divider)(() => ({
    borderColor: palette.neutral.neutral2,
    width: '100%',
    margin: '10px 0'
}));

export const LatestReport = styled(Stack)(() => ({
    width: '100%',
    flexDirection: 'row'
}));

export const IconWrapper = styled(Stack)(() => ({
    marginRight: '16px'
}));
