import { createSvgIcon } from '@mui/material';

export const FolderWithGearwheelIcon = createSvgIcon(
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.09274 10.25C5.09274 10.748 5.49798 11.15 6 11.15C6.50202 11.15 6.90726 10.748 6.90726 10.25C6.90726 9.752 6.50202 9.35 6 9.35C5.49798 9.35 5.09274 9.752 5.09274 10.25Z"
            fill="black"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 15.5L0.00750017 0.5H7.5L12 5V15.5H0ZM6.75 1.625V5.75H10.875L6.75 1.625ZM8.99395 9.404L8.25 9.992C8.26815 10.16 8.26815 10.334 8.25 10.502L9 11.096L8.24395 12.398L7.34879 12.05C7.20968 12.146 7.05847 12.236 6.90121 12.302L6.75605 13.25H5.24395L5.10484 12.302C4.94153 12.236 4.79032 12.152 4.65121 12.05L3.75605 12.404L3 11.102L3.74395 10.514C3.72581 10.346 3.73185 10.172 3.75 9.998L3 9.404L3.75605 8.102L4.65121 8.45C4.79032 8.354 4.94153 8.264 5.09879 8.198L5.24395 7.25H6.75605L6.89516 8.198C7.05847 8.264 7.20968 8.348 7.34879 8.45L8.2379 8.102L8.99395 9.404Z"
            fill="currentColor"
        />
    </svg>,
    'FolderWithGearwheelIcon'
);
