import { createSvgIcon } from '@mui/material';

export const ConfigurationIcon = createSvgIcon(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.0393 13.1775L17.9618 12.4425L17.0243 10.815L15.9218 11.25C15.7493 11.1225 15.5618 11.0175 15.3593 10.935L15.1868 9.75H13.3118L13.1318 10.935C12.9368 11.0175 12.7493 11.13 12.5768 11.25L11.4668 10.815L10.5293 12.4425L11.4593 13.185C11.4368 13.4025 11.4293 13.62 11.4518 13.83L10.5293 14.565L11.4668 16.1925L12.5768 15.75C12.7493 15.8775 12.9368 15.9825 13.1393 16.065L13.3118 17.25H15.1868L15.3668 16.065C15.5618 15.9825 15.7493 15.87 15.9218 15.75L17.0318 16.185L17.9693 14.5575L17.0393 13.815C17.0618 13.605 17.0618 13.3875 17.0393 13.1775ZM14.2493 14.625C13.6268 14.625 13.1243 14.1225 13.1243 13.5C13.1243 12.8775 13.6268 12.375 14.2493 12.375C14.8718 12.375 15.3743 12.8775 15.3743 13.5C15.3743 14.1225 14.8718 14.625 14.2493 14.625Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 16.5L3.0075 1.5H10.5L15 6V8.25H9V16.5H3ZM9.75 2.625V6.75H13.875L9.75 2.625Z"
            fill="currentColor"
        />
    </svg>,
    'ConfigurationIcon'
);
