import React from 'react';
import { Box, Paper, Link } from '@mui/material';
import { Close } from '@mui/icons-material';
import { groupBy } from 'lodash';
import moment from 'moment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { REPORT_STATUS } from 'constants/network';
import { NOTIFICATION_STATUS } from 'constants/reports';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/system';

declare global {
    // type Nullable<T> = T | null;
    interface Window {
        // openWidget: any;
        openWidget: () => void;
    }
}

interface alertSlideoutProps {
    notificationDetails: any;
    navigate: any;
    setShowAlertNotification: any;
    handleDeleteNotifications?: any;
}

const AlertSlideout = ({
    notificationDetails,
    navigate,
    handleDeleteNotifications,
    setShowAlertNotification
}: alertSlideoutProps) => {
    const theme = useTheme();
    const { newNotificationFlow } = useFlags();
    const viewReport = (bucket_id: any, report_run_id: any) => {
        const redirectUrl = `/reports/${
            bucket_id ? `bucket/${bucket_id}/` : `report-run/`
        }${report_run_id}/network/shipper/`;
        navigate(redirectUrl);
        setShowAlertNotification(false);
    };
    const notificationsByDate =
        groupBy(notificationDetails, ({ created_at }) =>
            moment(created_at).format('MM/DD HH:mm')
        ) || {};

    return (
        <>
            {notificationDetails?.length
                ? Object.keys(notificationsByDate)
                      .reverse()
                      .map((info, index) => {
                          return (
                              <div
                                  key={'group-by-date-hour-notifications' + index}
                                  style={{ width: '100%', marginTop: index === 0 ? '' : '16px' }}>
                                  <Box
                                      sx={{
                                          fontSize: '12px',
                                          color: theme.palette.neutral.neutral6,
                                          marginBottom: '4px'
                                      }}>
                                      {info || ''}
                                  </Box>
                                  {notificationsByDate[info].map(
                                      ({ id, message, status, bucket_id, report_run_id }) => {
                                          const reportStatus = message
                                              ?.split(' ')
                                              .pop()
                                              .toLowerCase();
                                          return (
                                              <Paper
                                                  key={message + id}
                                                  id={`alert-message-${id}`}
                                                  elevation={0}
                                                  sx={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      padding: '10px',
                                                      borderRadius: '4px',
                                                      backgroundColor:
                                                          theme.palette.neutral.neutral1,
                                                      marginBottom: '2px'
                                                  }}>
                                                  {(newNotificationFlow ||
                                                      (!newNotificationFlow &&
                                                          status !== NOTIFICATION_STATUS.READ)) && (
                                                      <Box
                                                          sx={{ marginBottom: '-3.5px' }}
                                                          display={'flex'}
                                                          zIndex={2}
                                                          alignItems={'center'}
                                                          flexDirection="row-reverse">
                                                          <Close
                                                              id={`remove-alert-message-${id}`}
                                                              sx={{
                                                                  cursor: 'pointer'
                                                              }}
                                                              onClick={() =>
                                                                  handleDeleteNotifications({
                                                                      id,
                                                                      message,
                                                                      status: NOTIFICATION_STATUS.READ
                                                                  })
                                                              }
                                                          />
                                                      </Box>
                                                  )}
                                                  <Box
                                                      zIndex={1}
                                                      sx={{
                                                          justifyContent: 'flex-start',
                                                          display: 'flex',
                                                          marginTop: '-16.5px'
                                                      }}>
                                                      <NotificationsIcon
                                                          sx={{
                                                              color: theme.palette.common.black,
                                                              marginRight: '16px'
                                                          }}
                                                      />
                                                      <Box
                                                          sx={{
                                                              width: '310px',
                                                              fontSize: '14px',
                                                              cursor: [
                                                                  'complete',
                                                                  REPORT_STATUS.COMPLETED
                                                              ].includes(reportStatus)
                                                                  ? 'pointer'
                                                                  : null
                                                          }}
                                                          onClick={() =>
                                                              [
                                                                  'complete',
                                                                  REPORT_STATUS.COMPLETED
                                                              ].includes(reportStatus)
                                                                  ? viewReport(
                                                                        bucket_id,
                                                                        report_run_id
                                                                    )
                                                                  : null
                                                          }>
                                                          {newNotificationFlow &&
                                                          ['failed'].includes(reportStatus) ? (
                                                              <>
                                                                  <Box>{message}</Box>
                                                                  <Link
                                                                      component="button"
                                                                      color="inherit"
                                                                      onClick={() =>
                                                                          window.openWidget()
                                                                      }>
                                                                      Get Support
                                                                  </Link>
                                                              </>
                                                          ) : (
                                                              message
                                                          )}
                                                      </Box>
                                                  </Box>
                                              </Paper>
                                          );
                                      }
                                  )}
                              </div>
                          );
                      })
                : null}
        </>
    );
};

export default AlertSlideout;
