import React, { useState } from 'react';
import { ODDialog, Label } from 'shared';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from 'utils/axios';
import { REPORT_STATUS } from 'constants/network';
import { REPORT_FILE_TYPE } from 'constants/reports';
import { capitalize } from 'lodash';
import { Clear } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';

interface DeleteGroupModalProps {
    open: boolean;
    type: string;
    records: any[] | undefined;
    onAction: () => void;
    fetchData: () => void;
    title: string;
}

export const DeleteReportGroupModal = ({
    open = true,
    type = 'group',
    records,
    onAction,
    fetchData,
    title
}: DeleteGroupModalProps) => {
    const theme = useTheme();
    const filteredLatestBaseReport = records?.filter(
        (item: {
            file_type: REPORT_FILE_TYPE;
            id: string | number;
            latest_base: {
                id: string | number;
            };
        }) =>
            item?.file_type?.toLowerCase() !== capitalize(REPORT_FILE_TYPE.BASE) &&
            item?.latest_base?.id !== item?.id
    );

    const [items, updateItems] = useState(filteredLatestBaseReport);
    const removeRuns = (run: { id: string | number }) => {
        const _items = items?.filter(({ id }: { id: string | number }) => id !== run.id);
        if (_items?.length === 0) return onAction();
        updateItems(_items);
    };
    const deletedItems = () => {
        items?.forEach((item: any, idx: number) => deleteItem(item, idx === items.length - 1));
        onAction();
    };

    const Title = styled(Box)(() => ({
        backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
        borderRadius: '4px',
        paddingLeft: '19px',
        height: '40px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center'
    }));

    const deleteItem = async (
        {
            name,
            id,
            report_status,
            config_id
        }: {
            name: string;
            id: string | number;
            report_status: REPORT_STATUS;
            config_id: string | number;
        },
        reset = true
    ) => {
        try {
            if (type === 'bucket') await API.delete(`/buckets/${id}/`);
            else {
                let url = `/reports/report-runs/${id}/`;
                if (report_status === REPORT_STATUS.DRAFT && config_id)
                    url += `/configurations/${config_id}`;
                await API.delete(url);
            }

            toast.success(`"${name}" is deleted.`, {
                position: 'bottom-right'
            });
            if (reset) fetchData();
        } catch (e) {
            toast.error(`Unable to delete "${name}"`, {
                position: 'bottom-right'
            });
        }
    };

    return (
        <ODDialog
            open={open}
            actionButtonText="Confirm"
            bodyOverride={
                <Box marginBottom="32px">
                    {items?.map((item: any) => {
                        return (
                            <Title>
                                <Label
                                    startIcon={
                                        <ListAltIcon sx={{ color: theme.palette.common.black }} />
                                    }
                                    sx={{
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        marginRight: '16px'
                                    }}
                                    text={item?.name}
                                    labelSx={{
                                        width: '280px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                    endIcon={
                                        <IconButton
                                            onClick={() => removeRuns(item)}
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple>
                                            <Clear sx={{ color: theme.palette.common.black }} />
                                        </IconButton>
                                    }
                                    endIconAction={() => deletedItems()}
                                />
                            </Title>
                        );
                    })}
                </Box>
            }
            dialogDescription={title}
            onClose={onAction}
            onConfirm={() => {
                deletedItems();
            }}
        />
    );
};
