import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/system';

type ButtonInfo = {
    value: string;
    buttonText: string;
};
type ODToggleButtonGroupProps = {
    toggleButtons: ButtonInfo[];
    value: string;
    handleAction: (value: string) => void;
};

const ODToggleButtonGroup = ({
    toggleButtons,
    value,
    handleAction,
    ...props
}: ODToggleButtonGroupProps) => {
    const theme = useTheme();

    const CustomizedToggleButton = styled(ToggleButton)(() => ({
        width: '196px',
        color: theme.palette.common.black,
        textTransform: 'capitalize',
        backgroundColor: theme.palette.neutral.neutral1,
        border: 'none',
        height: '40px',
        marginRight: '1px',
        borderRadius: '4px !important',
        '&.Mui-selected': {
            color: 'white',
            borderRadius: '4px',
            backgroundColor: theme.palette.semantic.focusedBlue
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: theme.palette.semantic.focusedBlue
        }
    }));

    return (
        <ToggleButtonGroup
            sx={{
                backgroundColor: theme.palette.neutral.neutral1
            }}
            color="primary"
            value={value}
            exclusive
            onChange={(_event, value) => {
                if (value !== null) handleAction(value);
            }}
            aria-label="Platform">
            {toggleButtons.map((info) => (
                <CustomizedToggleButton value={info.value}>
                    {info.buttonText}
                </CustomizedToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default ODToggleButtonGroup;
